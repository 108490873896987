import { inject, injectable } from "inversify";
import type { INetworkManager } from "next-netkit";
import { RequestMethod } from "next-netkit";
import { APIConst } from "../../../../product/constants/apiConst";
import { INewsRemoteDataSource } from "./iNewsRemoteDataSource";
import { NewsModel } from "../../../../product/models/news/newsModel";
import { PaginationParams } from "../../../../product/params/paginationParams";
import { GetFeedNewsParams } from "../../domain/useCases/getFeedNews";

@injectable()
export class NewsRemoteDataSource implements INewsRemoteDataSource {
  @inject("INetworkManager") private networkManager!: INetworkManager;

  async getDeniedNews(params: PaginationParams): Promise<NewsModel[]> {
    return await this.networkManager.requestList<NewsModel>({
      url: APIConst.deniedNews(params.page),
      method: RequestMethod.GET,
      data: params,
    });
  }

  async getVerifiedNews(params: PaginationParams): Promise<NewsModel[]> {
    return await this.networkManager.requestList<NewsModel>({
      url: APIConst.verifiedNews(params.page),
      method: RequestMethod.GET,
      data: params,
    });
  }

  async getFeedNews(params: GetFeedNewsParams): Promise<NewsModel[]> {
    return this.networkManager.requestList<NewsModel>({
      url: APIConst.feedNews(params.page, params.categoryId),
      method: RequestMethod.GET,
      data: params,
    });
  }

  getNewsDetails(slug: string): Promise<NewsModel> {
    return this.networkManager.request<NewsModel>({
      url: APIConst.newsBySlug(slug),
      method: RequestMethod.GET,
    });
  }

  async verifyNews(id: number): Promise<void> {
    await this.networkManager.request({
      url: APIConst.verifyNews(id),
      method: RequestMethod.POST,
    });
  }

  async denyNews(id: number): Promise<void> {
    await this.networkManager.request({
      url: APIConst.denyNews(id),
      method: RequestMethod.POST,
    });
  }

  async bookmarkNews(id: number): Promise<boolean> {
    await this.networkManager.request({
      url: APIConst.bookmarkNews(id),
      method: RequestMethod.POST,
    });
    return true;
  }

  async unBookmarkNews(id: number): Promise<boolean> {
    await this.networkManager.request({
      url: APIConst.bookmarkNews(id),
      method: RequestMethod.DELETE,
    });
    return true;
  }

  async shareNews(slug: string): Promise<void> {
    await this.networkManager.request({
      url: APIConst.shareNews(slug),
      method: RequestMethod.POST,
    });
  }
}
