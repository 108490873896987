import React from "react";
import "../../../components/header/header.css";
import { useNavigate } from "react-router-dom";

import "./CreateNewsHeader.css";

interface HeaderProps {
  noSearchBox?: boolean;
}

const CreateNewsHeader: React.FC<HeaderProps> = (props) => {
  const navigate = useNavigate();
  const searchIcon: string = require("../../assets/search-ico.svg").default;
  const Notifications_ico: string = require("../../assets/icons/Notifications.svg").default;
  const avatar_profile_ico: string = require(
    "../../assets/icons/avatar_profile.svg").default;
  const plus_ico: string = require("../../assets/icons/plus.svg").default;

  const handleCreateNewsClick = () => {
    navigate("/create-news");
  };

  return (
    <header className="header-v2">
      <div className="header-bar-v2">

        {!props.noSearchBox && (
          <div className="nav-right-boxes-v2">
         
            <div className="nav-right-box">
              <img src={Notifications_ico} alt="search" />
            </div>
            <div className="nav-right-box">
              <img src={avatar_profile_ico} alt="search" />
            </div>
          </div>
        )}
      </div>
    </header>
  );
};

export default CreateNewsHeader;

