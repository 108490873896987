import { inject, injectable } from "inversify";
import { IAuthRemoteDataSource } from "./auth.remote.data-source.interface";
import { SignInDto } from "../dto/sign-in.dto";
import { SignInResponseDto } from "../dto/sign-in.response.dto";
import type { INetworkManager } from "next-netkit";
import { RequestMethod } from "next-netkit";
import { APIConst } from "../../../../product/constants/apiConst";

@injectable()
export class AuthRemoteDataSource implements IAuthRemoteDataSource {
  @inject("INetworkManager")
  private networkManager!: INetworkManager;
  // constructor(
  //   @inject("INetworkManager") private networkManager: INetworkManager,
  // ) {}

  async signIn(dto: SignInDto): Promise<SignInResponseDto> {
    // Clear tokens before signing in, in case there is a token in the local storage
    this.networkManager.clearTokens();
    const result = await this.networkManager.request<SignInResponseDto>({
      method: RequestMethod.POST,
      url: APIConst.SIGN_IN,
      data: dto,
    });

    this.networkManager.setAccessToken(result.token);
    return result;
  }

  async removeUser(credentials: SignInDto): Promise<void> {
    await this.networkManager.request<void>({
      method: RequestMethod.DELETE,
      url: APIConst.REMOVE_ACCOUNT,
      data: credentials,
    });
  }
}
