import React, {FormEvent, useState} from "react";

import { Link } from "react-router-dom";


import './jurnalleWebSite.css';

const Footer: React.FC = () => {
  const Jurnal_Logo: string = require("../../product/assets/images/jurnalleWebSite/icon-jurnalle.png");

  
  return (
   
    <footer className="footer section">
    <div className="footer__container container grid">
        <div className="footer__content">
            <Link to="/home" className="footer__logo">
                <img src={Jurnal_Logo} alt="" className="footer__logo-img" />
                jurnalle
            </Link>

            <p className="footer__description">Doğru haberleri doğru yerde<br/>herkes için jurnalle</p>
            
            <div className="footer__social">
                <Link to="https://www.linkedin.com/company/jurnalle" target="_blank" className="footer__social-link">
                    <i className='bx bxl-linkedin'></i>
                </Link>
                <Link to="https://www.instagram.com/jurnalleapp" target="_blank" className="footer__social-link">
                    <i className='bx bxl-instagram-alt' ></i>
                </Link>
                <Link to="https://twitter.com/jurnalleapp" target="_blank" className="footer__social-link">
                    <i className='bx bxl-twitter' ></i>
                </Link>
            </div>
        </div>

        <div className="footer__content" id="about">
            <h3 className="footer__title">Hakkında</h3>
            
            <ul className="footer__links">
                <li>
                    <Link to="/home" className="footer__link">Hakkımızda</Link>
                </li>
                <li>
                    <Link to="/sss" className="footer__link">Sıkça Sorulan Sorular</Link>
                </li>
            </ul>
        </div>

        <div className="footer__content" id="contact">
            <h3 className="footer__title">Destek</h3>
            
            <ul className="footer__links">
                <li>
                   <Link  to="" className="footer__link">Mail:</Link> <Link to="mailto:hello@jurnalle.app" className="footer__link">  hello@jurnalle.app</Link><br/>

                    <br/><Link  to="" className="footer__link">Tel: </Link><Link to="https://api.whatsapp.com/send?phone=5060640842" className="footer__link">(+90) 506 064 0842</Link><br/>


                    <Link to="#" className="footer__link">İletişim</Link> 
                </li>
            </ul>
        </div>

        <div className="footer__content" id="info_center">
            <h3 className="footer__title">Bilgi Merkezi</h3>
            
            <ul className="footer__links">
                <li>
                    <Link to="/privacy_policy" className="footer__link">Gizlilik Politikası</Link>
                </li>
                <li>
                    <Link to="/terms_of_service" className="footer__link">Hizmet Şartları</Link>
                </li>
                <li>
                    <Link to="/cookies" className="footer__link">Çerezler</Link>
                </li>
                <li>
                    <Link to="/community_rules" className="footer__link">Topluluk Kuralları</Link>
                </li>                            

            </ul>
        </div>
    </div>

    <span className="footer__copy">jurnalle | 2023 &#169; Tüm hakları saklıdır</span>

</footer>

    );
  };
  
  export default Footer;

  