import { inject, injectable } from "inversify";
import type { INewsRepository } from "../repositories/iNewsRepository";
import { ResultFuture } from "../../../../core/types/types";
import { NewsModel } from "../../../../product/models/news/newsModel";
import { NewsContainerTypes } from "../../newsContainerTypes";

@injectable()
export class GetNewsDetails {
  @inject(NewsContainerTypes.INewsRepository) private newsRepo!: INewsRepository;

  async execute(slug: string): ResultFuture<NewsModel> {
    return this.newsRepo.getNewsDetails(slug);
  }
}

