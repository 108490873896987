import "reflect-metadata";
import { inject, injectable } from "inversify";
import type { IAuthRepository } from "../repositories/auth.repository.interface";

@injectable()
export class IsAuthenticated {
  @inject("IAuthRepository")
  private authRepo!: IAuthRepository;

  // constructor(@inject("IAuthRepository") private authRepo: IAuthRepository) {}

  execute(): boolean {
    return this.authRepo.isAuthenticated();
  }
}