import React, { FormEvent, useState } from "react";


import { Link } from "react-router-dom";
import './mobileMenu.css';



const MobileMenu: React.FC = () => {


  const back: string = require("../../product/assets/icons/back.svg").default;
  const user: string = require("../../product/assets/icons/user.svg").default;
  const listLine: string = require("../../product/assets/icons/list-line.svg").default;
  const dot3: string = require("../../product/assets/icons/dot3.svg").default;
  const noProfileUser: string = require("../../product/assets/noprofileuser.png");


  const homeIcon: string = require("../../product/assets/icons/menu/home.svg").default;
  const searchIco: string = require("../../product/assets/icons/menu/search.svg").default;
  const NotificationsIco: string = require("../../product/assets/icons/menu/Notifications.svg").default;
  const userIco: string = require("../../product/assets/icons/menu/user.svg").default;

  return (
    <>
      <section className="mobile-menu">
       
    
       <div className="menu-xs">
        <Link to="/feeds"> 
            <img src={homeIcon} alt="search" className="search-ico" />
       </Link>

       <Link to="/"> 
            <img src={searchIco} alt="search" className="search-ico" />
       </Link>

       <Link to="/"> 
            <img src={NotificationsIco} alt="search" className="search-ico" />
       </Link>

       <Link to="/my-profile"> 
            <img src={userIco} alt="search" className="search-ico" />
       </Link>

       </div>

      </section>
    </>
  );
};

export default MobileMenu;