import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import Quill styles
import "./createNews.css";
import Header from "../../../components/header/Header";
import MobileMenu from "../../../components/mobileMenu/MobileMenu";
import { useAppDispatch } from "../../../stores/hooks";
import { publishNews, userProfileData } from "../../../stores/general/actions";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../stores";

import FadeLoader from "react-spinners/ClipLoader";

// Convert base64 to File
const base64ToFile = (base64: string, filename: string): File | null => {
  const metadata = base64.split(",")[0];
  const base64Data = base64.split(",")[1];


  if (!metadata || !base64Data) {
    console.error("Invalid base64 string.");
    return null;
  }

  const mimeMatch = metadata.match(/:(.*?);/);
  if (!mimeMatch) {
    console.error("Could not extract MIME type.");
    return null;
  }

  const mimeString = mimeMatch[1];

  try {
    const byteCharacters = atob(base64Data);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: mimeString });

    return new File([blob], filename, { type: mimeString });
  } catch (error) {
    console.error("Error processing base64 string:", error);
    return null;
  }
};

const CreateNewsV2: React.FC = () => {


  const categoriesIco: string = require("../../../product/assets/icons/categoriesico.svg").default;
  const locationIco: string = require("../../../product/assets/icons/locationIco.svg").default;
  const searchIco: string = require("../../../product/assets/icons/searchIco.svg").default;
  const rightIco: string = require("../../../product/assets/icons/right.svg").default;
  const userIco: string = require("../../../product/assets/icons/user.svg").default;
  const rightIcoBlack: string = require("../../../product/assets/icons/right-black.svg").default;
  const noImage: string = require("../../../product/assets/loaders/no-img.avif");


  const userProfileDatas = useSelector((state: RootState) => state.ReducerGeneral.userProfileData);
  const loadingPublishNews = useSelector(
    (state: RootState) => state.ReducerGeneral.loadingPublishNews);
  const successPublishNews = useSelector(
    (state: RootState) => state.ReducerGeneral.successPublishNews);
  const errorPublishNews = useSelector((state: RootState) => state.ReducerGeneral.errorPublishNews);


  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [token, setToken] = useState("");
  useEffect(() => {
    const storedToken = localStorage.getItem("authToken");
    const storedExpiration = localStorage.getItem("authTokenExpiration");
    const now = new Date();

    if (storedExpiration) {
      const expirationDate = new Date(storedExpiration);

      if (now > expirationDate) {
        localStorage.removeItem("authToken");
        localStorage.removeItem("authTokenExpiration");
        navigate("/login");
      } else {
        if (storedToken) {
          setToken(storedToken);
          dispatch(userProfileData(storedToken));
        }
      }
    } else {

      navigate("/login");
    }
  }, []);


  useEffect(() => {

    successPublishNews && navigate("/");

  }, [successPublishNews]);


  const quillRef = useRef<ReactQuill>(null);

  const imageHandler = useCallback(() => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = () => {
      const file = input.files?.[0];
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          const imageUrl = reader.result as string;

          const quill = quillRef.current?.getEditor();
          if (quill) {
            const range = quill.getSelection();
            if (range && range.index !== null) {
              quill.insertEmbed(range.index, "image", imageUrl);
              quill.setSelection({
                index: range.index + 1,
                length: 0,
              });
            }
          }
        };
        reader.readAsDataURL(file);
      }
    };
  }, []);


  const imageArray: string[] = [];


  const [previewPage, setPreviewPage] = useState(false);
  const handleContinue = () => {
    setPreviewPage(true);
  };


  const imageArrayPreview: string[] = [];
  const [imagePreview, setImagePreview] = useState("");
  let currentTextPreview = "";
  const [textPreviewer, setTextPreviewer] = useState("");
  const [hideBtnPreview, setHideBtnPreview] = useState(false);

  const handlePreviewPost = () => {

    setHideBtnPreview(true);

    const quill = quillRef.current?.getEditor();


    if (quill) {
      const delta = quill.getContents();
      delta.ops && delta.ops.forEach((op: any) => {
        if (op.insert) {


          if (typeof op.insert === "object" && "image" in op.insert) {
            if (op.insert.image) {
              imageArrayPreview.push(op.insert.image);
              console.log("image prev" + imageArrayPreview.length);
              setImagePreview(imageArrayPreview[0]);

            }

          }
        }
      });

    }

    setPreviewPage(true);


  };


  const selectedCat: string = require("../../../product/assets/icons/selected.svg").default;


  const editorModules = {
    toolbar: {
      container: [
        [{ header: "1" }, { header: "2" }],
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link", "image"],
      ],
      handlers: {
        image: imageHandler,
      },
    },
  };

  const [selectedCategories, setSelectedCategories] = useState<number[]>([]);

  const toggleCategory = (index: number) => {
    setSelectedCategories(prevSelected => {
      if (prevSelected.includes(index)) {
        return prevSelected.filter(catIndex => catIndex !== index);
      } else if (prevSelected.length < 3) {
        return [...prevSelected, index];
      }
      return prevSelected;
    });
  };


  const categories: string[] = [
    "Teknoloji",
    "Kültür&sanat",
    "Eğitim",
    "Finans",
    "Spor",
    "Ekonomi",
    "Müzik bilim",
    "Sağlık",
    "Gündem",
    "Seyehat",
    "Eğlence",
    "Sinema",
    "Yapay zeka",
    "Kariyer",
    "Mizah",
    "Moda",
    "Viral",
    "Siyaset",
    "Tasarım",
    "Category",
  ];


  const locationList = [
    { id: 0, name: "Şehir seç" },
    { id: 1, name: "Adana" },
    { id: 2, name: "Adıyaman" },
    { id: 3, name: "Afyonkarahisar" },
    { id: 4, name: "Ağrı" },
    { id: 5, name: "Amasya" },
    { id: 6, name: "Ankara" },
    { id: 7, name: "Antalya" },
    { id: 8, name: "Artvin" },
    { id: 9, name: "Aydın" },
    { id: 10, name: "Balıkesir" },
    { id: 11, name: "Bilecik" },
    { id: 12, name: "Bingöl" },
    { id: 13, name: "Bitlis" },
    { id: 14, name: "Bolu" },
    { id: 15, name: "Burdur" },
    { id: 16, name: "Bursa" },
    { id: 17, name: "Çanakkale" },
    { id: 18, name: "Çankırı" },
    { id: 19, name: "Çorum" },
    { id: 20, name: "Denizli" },
    { id: 21, name: "Diyarbakır" },
    { id: 22, name: "Edirne" },
    { id: 23, name: "Elazığ" },
    { id: 24, name: "Erzincan" },
    { id: 25, name: "Erzurum" },
    { id: 26, name: "Eskişehir" },
    { id: 27, name: "Gaziantep" },
    { id: 28, name: "Giresun" },
    { id: 29, name: "Gümüşhane" },
    { id: 30, name: "Hakkari" },
    { id: 31, name: "Hatay" },
    { id: 32, name: "Isparta" },
    { id: 33, name: "Mersin" },
    { id: 34, name: "İstanbul" },
    { id: 35, name: "İzmir" },
    { id: 36, name: "Kars" },
    { id: 37, name: "Kastamonu" },
    { id: 38, name: "Kayseri" },
    { id: 39, name: "Kırklareli" },
    { id: 40, name: "Kırşehir" },
    { id: 41, name: "Kocaeli" },
    { id: 42, name: "Konya" },
    { id: 43, name: "Kütahya" },
    { id: 44, name: "Malatya" },
    { id: 45, name: "Manisa" },
    { id: 46, name: "Kahramanmaraş" },
    { id: 47, name: "Mardin" },
    { id: 48, name: "Muğla" },
    { id: 49, name: "Muş" },
    { id: 50, name: "Nevşehir" },
    { id: 51, name: "Niğde" },
    { id: 52, name: "Ordu" },
    { id: 53, name: "Rize" },
    { id: 54, name: "Sakarya" },
    { id: 55, name: "Samsun" },
    { id: 56, name: "Siirt" },
    { id: 57, name: "Sinop" },
    { id: 58, name: "Sivas" },
    { id: 59, name: "Tekirdağ" },
    { id: 60, name: "Tokat" },
    { id: 61, name: "Trabzon" },
    { id: 62, name: "Tunceli" },
    { id: 63, name: "Şanlıurfa" },
    { id: 64, name: "Uşak" },
    { id: 65, name: "Van" },
    { id: 66, name: "Yozgat" },
    { id: 67, name: "Zonguldak" },
    { id: 68, name: "Aksaray" },
    { id: 69, name: "Bayburt" },
    { id: 70, name: "Karaman" },
    { id: 71, name: "Kırıkkale" },
    { id: 72, name: "Batman" },
    { id: 73, name: "Şırnak" },
    { id: 74, name: "Bartın" },
    { id: 75, name: "Ardahan" },
    { id: 76, name: "Iğdır" },
    { id: 77, name: "Yalova" },
    { id: 78, name: "Karabük" },
    { id: 79, name: "Kilis" },
    { id: 80, name: "Osmaniye" },
    { id: 81, name: "Düzce" },
  ];


  const [inputTitle, setInputTitle] = useState("");

  const [inputKaynak, setInputKaynak] = useState("");
  const [selectedLocationId, setSelectedLocationId] = useState<string>("0");

  const handleLocationChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedLocationId(event.target.value);
  };


  const handleFinish = () => {

    const quill = quillRef.current?.getEditor();
    if (quill) {

      const delta = quill.getContents();
      const textArray: string[] = [];

      let currentText = "";

      delta.ops && delta.ops.forEach((op: any) => {

        if (op.insert) {
          if (typeof op.insert === "string") {
            currentText += op.insert;
          } else if (typeof op.insert === "object" && "image" in op.insert) {

            if (currentText.trim()) {
              textArray.push(currentText.trim());
              currentText = "";
            }

            if (op.insert.image) {

              imageArray.push(op.insert.image);

            }
          }
        }
      });

      if (currentText.trim()) {
        textArray.push(currentText.trim());
      }

      while (textArray.length < imageArray.length) {
        textArray.push("");
      }

      while (imageArray.length < textArray.length) {
        imageArray.push("");
      }

      const imageFiles = imageArray.map((base64Image, index) => {
        return base64ToFile(base64Image, `image_${index}.png`);
      }).filter(file => file !== null); // Filter out null values

      console.log("Text Array:", textArray);
      // console.log('Image Array (base64):', imageArray);
      console.log("Image Array (File objects):", imageFiles);

      const content = {

        title: inputTitle,
        referenceUrl: inputKaynak,
        commentEnabled: false,
        watermarkEnabled: false,
        categories: selectedCategories,
        locationId: selectedLocationId,
        descriptions: textArray,


      };


      token && dispatch(publishNews(
        token,
        imageFiles,
        content,
        textArray,
      ));


    }
  };
  const [newPubishable , setNewPubishable] = useState(false)

  return (
    <>
      <section className="max-widthed">
        <Header noSearchBox={true} />

        <section className="home-content feeds-section">


          <div className="publish-left-review" style={{ display: previewPage ? "block" : "none" }}>

            <div className="review-post">
              <div className="review-post-left">
                <div className="review-post-box">
                  <img className="prev-img-post" src={imagePreview ? imagePreview  : noImage} />


                  <div className="my-user-inf">
                    <div className="post-head-left-intro-my">
                      <img src={userIco}   alt="avatar" className="poster-img-intro-my" />
                        
                      <div className="single-post-user-intro-my"><span
                        className="name-surname-intro-my">
                        </span><span
                          className="usr-name-intro-my">@ {userProfileDatas &&
                            userProfileDatas.nickname }</span></div>
                    </div>
                  </div>

                  <p className="desc-review-post">
                    {inputTitle}
                  </p>


                </div>
              </div>


              <div className="review-post-right">
                <div className="review-post-row">
                  <div className="review-post-left-img"><img src={categoriesIco} /></div>

                  <div className="review-post-right-txt">

                    <h4> Kategori ekle</h4>
                    <span
                      className="info-review-post"> En fazla 3 adet kategori ekleyebilirsin. </span>
                    <br />
                    <div className="select-cats">
                      <div className="categories-slct">
                        {categories.map((category, index) => (
                          <span
                            key={index}
                            className={
                              selectedCategories.includes(index) ? "single-cat selected-cat"
                                : "single-cat"
                            }
                            onClick={() => toggleCategory(index)}
                          >
                            {category} <img src={selectedCat} alt="" />
                          </span>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>


                <div className="review-post-row">
                  <div className="review-post-left-img"><img src={locationIco} /></div>

                  <div className="review-post-right-txt">
                    <h4> Konum ekle</h4>
                    <span
                      className="info-review-post"> En fazla 3 adet kategori ekleyebilirsin. </span>


                    <select className="regis-select" value={selectedLocationId}
                      onChange={handleLocationChange}>

                      {locationList.map((location) => (
                        <option key={location.id} value={location.id}>
                          {location.name}
                        </option>
                      ))}
                    </select>


                  </div>
                </div>


                <div className="review-post-row">
                  <div className="review-post-left-img"><img src={searchIco} /></div>

                  <div className="review-post-right-txt">
                    <h4> Kaynak ekle</h4>

                    <input
                      className="info-review-input"
                      placeholder="www.jurnalle.com"
                      value={inputKaynak}
                      onChange={(e) => setInputKaynak(e.target.value)}

                    />

                  </div>
                </div>

              </div>


            </div>

       


          </div>


          <div className="publish-left" style={{ display: previewPage ? "none" : "block" }}>
            <div className="title-pubish">


              <textarea
                cols={40}
                rows={2}
                value={inputTitle}
                onChange={(e) => setInputTitle(e.target.value)}
                style={{ width: "84%", overflowWrap: "break-word" }}
                placeholder="Title"
              />

            </div>
            <ReactQuill
              ref={quillRef}
              theme="snow"
              modules={editorModules}
              placeholder="Haber içeriği..."
            />


          </div>





        </section>
        <MobileMenu />
      </section>


      {

        inputTitle.length ?


          <span className={hideBtnPreview ? "publish-btn top-right-abs hide-btn green-next"
            : "publish-btn top-right-abs green-next"} onClick={() => {

              handlePreviewPost();
              setNewPubishable(true)

            }}>Ileri   <img src={rightIcoBlack} />
          </span>

          :



          <span className={hideBtnPreview ? "publish-btn top-right-abs hide-btn"
            : "publish-btn top-right-abs"} onClick={() => {
              console.log("fill the title")
            }}>Ileri   <img src={rightIco} />
          </span>



      }


          {
              loadingPublishNews && newPubishable ? <span className="publish-btn top-right-abs"><FadeLoader
                color="white" /></span>
                :
                !loadingPublishNews && newPubishable ?

                // selectedLocationId == "0" ?  <span className="publish-btn top-right-abs
                // low-opacity"   onClick={()=>console.log("enter location")}>  Haberi paylaş
                // </span> :

                <span className="publish-btn top-right-abs" onClick={handleFinish}>
                  paylaş <img src={rightIco} /></span>

                  : ""
            }



    </>
  );
};

export default CreateNewsV2;