import { ICategoryLocalDataSource } from "./ICategoryLocalDataSource";
import { CategoryModel } from "../../../../product/models/category/categoryModel";
import { injectable } from "inversify";

@injectable()
export class CategoryLocalDataSource implements ICategoryLocalDataSource {
  private readonly storageKey = "userCategoriesV1";

  getUserCategories(): CategoryModel[] {
    const data = localStorage.getItem(this.storageKey);
    if (data) {
      return JSON.parse(data) as CategoryModel[];
    }
    return [];
  }

  saveUserCategories(categories: CategoryModel[]): void {
    const data = JSON.stringify(categories);
    localStorage.setItem(this.storageKey, data);
  }
}
