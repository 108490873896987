import React from "react";
import "./rightBlockMyProfile.css";


const RightBlock: React.FC = () => {
  return (
    <div className="right-side">
      <div className="right-side-ads"></div>
      <div className="right-block-content-my">
        <h3>Gündem</h3>

        <div className="cat-block">
          <span>Gündem</span>
          <p>İstanbul</p>
          <span>2400</span>
        </div>

        <div className="cat-block">
          <span>Gündem</span>
          <p>İstanbul</p>
          <span>2400</span>
        </div>

        <div className="cat-block">
          <span>Gündem</span>
          <p>İstanbul</p>
          <span>2400</span>
        </div>

        <div className="cat-block">
          <span>Gündem</span>
          <p>İstanbul</p>
          <span>2400</span>
        </div>

        <div className="cat-block">
          <span>Gündem</span>
          <p>İstanbul</p>
          <span>2400</span>
        </div>

        <div className="cat-block">
          <span>Gündem</span>
          <p>İstanbul</p>
          <span>2400</span>
        </div>

      </div>

    </div>

  );
};

export default RightBlock;
