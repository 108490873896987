import { format, isToday, parseISO } from "date-fns";
import { tr } from "date-fns/locale";

class DateTimeUtil {
  /**
   * This method is used to parse the date and time to hour and minute.
   * If it is today, it returns the time in 'HH:mm' format, otherwise it returns the date.
   * @param dateTime - The date and time string to parse.
   * @returns The formatted date or time string.
   */
  static parseDateTimeToHourMinute(dateTime: string | undefined): string {
    if (!dateTime) {
      return "";
    }

    const utcDateTime = parseISO(dateTime);

    if (isToday(utcDateTime)) {
      return format(utcDateTime, "HH:mm", { locale: tr });
    } else {
      return this.parseCreatedAt(dateTime);
    }
  }

  /**
   * Parses the created at date string to a formatted date string.
   * @param dateTime - The date and time string to parse.
   * @returns The formatted date string.
   */
  static parseCreatedAt(dateTime: string): string {
    const date = parseISO(dateTime);
    return format(date, "d MMMM yyyy", { locale: tr });
  }

}

export default DateTimeUtil;