import {
  LOGIN_FAILED,
  LOGIN_START,
  LOGIN_SUCCESS,
  NEWS_LIST_BY_CATEGORY_DECREASE_ATTEMPT_COUNT,
  NEWS_LIST_BY_CATEGORY_FAILED,
  NEWS_LIST_BY_CATEGORY_INCREASE_ATTEMPT_COUNT,
  NEWS_LIST_BY_CATEGORY_PAGE_RESET,
  NEWS_LIST_BY_CATEGORY_SHOULD_FETCH,
  NEWS_LIST_BY_CATEGORY_SHOULD_NOT_FETCH,
  NEWS_LIST_BY_CATEGORY_START,
  NEWS_LIST_BY_CATEGORY_SUCCESS,
  NEWS_LIST_FAILED,
  NEWS_LIST_START,
  NEWS_LIST_SUCCESS,
  NEWSLISTDECREASEATTEMPTCOUNT,
  NEWSLISTINCREASEATTEMPTCOUNT,
  NEWSLISTSHOULDFETCH,
  NEWSLISTSHOULNOTDFETCH,
  PUBLISHNEWS_FAILED,
  PUBLISHNEWS_START,
  PUBLISHNEWS_SUCCESS,
  REGISTERCATEGORIES_FAILED,
  REGISTERCATEGORIES_START,
  REGISTERCATEGORIES_SUCCESS,
  REGISTERPERSONALINFO_FAILED,
  REGISTERPERSONALINFO_START,
  REGISTERPERSONALINFO_SUCCESS,
  SIGNUP_FAILED,
  SIGNUP_START,
  SIGNUP_SUCCESS,
  USERCATEGORIES_FAILED,
  USERCATEGORIES_START,
  USERCATEGORIES_SUCCESS,
  USERPROFILEDATA_FAILED,
  USERPROFILEDATA_START,
  USERPROFILEDATA_SUCCESS,
  USERPROFILEDATAOTHER_FAILED,
  USERPROFILEDATAOTHER_START,
  USERPROFILEDATAOTHER_SUCCESS,
} from "./types";

export const signupStart = () => {
  return {
    type: SIGNUP_START,
  };
};

export const signupSuccess = (payload: any) => {
  return {
    type: SIGNUP_SUCCESS,
    payload,
  };
};

export const signupFailed = (err: any) => {
  return {
    type: SIGNUP_FAILED,
    err: err,
  };
};

export const signup = (data: any) => {
  return (dispatch: any) => {
    dispatch(signupStart());

    fetch("https://api-prod.jurnalle.com/api/v1/auth/sign_up", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        fullName: data.nameSurname,
        nickname: data.userName,
        email: data.email,
        password: data.password,
      }),
    })
    .then((res) => res.json())
    .then((responseData) => {

      if (responseData.token) {
        dispatch(signupSuccess(responseData));
      } else {

        console.error("Signup failed: No token received", responseData);
        dispatch(signupFailed("No token received"));
      }
    })
    .catch((err) => {
      console.error("Signup error:", err);
      dispatch(signupFailed(err.toString()));
    });
  };
};


//user profile registration step 2 start . personal info start

export const registerPersonalInfoStart = () => {
  return {
    type: REGISTERPERSONALINFO_START,
  };
};

export const registerPersonalInfoSuccess = (payload: any) => {
  return {
    type: REGISTERPERSONALINFO_SUCCESS,
    payload,
  };
};

export const registerPersonalInfoFailed = (err: any) => {
  return {
    type: REGISTERPERSONALINFO_FAILED,
    err: err,
  };
};

export const registerPersonalInfo = (data: any, token: String) => {
  return (dispatch: any) => {
    dispatch(registerPersonalInfoStart());
    fetch(`https://api-prod.jurnalle.com/api/v1/user/profile`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },

      body: JSON.stringify({
        birthdate: data.birthdate,
        gender: data.gender,
        location: data.location,

      }),
    })
    .then((res) => res.json())
    .then((data) => {
      if (data) {

        dispatch(registerPersonalInfoSuccess(data));

      } else {


        //  dispatch(signupFailed(err));
      }
    })
    .catch((err) => {
      console.log(err);
      //  dispatch(signupFailed(err));

    });
  };
};


/// user categories select

export const registerCategoriesStart = () => {
  return {
    type: REGISTERCATEGORIES_START,
  };
};

export const registerCategoriesSuccess = () => {
  return {
    type: REGISTERCATEGORIES_SUCCESS,

  };
};

export const registerCategoriesFailed = (err: any) => {
  return {
    type: REGISTERCATEGORIES_FAILED,
    err: err,
  };
};


export const registerCategories = (data: any, token: string) => {

  return (dispatch: any) => {
    dispatch(registerCategoriesStart());

    fetch(`https://api-prod.jurnalle.com/api/v1/user-category`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    })
    .then((res) => {

      dispatch(registerCategoriesSuccess());

      const expirationDate = new Date();
      expirationDate.setHours(expirationDate.getHours() + 24);
      localStorage.setItem("authToken", JSON.stringify(token));
      localStorage.setItem("authTokenExpiration", expirationDate.toISOString());


      if (!res.ok) {
        throw new Error(`HTTP error! Status: ${res.status}`);
      }
      return res.text();
    })
    .then((text) => {
      try {
        const jsonData = text ? JSON.parse(text) : {};

      } catch (error) {
        console.error("Failed :", error);
      }
    })
    .catch((err) => {

    });
  };
};


//LOGİN functions start

export const loginStart = () => {
  return {
    type: LOGIN_START,
  };
};

export const loginSuccess = (payload: any) => {
  return {
    type: LOGIN_SUCCESS,
    payload,
  };
};

export const loginFailed = (err: any) => {
  return {
    type: LOGIN_FAILED,
    err: err,
  };
};


export const login = (data: any) => {


  return (dispatch: any) => {
    dispatch(loginStart());

    fetch(`https://api-prod.jurnalle.com/api/v1/auth/sign_in`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
    .then((res) => {

      if (!res.ok) {
        throw new Error(`HTTP error! Status: ${res.status}`);
      }
      return res.text();
    })
    .then((text) => {
      try {
        const jsonData = text ? JSON.parse(text) : {};
        dispatch(loginSuccess(jsonData));
        const userToken = JSON.stringify(jsonData.token);
        const expirationDate = new Date();
        expirationDate.setHours(expirationDate.getHours() + 24);
        localStorage.setItem("authToken", userToken);
        localStorage.setItem("authTokenExpiration", expirationDate.toISOString());
        const cleanedToken = userToken.replace(/^['"]|['"]$/g, "");
        localStorage.setItem("accessToken", cleanedToken);


      } catch (error) {

        dispatch(loginFailed(error));
      }
    })
    .catch((err) => {

      dispatch(loginFailed(err));

    });
  };
};


const getToken = (token: string) => {
  return "Bearer " + JSON.parse(token);
};

//USER data functions start

export const userProfileDataStart = () => {
  return {
    type: USERPROFILEDATA_START,

  };
};

export const userProfileDataSuccess = (payload: any) => {
  return {
    type: USERPROFILEDATA_SUCCESS,
    payload,
  };
};

export const userProfileDataFailed = (err: any) => {
  return {
    type: USERPROFILEDATA_FAILED,
    err: err,
  };
};


export const userProfileData = (token: string) => {

  return (dispatch: any) => {
    dispatch(userProfileDataStart());

    fetch(`https://api-prod.jurnalle.com/api/v1/user/profile`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: getToken(token),
      },

    })
    .then((res) => {

      if (!res.ok) {
        throw new Error(`HTTP error! Status: ${res.status}`);
      }
      return res.text();
    })
    .then((text) => {
      try {
        const jsonData = text ? JSON.parse(text) : {};

        dispatch(userProfileDataSuccess(jsonData));


      } catch (error) {

        dispatch(userProfileDataFailed(error));
      }
    })
    .catch((err) => {

      dispatch(userProfileDataFailed(err));

    });

  };

};


// get all news list

export const newsListStart = () => {
  return {
    type: NEWS_LIST_START,
  };
};

export const newsListSuccess = (payload: any) => {
  return {
    type: NEWS_LIST_SUCCESS,
    payload,
  };
};

export const newsListFailed = (err: any) => {
  return {
    type: NEWS_LIST_FAILED,
    err,
  };
};


export const newsListIncreaseAttemptCount = () => {
  return {
    type: NEWSLISTINCREASEATTEMPTCOUNT,
  };
};

export const newsListDecreaseAttemptCount = () => {
  return {
    type: NEWSLISTDECREASEATTEMPTCOUNT,
  };
};


export const newsListShoudFetch = () => {
  return {
    type: NEWSLISTSHOULDFETCH,
  };
};

export const newsListShoudNotFetch = () => {
  return {
    type: NEWSLISTSHOULNOTDFETCH,
  };
};


export const getNewsList = (token: string, page: number) => {

  return (dispatch: any) => {
    dispatch(newsListStart());


    fetch(`https://api-prod.jurnalle.com/api/news?page=${page}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: getToken(token),
      },
    })
    .then((res) => {
      if (!res.ok) {

        throw new Error(`HTTP error! Status: ${res.status}`);
      }
      return res.json();
    })
    .then((jsonData) => {
      try {


        if (Array.isArray(jsonData) && jsonData.length > 0) {

          dispatch(newsListDecreaseAttemptCount());
        } else {

          dispatch(newsListIncreaseAttemptCount());
        }

        dispatch(newsListSuccess(jsonData));
      } catch (error) {
        dispatch(newsListFailed(error));
      }
    })
    .catch((err) => {
      dispatch(newsListFailed(err));

    });
  };
};


// get user categories


export const userCategoriesStart = () => {
  return {
    type: USERCATEGORIES_START,

  };
};

export const userCategoriesSuccess = (payload: any) => {
  return {
    type: USERCATEGORIES_SUCCESS,
    payload,
  };
};

export const userCategoriesFailed = (err: any) => {
  return {
    type: USERCATEGORIES_FAILED,
    err: err,
  };
};


export const userCategories = (token: string) => {

  return (dispatch: any) => {
    dispatch(userCategoriesStart());

    fetch(`https://api-prod.jurnalle.com/api/v1/category`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: getToken(token),
      },

    })
    .then((res) => {

      if (!res.ok) {
        throw new Error(`HTTP error! Status: ${res.status}`);
      }
      return res.text();
    })
    .then((text) => {
      try {
        const jsonData = text ? JSON.parse(text) : {};

        dispatch(userCategoriesSuccess(jsonData));


      } catch (error) {

        dispatch(userCategoriesFailed(error));
      }
    })
    .catch((err) => {

      dispatch(userCategoriesFailed(err));

    });

  };


};

//news by category

export const newsListByCategoryStart = (categoryId: number) => ({
  type: NEWS_LIST_BY_CATEGORY_START,
  payload: {
    categoryId,
  },
});

export const newsListByCategorySuccess = (
  jsonData: any,
  categoryIdFetched: number,
  categoryBeforFetch: number,
) => ({
  type: NEWS_LIST_BY_CATEGORY_SUCCESS,
  payload: {
    jsonData,
    categoryIdFetched,
    categoryBeforFetch,
  },
});

export const newsListByCategoryFailed = (err: any) => ({
  type: NEWS_LIST_BY_CATEGORY_FAILED,
  err,
});

export const newsListByCategoryShouldFetch = () => ({
  type: NEWS_LIST_BY_CATEGORY_SHOULD_FETCH,
});


export const newsListByCategoryPageReset = () => ({
  type: NEWS_LIST_BY_CATEGORY_PAGE_RESET,
});


export const newsListByCategoryShouldNotFetch = () => ({
  type: NEWS_LIST_BY_CATEGORY_SHOULD_NOT_FETCH,
});

export const newsListByCategoryIncreaseAttemptCount = () => ({
  type: NEWS_LIST_BY_CATEGORY_INCREASE_ATTEMPT_COUNT,
});

export const newsListByCategoryDecreaseAttemptCount = () => ({
  type: NEWS_LIST_BY_CATEGORY_DECREASE_ATTEMPT_COUNT,
});


export const getNewsListByCategory = (
  token: string, categoryId: number, page: number, actualCategry: number) => {

  return (dispatch: any) => {
    dispatch(newsListByCategoryStart(categoryId));


    const url = categoryId == actualCategry
      ? `https://api-prod.jurnalle.com/api/news?categoryId=${categoryId}&page=${page}` :
      `https://api-prod.jurnalle.com/api/news?categoryId=${categoryId}&page=1`;


    categoryId != actualCategry && dispatch(newsListByCategoryPageReset());


    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: getToken(token),
      },
    })
    .then((res) => {
      if (!res.ok) {

        throw new Error(`HTTP error! Status: ${res.status}`);
      }
      return res.json(); // Directly parse to JSON
    })
    .then((jsonData) => {
      try {


        if (Array.isArray(jsonData) && jsonData.length > 0) {


          dispatch(newsListByCategoryDecreaseAttemptCount());
        } else {

          dispatch(newsListByCategoryIncreaseAttemptCount());

        }


        dispatch(newsListByCategorySuccess(jsonData, categoryId, actualCategry));
        //  console.log(jsonData + " ar cat data ")

      } catch (error) {
        dispatch(newsListByCategoryFailed(error));
        console.log(error, "ssds");
      }
    })
    .catch((err) => {
      dispatch(newsListByCategoryFailed(err));
      console.log(err, "21");
    });
  };
};


// visit otheer user profile


//USER data functions start

export const userProfileDataOtherStart = () => {
  return {
    type: USERPROFILEDATAOTHER_START,

  };
};

export const userProfileDataOtherSuccess = (payload: any) => {
  return {
    type: USERPROFILEDATAOTHER_SUCCESS,
    payload,
  };
};

export const userProfileDataOtherFailed = (err: any) => {
  return {
    type: USERPROFILEDATAOTHER_FAILED,
    err: err,
  };
};


export const userProfileDataOther = (token: string, userId: number) => {

  return (dispatch: any) => {
    dispatch(userProfileDataOtherStart());

    fetch(`https://api-prod.jurnalle.com/api/v1/user/profile/${userId}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: getToken(token),
      },

    })
    .then((res) => {

      if (!res.ok) {
        throw new Error(`HTTP error! Status: ${res.status}`);
      }
      return res.text();
    })
    .then((text) => {
      try {
        const jsonData = text ? JSON.parse(text) : {};

        dispatch(userProfileDataOtherSuccess(jsonData));


      } catch (error) {

        dispatch(userProfileDataFailed(error));
      }
    })
    .catch((err) => {

      dispatch(userProfileDataOtherFailed(err));

    });

  };

};


//make a post  functions start

export const publishNewsStart = () => {
  return {
    type: PUBLISHNEWS_START,

  };
};

export const publishNewsSuccess = (payload: any) => {
  return {
    type: PUBLISHNEWS_SUCCESS,
    payload,
  };
};

export const publishNewsFailed = (err: any) => {
  return {
    type: PUBLISHNEWS_FAILED,
    err: err,
  };
};


export const publishNews = (token: string, files: any[], content: any, textArray: any) => {
  return (dispatch: any) => {
    dispatch(publishNewsStart());

    const formData = new FormData();

    // Append files to FormData
    files.forEach((file, _) => {
      formData.append(`files`, file);
    });


    // Append content as JSON
    const jsonBlob = new Blob([JSON.stringify(content)], { type: "application/json" });
    formData.append("news", jsonBlob);

    // Log FormData contents for debugging
    for (let [key, value] of formData.entries()) {
      console.log(`${key}:`, value);
    }

    const news: any = {
      news: {
        title: "title tec",
      },
    };


    fetch(`https://api-prod.jurnalle.com/api/news/v2`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: getToken(token),
      },
      body: formData,
    })
    .then((res) => {
      if (!res.ok) {
        throw new Error(`HTTP error! Status: ${res.status}`);
      }
      return res.text();
    })
    .then((text) => {
      try {
        const jsonData = text ? JSON.parse(text) : {};
        setTimeout(() => {
          dispatch(publishNewsSuccess(jsonData));
        }, 3000);

      } catch (error) {
        dispatch(publishNewsFailed(error));
      }
    })
    .catch((err) => {
      dispatch(publishNewsFailed(err));
    });
  };
};
