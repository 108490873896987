import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import "./CreateNewsLayout.css";
import CreateNewsHeader from "../../../../product/components/header/CreateNewsHeader";
import SideBarV2 from "../../../../product/components/sidebar/SideBarV2";
const CreateNewsLayout: React.FC = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const storedToken = localStorage.getItem("authToken");
    const storedExpiration = localStorage.getItem("authTokenExpiration");
    const now = new Date();

    if (storedExpiration) {
      const expirationDate = new Date(storedExpiration);

      if (now > expirationDate) {
        localStorage.removeItem("authToken");
        localStorage.removeItem("authTokenExpiration");
        navigate("/welcome");
      } else {
        if (storedToken) {

        }
      }
    } else {
      navigate("/welcome");
    }
  }, []);

  return (
    <div className="app-container">
      <div className="main-content news-layout">
        <SideBarV2 />
        <div className="content">
           <CreateNewsHeader />
          <Outlet /> {/* This will render the child routes */}
        </div>
      </div>
    </div>
  );
};

export default CreateNewsLayout;
