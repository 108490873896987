import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAppSelector } from "../../stores/hooks";
import { AuthStatus } from "../../stores/auth/AuthStatus";
import { selectAuthStatus } from "../../stores/auth/authSlice";

const ProtectedRouteV2: React.FC = () => {
  const authStatus = useAppSelector(selectAuthStatus);
  const location = useLocation();

  // Allow access to /news-details/:param without authentication
  if (location.pathname.startsWith("/news-details/")) {
    return <Outlet />;
  }
  // Redirect to /landing if user is not authenticated
  if (authStatus !== AuthStatus.Authenticated) {
    return <Navigate to='/landing' replace />;
  }

  // If authenticated, render the child component(s)
  return <Outlet />;
};

export default ProtectedRouteV2;
