import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../stores/hooks";
import { fetchUserCategories, setCategoryId } from "../../../../stores/news/feed/feedSlice";
import "./CategoryList.css";
import { ReactComponent as ChevronRight } from "../../../../product/assets/iconsV2/outlined/chevron_right.svg";
import { ReactComponent as ChevronLeft } from "../../../../product/assets/iconsV2/outlined/chevron_left.svg";

const CategoryList: React.FC = () => {
  const dispatch = useAppDispatch();
  const categories = useAppSelector((state) => state.feed.categories);
  const selectedCategoryId = useAppSelector((state) => state.feed.categoryId);

  const categoryContainerRef = useRef<HTMLDivElement>(null);
  const [isAtLeftEnd, setIsAtLeftEnd] = useState<boolean>(true);
  const [isAtRightEnd, setIsAtRightEnd] = useState<boolean>(false);

  useEffect(() => {
    dispatch(fetchUserCategories());
  }, [dispatch]);

  const updateScrollEndStatus = () => {
    if (categoryContainerRef.current) {
      const { scrollWidth, clientWidth, scrollLeft } = categoryContainerRef.current;
      setIsAtLeftEnd(scrollLeft <= 0);
      setIsAtRightEnd(scrollLeft >= scrollWidth - clientWidth);
    }
  };

  useLayoutEffect(() => {
    // Initial scroll status check on mount
    updateScrollEndStatus();
  }, [categories.length]);

  useEffect(() => {
    const handleScroll = () => {
      updateScrollEndStatus();
    };

    categoryContainerRef.current?.addEventListener("scroll", handleScroll);
    return () => categoryContainerRef.current?.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollCategories = (direction: "left" | "right") => {
    if (categoryContainerRef.current) {
      const scrollAmount = direction === "right" ? 150 : -150;
      categoryContainerRef.current.scrollBy({ left: scrollAmount, behavior: "smooth" });
    }
  };

  const handleCategoryClick = (categoryId: number | undefined) => {
    dispatch(setCategoryId(categoryId));
  };

  return (
    <div className='category-container'>
      {/** Render left chevron conditionally based on scroll position */}
      {!isAtLeftEnd && (
        <ChevronLeft onClick={() => scrollCategories("left")} className='category-scroll-arrow left-arrow' />
      )}
      <div className='category-list' ref={categoryContainerRef}>
        {categories.map((category) => (
          <div
            key={category.id}
            className={`category-item ${category.id === selectedCategoryId ? "active" : ""}`}
            onClick={() => handleCategoryClick(category.id)}
          >
            {category.name}
          </div>
        ))}
      </div>
      {/** Right chevron is always visible but disabled when at the end */}
      <ChevronRight
        onClick={() => !isAtRightEnd && scrollCategories("right")}
        className={`category-scroll-arrow right-arrow ${isAtRightEnd ? "disabled" : ""}`}
      />
    </div>
  );
};

export default CategoryList;
