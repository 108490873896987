
export const SIGNUP_START  =  "SIGNUP_START";
export const SIGNUP_SUCCESS  =  "SIGNUP_SUCCESS";
export const SIGNUP_FAILED  =  "SIGNUP_FAILED"; 


export const REGISTERPERSONALINFO_SUCCESS  =  "REGISTERPERSONALINFO_SUCCESS";
export const REGISTERPERSONALINFO_FAILED  =  "REGISTERPERSONALINFO_FAILED";
export const REGISTERPERSONALINFO_START  =  "REGISTERPERSONALINFO_START"; 

export const REGISTERCATEGORIES_SUCCESS  =  "REGISTERCATEGORIES_SUCCESS";
export const REGISTERCATEGORIES_START  =  "REGISTERCATEGORIES_START";
export const REGISTERCATEGORIES_FAILED  =  "REGISTERCATEGORIES_FAILED"; 


export const LOGIN_SUCCESS  =  "LOGIN_SUCCESS";
export const LOGIN_FAILED  =  "LOGIN_FAILED";
export const LOGIN_START  =  "LOGIN_START"; 


export const USERPROFILEDATA_SUCCESS  =  "USERPROFILEDATA_SUCCESS";
export const USERPROFILEDATA_FAILED  =  "USERPROFILEDATA_FAILED";
export const USERPROFILEDATA_START  =  "USERPROFILEDATA_START"; 


export const NEWS_LIST_START = 'NEWS_LIST_START';
export const NEWS_LIST_SUCCESS = 'NEWS_LIST_SUCCESS';
export const NEWS_LIST_FAILED = 'NEWS_LIST_FAILED';
export const NEWSLISTSHOULDFETCH = 'NEWSLISTSHOULDFETCH';
export const NEWSLISTSHOULNOTDFETCH = 'NEWSLISTSHOULNOTDFETCH';
export const NEWSLISTDECREASEATTEMPTCOUNT = 'NEWSLISTDECREASEATTEMPTCOUNT';
export const NEWSLISTINCREASEATTEMPTCOUNT = 'NEWSLISTINCREASEATTEMPTCOUNT';



export const USERCATEGORIES_SUCCESS = 'USERCATEGORIES_SUCCESS';
export const USERCATEGORIES_START = 'USERCATEGORIES_START';
export const USERCATEGORIES_FAILED = 'USERCATEGORIES_FAILED';


export const NEWS_LIST_BY_CATEGORY_START = 'NEWS_LIST_BY_CATEGORY_START';
export const NEWS_LIST_BY_CATEGORY_SUCCESS = 'NEWS_LIST_BY_CATEGORY_SUCCESS';
export const NEWS_LIST_BY_CATEGORY_FAILED = 'NEWS_LIST_BY_CATEGORY_FAILED';
export const NEWS_LIST_BY_CATEGORY_SHOULD_FETCH = 'NEWS_LIST_BY_CATEGORY_SHOULD_FETCH';
export const NEWS_LIST_BY_CATEGORY_SHOULD_NOT_FETCH = 'NEWS_LIST_BY_CATEGORY_SHOULD_NOT_FETCH';
export const NEWS_LIST_BY_CATEGORY_INCREASE_ATTEMPT_COUNT = 'NEWS_LIST_BY_CATEGORY_INCREASE_ATTEMPT_COUNT';
export const NEWS_LIST_BY_CATEGORY_DECREASE_ATTEMPT_COUNT = 'NEWS_LIST_BY_CATEGORY_DECREASE_ATTEMPT_COUNT';


export const NEWS_LIST_BY_CATEGORY_PAGE_RESET = "NEWS_LIST_BY_CATEGORY_PAGE_RESET";

export const USERPROFILEDATAOTHER_SUCCESS = "USERPROFILEDATAOTHER_SUCCESS";
export const USERPROFILEDATAOTHER_FAILED = "USERPROFILEDATAOTHER_FAILED";
export const USERPROFILEDATAOTHER_START = "USERPROFILEDATAOTHER_START";

export const PUBLISHNEWS_SUCCESS = "PUBLISHNEWS_SUCCESS";
export const PUBLISHNEWS_FAILED = "PUBLISHNEWS_FAILED";
export const PUBLISHNEWS_START = "PUBLISHNEWS_START";


