import * as actionTypes from "./types";



const initialState = {
  loadingSignup: false,
  Signup: {},
  errorSignup: false,
  successSignUp: false,


  loadingRegisterPersonalInfo: false,
  errorRegisterPersonalInfo: false,
  successRegisterPersonalInfo: false,

  loadingRegisterCategories: false,
  errorRegisterCategories: false,
  successRegisterCategories: false,


  loadingLogin: false,
  errorLogin: false,
  successLogin: false,



  loadingUserprofiledata: false,
  errorUserprofiledata: false,
  successUserprofiledata: false,
  userProfileData: {},


  loadingUserprofiledataOther: false,
  errorUserprofiledataOther: false,
  successUserprofiledataOther: false,
  userProfileDataOther: {},





  loadingNewsList: false,
  dataNewsList: [{"id":3223130849038439,
    "slug":"null",
    "user":{"id":2932094,"fullName":"",
      "imageUrl":null,"nickname":"",
      "verified":false},"title":"",
      "thumbnail":"",
      "referenceUrl":"",
      "verificationCount":0,"denialCount":0,"referenceCount":0,"viewCount":0,"readCount":0,"bookmarkCount":0,"commentCount":0,
      "shareCount":0,"commentEnabled":true,"watermarkEnabled":true,"editedAt":null,
      "createdAt":"","updatedAt":"","category":null,
      "categories":[{"id":1,"name":""}] }],
  errorNewsList: false,
  newsListPage: 1,
  attemptCount: 0,
  shouldFetch: false,




  
  loadingCategories: false,
  errorCategories: false,
  categories: [],



  //news by categories
  currentCategory : 1 ,
  loadingNewsListCategory: false,
  dataNewsListCategory: [{"id":3223130849038439,
    "slug":"null",
    "user":{"id":2932094,"fullName":"",
      "imageUrl":null,"nickname":"",
      "verified":false},"title":"",
      "thumbnail":"",
      "referenceUrl":"",
      "verificationCount":0,"denialCount":0,"referenceCount":0,"viewCount":0,"readCount":0,"bookmarkCount":0,"commentCount":0,
      "shareCount":0,"commentEnabled":true,"watermarkEnabled":true,"editedAt":null,
      "createdAt":"","updatedAt":"","category":null,
      "categories":[{"id":1,"name":""}] }],
  errorNewsListCategory: false,
  newsListCategoryPage: 1,
  attemptCountNewsCat: 0,
  shouldFetchNewsCat: false,


  
  loadingPublishNews: false,
  successPublishNews: false,
  errorPublishNews: false,

  

};




const ReducerGeneral = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.SIGNUP_START:
      return {
        ...state,
        loadingSignup: true,
        errorSignup: false,
        successSignUp: false,

      };

    case actionTypes.SIGNUP_SUCCESS:
      return {
        ...state,
        Signup: action.payload,
        loadingSignup: false,
        errorSignup: false,
        successSignUp: true,
      };

    case actionTypes.SIGNUP_FAILED:
      return {
        ...state,
        loadingSignup: false,
        errorSignup: true,
        successSignUp: false,

      };



    //personal info register
    case actionTypes.REGISTERPERSONALINFO_START:
      return {
        ...state,
        loadingRegisterPersonalInfo: true,
        errorRegisterPersonalInfo: false,
        successRegisterPersonalInfo: false,

      };

    case actionTypes.REGISTERPERSONALINFO_SUCCESS:
      return {
        ...state,
        loadingRegisterPersonalInfo: false,
        errorRegisterPersonalInfo: false,
        successRegisterPersonalInfo: true,
      };

    case actionTypes.REGISTERPERSONALINFO_FAILED:
      return {
        ...state,
        loadingRegisterPersonalInfo: false,
        errorRegisterPersonalInfo: true,
        successRegisterPersonalInfo: false,

      };

    //user categories register

    case actionTypes.REGISTERCATEGORIES_START:
      return {
        ...state,
        loadingRegisterCategories: true,
        errorRegisterCategories: false,
        successRegisterCategories: false,

      };

    case actionTypes.REGISTERCATEGORIES_SUCCESS:
      return {
        ...state,
        loadingRegisterCategories: false,
        errorRegisterCategories: false,
        successRegisterCategories: true,
      };

    case actionTypes.REGISTERCATEGORIES_FAILED:
      return {
        ...state,
        loadingRegisterCategories: false,
        errorRegisterCategories: true,
        successRegisterCategories: false,

      };

    //user login

    case actionTypes.LOGIN_START:
      return {
        ...state,
        loadingLogin: true,
        errorLogin: false,
        successLogin: false,

      };

    case actionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        loadingLogin: false,
        errorLogin: false,
        successLogin: true,
      };

    case actionTypes.LOGIN_FAILED:
      return {
        ...state,
        loadingLogin: false,
        errorLogin: true,
        successLogin: false,

      };


    //user profile data

    
  
  
  case actionTypes.USERPROFILEDATAOTHER_START:
    return {
      ...state,
      loadingUserprofiledataOther: true,
      errorUserprofiledataOther: false,
      successUserprofiledataOther: false,
      userProfileDataOther: {}


    };

  case actionTypes.USERPROFILEDATAOTHER_SUCCESS:
    return {
      ...state,
      loadingUserprofiledataOther: false,
      errorUserprofiledataOther: false,
      successUserprofiledataOther: false,
      userProfileDataOther: action.payload

    };

  case actionTypes.USERPROFILEDATAOTHER_FAILED:
    return {
      ...state,
      loadingUserprofiledataOther: false,
      errorUserprofiledataOther: action.payload,
      successUserprofiledataOther: false,
      userProfileDataOther: {}


    };







    case actionTypes.USERPROFILEDATA_START:
      return {
        ...state,
        loadingUserprofiledata: true,
        errorUserprofiledata: false,
        successUserprofiledata: false,
        userProfileData: {}


      };

    case actionTypes.USERPROFILEDATA_SUCCESS:
      return {
        ...state,
        loadingUserprofiledata: false,
        errorUserprofiledata: false,
        successUserprofiledata: false,
        userProfileData: action.payload

      };

    case actionTypes.USERPROFILEDATA_FAILED:
      return {
        ...state,
        loadingUserprofiledata: false,
        errorUserprofiledata: action.payload,
        successUserprofiledata: false,
        userProfileData: {}


      };






    case actionTypes.NEWS_LIST_START:
      return {
        ...state,
        loadingNewsList: true,
        newsListPage: state.newsListPage + 1,

      };

    case actionTypes.NEWS_LIST_SUCCESS:
     

      const flattenedPayload = action.payload.flat();
      return {
        ...state,
        loadingNewsList: false,
        
          dataNewsList: [
            ...state.dataNewsList,
            ...flattenedPayload
          ],

        errorNewsList: false,
      };

    case actionTypes.NEWS_LIST_FAILED:
      return {
        ...state,
        loadingNewsList: false,
        errorNewsList: action.err,
      };

    case actionTypes.NEWSLISTINCREASEATTEMPTCOUNT:
      return {
        ...state,
        attemptCount: state.attemptCount + 1,
      };

    case actionTypes.NEWSLISTDECREASEATTEMPTCOUNT:
      return {
        ...state,
        attemptCount:0,
      };
    case actionTypes.NEWSLISTSHOULDFETCH:
      return {
        ...state,
        shouldFetch: true,
      };



    case actionTypes.NEWSLISTSHOULNOTDFETCH:
      return {
        ...state,
        shouldFetch: false,
      };




 //categories data


 case actionTypes.USERCATEGORIES_START:
  return {
    ...state,
    loadingCategories: true,
    errorCategories: false,
    categories: []


  };

case actionTypes.USERCATEGORIES_SUCCESS:
  return {
    ...state,
    loadingCategories: false,
    errorCategories: false,
    successUserprofiledata: false,
    categories: action.payload

  };

case actionTypes.USERCATEGORIES_FAILED:
  return {
    ...state,
    loadingCategories: false,
    errorCategories: action.payload,
    categories: []


  };
//list news by category

case  actionTypes.NEWS_LIST_BY_CATEGORY_START:
  return {
    ...state,
    currentCategory : action.payload.categoryId,
    loadingNewsListCategory: true,
    newsListCategoryPage: state.newsListCategoryPage + 1, // Increment the page number
  };


  case actionTypes.NEWS_LIST_BY_CATEGORY_PAGE_RESET : 
return {
  ...state,
  newsListCategoryPage : 1

};



case  actionTypes.NEWS_LIST_BY_CATEGORY_SUCCESS:
  // Flatten payload if necessary
  const flattenedPayloadCat = action.payload.jsonData.flat();



  return {
    ...state,
    loadingNewsListCategory: false,
    dataNewsListCategory: action.payload.categoryIdFetched == action.payload.categoryBeforFetch ?  [
      ...state.dataNewsListCategory,
      ...flattenedPayloadCat
    ] : action.payload.jsonData ,
    errorNewsListCategory: false,

    newsListCategoryPage:  action.payload.categoryIdFetched == action.payload.categoryBeforFetch ? state.newsListCategoryPage  : 1, 
  };

case  actionTypes.NEWS_LIST_BY_CATEGORY_FAILED:
  return {
    ...state,
    loadingNewsListCategory: false,
    errorNewsListCategory: action.err,
  };

case  actionTypes.NEWS_LIST_BY_CATEGORY_INCREASE_ATTEMPT_COUNT:
  return {
    ...state,
    attemptCountNewsCat: state.attemptCountNewsCat + 1,
  };

case  actionTypes.NEWS_LIST_BY_CATEGORY_DECREASE_ATTEMPT_COUNT:
  return {
    ...state,
    attemptCountNewsCat: 0, 
  };

case  actionTypes.NEWS_LIST_BY_CATEGORY_SHOULD_FETCH:
  return {
    ...state,
    shouldFetchNewsCat: true,
  };

case  actionTypes.NEWS_LIST_BY_CATEGORY_SHOULD_NOT_FETCH:
  return {
    ...state,
    shouldFetchNewsCat: false,
  };



  case  actionTypes.PUBLISHNEWS_START:
  return {
    ...state,

    loadingPublishNews: true,
    successPublishNews: false,
    errorPublishNews: false,
  };



  case  actionTypes.PUBLISHNEWS_SUCCESS:
  return {
    ...state,
    loadingPublishNews: false,
    successPublishNews: true,
    errorPublishNews: false,
  };



  
  case  actionTypes.PUBLISHNEWS_FAILED:
  return {
    ...state,
     loadingPublishNews: false,
     successPublishNews: false,
     errorPublishNews: true,
  };





    default:
      return state;
  }
};

export default ReducerGeneral;
