import { inject, injectable } from "inversify";
import type { INewsRepository } from "../repositories/iNewsRepository";
import { ResultFuture } from "../../../../core/types/types";
import { NewsModel } from "../../../../product/models/news/newsModel";
import { NewsContainerTypes } from "../../newsContainerTypes";

@injectable()
export class GetFeedNews {
  @inject(NewsContainerTypes.INewsRepository) private newsRepo!: INewsRepository;

  async execute(params: GetFeedNewsParams): ResultFuture<NewsModel[]> {
    return this.newsRepo.getFeedNews(params);
  }
}

export class GetFeedNewsParams {
  public page: number;
  public categoryId: number | undefined;

  constructor({ page, categoryId }: { page: number; categoryId: number | undefined }) {
    this.page = page;
    this.categoryId = categoryId;
  }
}
