import React, {FormEvent, useState} from "react";

import { Link } from "react-router-dom";
import Header from "./Header"
import Footer from "./Footer"

import './jurnalleWebSite.css';

const Sss: React.FC = () => {

  
  return (
    <section className="web-section">
        <Header />
      <main className="main">

    <section className="container terms">
    <article className="page">
        <header className="page__header">
            <h1 className="page__title">SSS</h1>
        </header>
        <div className="page__content">
            Sıkça Sorulan Sorular
                <br/><h1> S. Jurnalle nedir? </h1><br/>
            <p>C. Jurnalle, kendisini bir sosyal haber ağı olarak tanımlayan bir mobil uygulamadır. Her kullanıcının haberlerini özgürce paylaşmasına olanak tanır ve kullanıcıların haberlerini diğer kullanıcılar tarafından doğrulanmasını veya yalanlamasını sağlayarak doğru haberlere ulaşmayı hedefler.</p>
            <br/>
            <h1>S. Jurnalle'i nereden indirebilirim?</h1>
            <br/>
            <p>C. Jurnalle uygulamasını indirmek için iOS ve Android cihazlar için ilgili uygulama mağazalarını kullanabilirsiniz. App Store veya Google Play Store'da "Jurnalle" yazarak uygulamayı bulabilir ve indirebilirsiniz.

            </p>
            <br/>
            <h1>S. Jurnalle kullanmak ücretsiz mi?</h1><br/>
            <p>C. Evet, Jurnalle uygulamasını kullanmak tamamen ücretsizdir. Herhangi bir üyelik ücreti veya ödeme gerektirmez.
            </p>
            <br/><h1>S. Jurnalle'de nasıl haber paylaşabilirim?</h1>
            <br/><p>C. Jurnalle uygulamasında haber paylaşmak için hesabınızı oluşturduktan sonra "Haber Paylaş" veya benzeri bir seçeneği seçebilirsiniz. Ardından haber başlığınızı, içeriğinizi ve ilgili kategoriyi ekleyebilirsiniz.
            </p> <br/>
            <h1>S. Haberlerimi nasıl doğrulayabilirim? </h1><br/>
            <p>C. Jurnalle'de her kullanıcının haberleri doğrulama veya yalanlama yetkisi vardır. Bir haberin altındaki doğrulama ve yalanlama düğmelerini kullanarak haberleri değerlendirebilirsiniz. Diğer kullanıcıların da bu değerlendirmelere katkıda bulunabileceğini unutmayın.</p>
            <br/><h1>S. Hangi kategorilerde haberler bulabilirim?</h1><br/>
            <p>C. Jurnalle, çeşitli kategorilerde haberleri filtrelemek için kullanabileceğiniz bir dizi seçenek sunar. Kullanıcılar haberleri kategoriye göre düzenler ve arar.</p>
            <h1>S. Jurnalle'in web sürümü var mı?</h1>     <br/>
            <p>C. Şu an için uygulama yalnızca iOS ve Android cihazlar için aktif bir şekilde çalışmaktadır. Jurnalle'nin geliştirici ekibi web için çalışmaya devam etmekte.

            </p>
           
        </div>
        
        </article>

</section>
</main>
<Footer/>
</section>    
    );
  };
  
  export default Sss;

  