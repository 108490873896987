import React from "react";
import "./UserDetailRow.css";
import { UserModel } from "../../../../product/models/user/userModel";
import UserAvatar from "../../../../product/components/user/UserAvatar";

const UserDetailRow: React.FC<{ user: UserModel | undefined }> = ({ user }) => {
  if (!user) {
    return null;
  }

  return (
    <div className='user-details-row'>
      <UserAvatar userId={user?.id} imageUrl={user?.imageUrl} height={35} width={35} />
      <div className='user-info'>
        <p className='user-username'>{user.fullName}</p>
        <p className='user-nickname'>@{user.nickname}</p>
      </div>
      {/*<BaseElevatedButton>{user.followed ? "Unfollow" : "Follow"}</BaseElevatedButton>*/}
    </div>
  );
};

export default UserDetailRow;
