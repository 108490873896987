import React, {ButtonHTMLAttributes} from 'react';
import './BaseElevatedButton.css';
import {useNavigate} from "react-router-dom";

interface BaseElevatedButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  onClick?: () => void;
  to?: string;
  href?: string;
}

const BaseElevatedButton: React.FC<BaseElevatedButtonProps> = ({
  children,
  onClick,
  to,
  href,
  ...props
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (href) {
      // Redirect to external link (open in new tab)
      window.open(href, "_blank");
    } else if (to) {
      // Navigate to internal route
      navigate(to);
    } else if (onClick) {
      // Call the onClick handler if provided
      onClick();
    }
  };

  return (
      <button type="button" className="base-elevated-button" onClick={handleClick} {...props}>
        {children}
      </button>
  );
};

export default BaseElevatedButton;
