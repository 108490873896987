import {SignInRequest} from "../../../../domain/entity/SignInRequest";
import api from "../api";

// deprecated
export async function removeAccount(credentials: SignInRequest): Promise<void> {
  try {
    const response =
        await api.post<void>(
            '/api/v1/auth/remove_account_by_credentials',
            credentials
        );
    return;
  } catch (error) {
    console.error('An error occurred while removing account: ', error);
    throw error;
  }
}