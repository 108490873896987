import * as actionTypes from "./types";

const initialState = {
  loadingNewsDetails: true,
  NewsDetails: {},
  errorNewsDetails: false,
};

const ReducerApp = (state = initialState, action:any) => {
  switch (action.type) {
    case actionTypes.GET_NEWS_DETAIL_START:
      return {
        ...state,
        loadingNewsDetails: true,
        errorNewsDetails: false,
        
      };

    case actionTypes.GET_NEWS_DETAIL_SUCCESS:
      return {
        ...state,
        NewsDetails: action.payload,
        loadingNewsDetails: false,
        errorNewsDetails: false,
      };

    case actionTypes.GET_NEWS_DETAIL_FAILED:
      return {
        ...state,
        loadingNewsDetails: false,
        errorNewsDetails: true,
        
      };

       

    default:
      return state;
  }
};

export default ReducerApp;
