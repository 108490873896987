import { inject, injectable } from "inversify";
import { ApiFailure } from "../../../../core/error/failure";
import { ResultFuture } from "../../../../core/types/types";
import { Left, Right } from "../../../../core/types/either";
import { ApiException } from "next-netkit";
import { INewsRepository } from "../../domain/repositories/iNewsRepository";
import type { INewsRemoteDataSource } from "../dataSources/iNewsRemoteDataSource";
import { NewsModel } from "../../../../product/models/news/newsModel";
import { PaginationParams } from "../../../../product/params/paginationParams";
import { GetFeedNewsParams } from "../../domain/useCases/getFeedNews";
import { NewsContainerTypes } from "../../newsContainerTypes";

@injectable()
export class NewsRepository implements INewsRepository {
  @inject(
    NewsContainerTypes.INewsRemoteDataSource) private remoteDataSource!: INewsRemoteDataSource;


  async getDeniedNews(params: PaginationParams): ResultFuture<NewsModel[]> {
    try {
      const result = await this.remoteDataSource.getDeniedNews(params);
      return new Right(result);
    } catch (error) {
      if (error instanceof ApiException) {
        return new Left(ApiFailure.fromException(error));
      }
      return new Left(new ApiFailure({}));
    }
  }

  async getVerifiedNews(params: PaginationParams): ResultFuture<NewsModel[]> {
    try {
      const result = await this.remoteDataSource.getVerifiedNews(params);
      return new Right(result);
    } catch (error) {
      if (error instanceof ApiException) {
        return new Left(ApiFailure.fromException(error));
      }
      return new Left(new ApiFailure({}));
    }
  }

  async getFeedNews(params: GetFeedNewsParams): ResultFuture<NewsModel[]> {
    try {
      const result = await this.remoteDataSource.getFeedNews(params);
      return new Right(result);
    } catch (error) {
      if (error instanceof ApiException) {
        return new Left(ApiFailure.fromException(error));
      }
      return new Left(new ApiFailure({}));
    }
  }

  async getNewsDetails(slug: string): ResultFuture<NewsModel> {
    try {
      const result = await this.remoteDataSource.getNewsDetails(slug);
      return new Right(result);
    } catch (error) {
      if (error instanceof ApiException) {
        return new Left(ApiFailure.fromException(error));
      }
      return new Left(new ApiFailure({}));
    }
  }

  async verifyNews(id: number): ResultFuture<boolean> {
    try {
      await this.remoteDataSource.verifyNews(id);
      return new Right(true);
    } catch (error) {
      if (error instanceof ApiException) {
        return new Left(ApiFailure.fromException(error));
      }
      return new Left(new ApiFailure({}));
    }
  }

  async denyNews(id: number): ResultFuture<boolean> {
    try {
      await this.remoteDataSource.denyNews(id);
      return new Right(true);
    } catch (error) {
      if (error instanceof ApiException) {
        return new Left(ApiFailure.fromException(error));
      }
      return new Left(new ApiFailure({}));
    }
  }

  async bookmarkNews(id: number): ResultFuture<boolean> {
    try {
      await this.remoteDataSource.bookmarkNews(id);
      return new Right(true);
    } catch (error) {
      if (error instanceof ApiException) {
        return new Left(ApiFailure.fromException(error));
      }
      return new Left(new ApiFailure({}));
    }
  }

  async unBookmarkNews(id: number): ResultFuture<boolean> {
    try {
      await this.remoteDataSource.unBookmarkNews(id);
      return new Right(true);
    } catch (error) {
      if (error instanceof ApiException) {
        return new Left(ApiFailure.fromException(error));
      }
      return new Left(new ApiFailure({}));
    }
  }

  async shareNews(slug: string): ResultFuture<boolean> {
    try {
      await this.remoteDataSource.shareNews(slug);
      return new Right(true);
    } catch (error) {
      if (error instanceof ApiException) {
        return new Left(ApiFailure.fromException(error));
      }
      return new Left(new ApiFailure({}));
    }
  }

}