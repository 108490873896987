import React from "react";
import "./BaseInput.css";

interface BaseInputProps {
  label: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  type?: string;
  id?: string;
  required?: boolean;
}

const BaseInput: React.FC<BaseInputProps> = ({
  label,
  value,
  onChange,
  type = "text",
  id = "",
  required = false,
}) => (
    <div className="input-container">
      <input
          type={type}
          id={id}
          className={`input-field ${value ? "filled" : ""}`}
          value={value}
          onChange={onChange}
          required={required}
          placeholder={label}
      />
    </div>
);

export default BaseInput;
