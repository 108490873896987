import axios from 'axios';
// deprecated
const API_BASE_URL = 'https://api-prod.jurnalle.com';
// deprecated
const api = axios.create({
  baseURL: API_BASE_URL,
});
// deprecated
// Request interceptor to add authorization header if token exists
api.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem('token');
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
);

// Response interceptor to handle 401 (Unauthorized) errors
api.interceptors.response.use(undefined, (error) => {
  if (axios.isAxiosError(error) && error.response?.status === 401) {

    // Optionally, redirect the user to the login page
  }
  return Promise.reject(error);
});

export default api;
