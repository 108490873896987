import React, { FormEvent, useState,useEffect } from "react";

import SideBar from "../../components/sideBar/sideBar";
import RightBlockMyProfle from "../../components/rightBlockMyProfile/RightBlockMyProfile";
import { Link,useNavigate } from "react-router-dom";


import { useAppDispatch, useAppSelector } from "../../stores/hooks";

import FadeLoader from "react-spinners/ClipLoader";

import "./register.css";

//redux
import {
  signup,
  registerPersonalInfo,
  registerCategories
} from "../../stores/general/actions";
import { RootState } from "../../stores";
import { useSelector } from "react-redux";
const Register: React.FC = () => {
  const logoGreen: string = require("../../product/assets/jurnalle-green.svg").default;
  const selectedCat: string =
    require("../../product/assets/icons/selected.svg").default;

  const [showRegisterEmail, setShowRegisterEmail] = useState(true);
  const [showregisterNameSurname, setRegisterNameSurname] = useState(false);
  const [showregisterPassword, setRegisterPassword] = useState(false);
  const [showregisterPersonInfo, setRegisterPersonInfo] = useState(false);
  const [showselectCategories, setSelectCategories] = useState(false);

  const loadingSignup = useSelector((state: RootState) => state.ReducerGeneral.loadingSignup);
  const SignupData = useSelector((state: RootState) => state.ReducerGeneral.Signup);
  const successSignUp = useSelector((state: RootState) => state.ReducerGeneral.successSignUp);
  const successSignupStep1 = useSelector((state: RootState) => state.ReducerGeneral.Signup);

  const loadingRegisterPersonalInfo = useSelector((state: RootState) => state.ReducerGeneral.loadingRegisterPersonalInfo);
  const successRegisterPersonalInfo = useSelector((state: RootState) => state.ReducerGeneral.successRegisterPersonalInfo);
  const loadingRegisterCategories = useSelector((state: RootState) => state.ReducerGeneral.loadingRegisterCategories);


  const navigate = useNavigate();


  useEffect(() => {
    const storedToken = localStorage.getItem("authToken");
    const storedExpiration = localStorage.getItem("authTokenExpiration");
    const now = new Date();

    if (storedToken && storedExpiration) {
      const expirationDate = new Date(storedExpiration);

      if (now > expirationDate) {
        // Token expired
        localStorage.removeItem("authToken");
        localStorage.removeItem("authTokenExpiration");
        navigate('/login'); // Redirect to login or other appropriate page
      } else {
        // Token valid
        navigate('/feeds'); // Redirect to feeds or other protected area
      }
    } else {
      // No token or expiration found
    //  navigate('/login'); // Redirect to login or other appropriate page
    }
  }, [loadingRegisterCategories]);





  const dispatch = useAppDispatch();


  const showRegisterEmailScreen = () => {
    setShowRegisterEmail(true);
    setRegisterNameSurname(false);
    setRegisterPassword(false);
    setRegisterPersonInfo(false);
    setSelectCategories(false);
  };

  const showregisterNameSurnameScreen = () => {
    setShowRegisterEmail(false);
    setRegisterNameSurname(true);
    setRegisterPassword(false);
    setRegisterPersonInfo(false);
    setSelectCategories(false);
  };

  const showregisterPasswordScreen = () => {
    setShowRegisterEmail(false);
    setRegisterNameSurname(false);
    setRegisterPassword(true);
    setRegisterPersonInfo(false);
    setSelectCategories(false);
  };

  const showregisterPersonInfoScreen = () => {
    setShowRegisterEmail(false);
    setRegisterNameSurname(false);
    setRegisterPassword(false);
    setRegisterPersonInfo(true);
    setSelectCategories(false);
  };

  const showselectCategoriesScreen = () => {
    setShowRegisterEmail(false);
    setRegisterNameSurname(false);
    setRegisterPassword(false);
    setRegisterPersonInfo(false);
    setSelectCategories(true);
  };

  const [nameSurname, setNameSurname] = useState("");
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");




  const [birthDay, setBirthDay] = useState("");
  const [birthMonth, setBirthMonth] = useState("");
  const [birthYear, setBirthYear] = useState("");

  const [gender, setGender] = useState("");
  const [location, setLocation] = useState("");



  const [selectedYear, setSelectedYear] = useState<number>(1999);
  const [selectedMonth, setSelectedMonth] = useState<string>('01');
  const [selectedDay, setSelectedDay] = useState<string>('01');

  // Handle the change event when the user selects a different year
  const handleYearChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedYear(Number(event.target.value));
  };



  const handleMonthChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedMonth(event.target.value);
  };




  // Handle the change event when the user selects a different day
  const handleDayChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedDay(event.target.value);
  };



  


  const RegisterEmail = (props: any) => {
    const { children, value, index, ...other } = props;

    return (
      <div className="regis-parts">
        <img src={logoGreen} alt="" className="logo-register" />
        <div className="regis-box">
          <span className="box-title">Kaydol</span>

          <div className="input-box">
            <input
              type="text"
              placeholder="E-posta"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div className="input-box">
            <input
              type="text"
              placeholder="Ad Soyad"
              value={nameSurname}
              onChange={(e) => setNameSurname(e.target.value)}
            />
          </div>

          <div className="input-box">
            <input
              type="text"
              placeholder="Kullanici adi"
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
            />
          </div>

          <div className="input-box">
            <input
              type="text"
              placeholder="Sifre"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>

          <span
            className="regis-btns"
          
          >
            Devam et
          </span>

          <div className="have-account">
            <span> Zaten hesabın var mı? </span>{" "}
            <Link to="/" className="color-green">
              Giriş yap
            </Link>
          </div>
        </div>
      </div>
    );
  };

  function RegisterNameSurname(props: any) {
    const { children, value, index, ...other } = props;

    return (
      <div className="regis-parts">
        <img src={logoGreen} alt="" className="logo-register" />
        <div className="regis-box">
          <h3>Hoşgeldin</h3>
          <span className="box-title">Yeni bir hesap oluştur</span>

          <div className="input-box">
            <span className="input-title">Ad soyad</span>
            <input type="text" placeholder="E-posta" />
          </div>

          <div className="input-box">
            <span className="input-title">Kullanıcı adı</span>
            <input type="text" placeholder="E-posta" />
          </div>

          <span className="regis-btns" onClick={showregisterPasswordScreen}>
            Devam et
          </span>

          <div className="have-account">
            <span> Zaten hesabın var mı? </span>{" "}
            <Link to="/" className="color-green">
              Giriş yap
            </Link>
          </div>
        </div>
      </div>
    );
  }

  function RegisterPassword(props: any) {
    const { children, value, index, ...other } = props;

    return (
      <div className="regis-parts">
        <img src={logoGreen} alt="" className="logo-register" />
        <div className="regis-box">
          <h3>Hoşgeldin</h3>
          <span className="box-title weight-light">
            Şifren en az 6 karakter olmalı ve büyük küçük harfler, rakamlar ve
            özel karakterlerden oluşmalıdır.
          </span>

          <div className="input-box">
            <span className="input-title">Şifre</span>
            <input type="text" placeholder="E-posta" />
          </div>

          <div className="input-box">
            <span className="input-title">Şifreyi doğrula</span>
            <input type="text" placeholder="E-posta" />
          </div>

          <span className="regis-btns" onClick={showregisterPersonInfoScreen}>
            Devam et
          </span>

          <div className="have-account">
            <span> Zaten hesabın var mı? </span>{" "}
            <Link to="/" className="color-green">
              Giriş yap
            </Link>
          </div>
        </div>
      </div>
    );
  }

  function RegisterPersonInfo(props: any) {
    const { children, value, index, ...other } = props;

    return (
      <div className="regis-parts">
        <img src={logoGreen} alt="" className="logo-register" />
        <div className="regis-box">
          <h3>Kaydol</h3>
          <span className="box-title">Kişisel bilgiler</span>

          <div className="input-box">
            <span className="input-title">Şehir</span>

            <select className="regis-select">
              <option value="Istanbul">Istanbul</option>
              <option value="Mersin">Mersin</option>
              <option value="Sakarya">Sakarya</option>
              <option value="İzmir" selected>
                İzmir
              </option>
            </select>
          </div>

          <div className="input-box">
            <span className="input-title">Cinsiyet</span>

            <select className="regis-select">
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </select>
          </div>

          <div className="input-box">
            <span className="input-title">Doğum tarihi</span>

            <div className="flex-selectors">
              <select className="regis-select">
                <option value="Male">00</option>
                <option value="Male">03</option>
              </select>

              <select className="regis-select">
                <option value="Male">04</option>
                <option value="Male">04</option>
              </select>

              <select className="regis-select">
                <option value="Male">2001</option>
                <option value="Male">1999</option>
              </select>
            </div>
          </div>

          <span className="regis-btns" onClick={showselectCategoriesScreen}>
            Devam et
          </span>

          <div className="have-account">
            <span> Zaten hesabın var mı? </span>{" "}
            <Link to="/" className="color-green">
              Giriş yap
            </Link>
          </div>
        </div>
      </div>
    );
  }


  

  function SelectCategories(props: any) {
    const { children, value, index, ...other } = props;

    return (
      <div className="regis-parts-cat">
        <h3>Aramıza hoşgeldin jurnalci</h3>
        <span className="box-title weight-light">
          Haberdar olmak istediğin konuları ve kategorileri aşağıdan
          seçebilirsin. Böylelikle ana akışını kişiselleştirebiliriz. En az 5
          kategori seçmelisin.
        </span>

        <div className="regis-categories">
          <div className="categories-slct">
            <span className="single-cat">
              Kültür&sanat <img src={selectedCat} alt="" />
            </span>
            <span className="single-cat selected-cat">
              Teknoloji <img src={selectedCat} alt="" />
            </span>
            <span className="single-cat selected-cat">
              Eğitim <img src={selectedCat} alt="" />
            </span>
            <span className="single-cat">
              Finans <img src={selectedCat} alt="" />
            </span>
            <span className="single-cat">
              Spor <img src={selectedCat} alt="" />
            </span>
            <span className="single-cat selected-cat">
              Ekonomi <img src={selectedCat} alt="" />
            </span>
            <span className="single-cat">
              Müzik bilim <img src={selectedCat} alt="" />{" "}
            </span>
            <span className="single-cat selected-cat">
              Sağlık <img src={selectedCat} alt="" />{" "}
            </span>
            <span className="single-cat selected-cat">
              Gündem <img src={selectedCat} alt="" />{" "}
            </span>
            <span className="single-cat selected-cat">
              Seyehat <img src={selectedCat} alt="" />{" "}
            </span>
            <span className="single-cat">
              Eğlence <img src={selectedCat} alt="" />{" "}
            </span>
            <span className="single-cat selected-cat">
              Sinema <img src={selectedCat} alt="" />
            </span>

            <span className="single-cat">
              Yapay zeka <img src={selectedCat} alt="" />{" "}
            </span>
            <span className="single-cat">
              Kariyer <img src={selectedCat} alt="" />{" "}
            </span>
            <span className="single-cat">
              Mizah <img src={selectedCat} alt="" />{" "}
            </span>
            <span className="single-cat">
              Moda <img src={selectedCat} alt="" />{" "}
            </span>
            <span className="single-cat selected-cat">
              Viral <img src={selectedCat} alt="" />
            </span>
            <span className="single-cat selected-cat">
              Siyaset <img src={selectedCat} alt="" />
            </span>
            <span className="single-cat">
              Tasarım <img src={selectedCat} alt="" />{" "}
            </span>
            <span className="single-cat selected-cat">
              Category <img src={selectedCat} alt="" />{" "}
            </span>
          </div>
        </div>
      </div>
    );
  }

  const handleSignup = () => {
    dispatch(signup({ email, nameSurname, userName, password }))
  };

  
  


  const handleUserDetails = () => {

    const date = new Date(selectedYear + "-" + selectedMonth + "-" + selectedDay);
    const timestamp = date.getTime();
    successSignupStep1.token && dispatch(registerPersonalInfo({ "birthdate": timestamp, "gender": "MALE", "location": "Mersin" }, successSignupStep1.token))




  }

  const [selectedCategories, setSelectedCategories] = useState<number[]>([]);



  // Array of categories
  const categories: string[] = [
    "Teknoloji",
    "Kültür&sanat",
    "Eğitim",
    "Finans",
    "Spor",
    "Ekonomi",
    "Müzik bilim",
    "Sağlık",
    "Gündem",
    "Seyehat",
    "Eğlence",
    "Sinema",
    "Yapay zeka",
    "Kariyer",
    "Mizah",
    "Moda",
    "Viral",
    "Siyaset",
    "Tasarım",
    "Category"
  ];

  const toggleCategory = (index: number) => {
    setSelectedCategories(prevSelected =>
      prevSelected.includes(index)
        ? prevSelected.filter(catIndex => catIndex !== index)
        : [...prevSelected, index]
    );
  };

  
  const handleFinishRegistration = () => {
    if (successSignupStep1.token) {
   
      dispatch(registerCategories( { "ids": selectedCategories }, successSignupStep1.token)); // Pass the data object and token to the function
    }
  };


  const locationList = [
    { id: 1, name: 'Adana' },
    { id: 2, name: 'Adıyaman' },
    { id: 3, name: 'Afyonkarahisar' },
    { id: 4, name: 'Ağrı' },
    { id: 5, name: 'Amasya' },
    { id: 6, name: 'Ankara' },
    { id: 7, name: 'Antalya' },
    { id: 8, name: 'Artvin' },
    { id: 9, name: 'Aydın' },
    { id: 10, name: 'Balıkesir' },
    { id: 11, name: 'Bilecik' },
    { id: 12, name: 'Bingöl' },
    { id: 13, name: 'Bitlis' },
    { id: 14, name: 'Bolu' },
    { id: 15, name: 'Burdur' },
    { id: 16, name: 'Bursa' },
    { id: 17, name: 'Çanakkale' },
    { id: 18, name: 'Çankırı' },
    { id: 19, name: 'Çorum' },
    { id: 20, name: 'Denizli' },
    { id: 21, name: 'Diyarbakır' },
    { id: 22, name: 'Edirne' },
    { id: 23, name: 'Elazığ' },
    { id: 24, name: 'Erzincan' },
    { id: 25, name: 'Erzurum' },
    { id: 26, name: 'Eskişehir' },
    { id: 27, name: 'Gaziantep' },
    { id: 28, name: 'Giresun' },
    { id: 29, name: 'Gümüşhane' },
    { id: 30, name: 'Hakkari' },
    { id: 31, name: 'Hatay' },
    { id: 32, name: 'Isparta' },
    { id: 33, name: 'Mersin' },
    { id: 34, name: 'İstanbul' },
    { id: 35, name: 'İzmir' },
    { id: 36, name: 'Kars' },
    { id: 37, name: 'Kastamonu' },
    { id: 38, name: 'Kayseri' },
    { id: 39, name: 'Kırklareli' },
    { id: 40, name: 'Kırşehir' },
    { id: 41, name: 'Kocaeli' },
    { id: 42, name: 'Konya' },
    { id: 43, name: 'Kütahya' },
    { id: 44, name: 'Malatya' },
    { id: 45, name: 'Manisa' },
    { id: 46, name: 'Kahramanmaraş' },
    { id: 47, name: 'Mardin' },
    { id: 48, name: 'Muğla' },
    { id: 49, name: 'Muş' },
    { id: 50, name: 'Nevşehir' },
    { id: 51, name: 'Niğde' },
    { id: 52, name: 'Ordu' },
    { id: 53, name: 'Rize' },
    { id: 54, name: 'Sakarya' },
    { id: 55, name: 'Samsun' },
    { id: 56, name: 'Siirt' },
    { id: 57, name: 'Sinop' },
    { id: 58, name: 'Sivas' },
    { id: 59, name: 'Tekirdağ' },
    { id: 60, name: 'Tokat' },
    { id: 61, name: 'Trabzon' },
    { id: 62, name: 'Tunceli' },
    { id: 63, name: 'Şanlıurfa' },
    { id: 64, name: 'Uşak' },
    { id: 65, name: 'Van' },
    { id: 66, name: 'Yozgat' },
    { id: 67, name: 'Zonguldak' },
    { id: 68, name: 'Aksaray' },
    { id: 69, name: 'Bayburt' },
    { id: 70, name: 'Karaman' },
    { id: 71, name: 'Kırıkkale' },
    { id: 72, name: 'Batman' },
    { id: 73, name: 'Şırnak' },
    { id: 74, name: 'Bartın' },
    { id: 75, name: 'Ardahan' },
    { id: 76, name: 'Iğdır' },
    { id: 77, name: 'Yalova' },
    { id: 78, name: 'Karabük' },
    { id: 79, name: 'Kilis' },
    { id: 80, name: 'Osmaniye' },
    { id: 81, name: 'Düzce' },
  ];

  const [selectedLocationId, setSelectedLocationId] = useState<string>('');

  const handleLocationChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedLocationId(event.target.value);
  };


  return (
    <>
      <div className="register-content">
        {showRegisterEmail && !successSignUp ? (
          <div className="regis-parts">
            <img src={logoGreen} alt="" className="logo-register" />
            <div className="regis-box">
              <span className="box-title">Kaydol</span>

              <div className="input-box">
                <input
                  type="text"
                  placeholder="E-posta"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  
                />
              </div>

              <div className="input-box">
                <input
                required
                  type="text"
                  placeholder="Ad Soyad"
                  value={nameSurname}
                  onChange={(e) => setNameSurname(e.target.value)}
                />
              </div>

              <div className="input-box">
                <input
                  type="text"
                  placeholder="Kullanici adi"
                  value={userName}
                  onChange={(e) => setUserName(e.target.value)}
                />
              </div>

              <div className="input-box">
                <input
                  type="password"
                  placeholder="Sifre"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              
              <span
                className="regis-btns"
                onClick={
                  handleSignup
                }
              >
                {loadingSignup ?    <FadeLoader color="white"  />  : " Devam et"}  
              </span>

              <div className="have-account">
                <span> Zaten hesabın var mı? </span>{" "}
                <Link to="/" className="color-green">
                  Giriş yap
                </Link>
              </div>
            </div>
          </div>
        ) : successSignUp && !successRegisterPersonalInfo ? (
          <div className="regis-parts">
            <img src={logoGreen} alt="" className="logo-register" />
            <div className="regis-box">
              <h3>Kaydol</h3>
              <br></br>

              <div className="input-box">
                <span className="input-title">Şehir</span>


                <select className="regis-select" value={selectedLocationId} onChange={handleLocationChange}>
      {locationList.map((location) => (
        <option key={location.id} value={location.name}>
          {location.name}
        </option>
      ))}
    </select>


              </div>

              <div className="input-box">
                <span className="input-title">Cinsiyet</span>

                <select className="regis-select">
                  <option value="Male">MALE</option>
                  <option value="FEMALE">FEMALE</option>
                  <option value="NOT_SPECIFIED">Belli Değil</option>


                </select>
              </div>

              <div className="input-box">
                <span className="input-title">Doğum tarihi</span>

                <div className="flex-selectors">


                  <select className="regis-select" value={selectedDay} onChange={handleDayChange}>

                    {[...Array(31)].map((_, i) => (
                      <option key={i + 1} value={String(i + 1).padStart(2, '0')}>
                        {String(i + 1).padStart(2, '0')}
                      </option>
                    ))}
                  </select>

                  <select className="regis-select" value={selectedMonth} onChange={handleMonthChange}>

                    {[...Array(12)].map((_, i) => (
                      <option key={i + 1} value={String(i + 1).padStart(2, '0')}>
                        {String(i + 1).padStart(2, '0')}
                      </option>
                    ))}
                  </select>


                  <select className="regis-select" value={selectedYear} onChange={handleYearChange}
                  >
                    {[...Array(2015 - 1940 + 1)].map((_, i) => {
                      const year = 1940 + i;
                      return (
                        <option key={i} value={year} selected={year === 1999}>
                          {year}
                        </option>
                      );
                    })}
                  </select>


                </div>
              </div>

              <span className="regis-btns" onClick={handleUserDetails
                //showselectCategoriesScreen
              }>
               
                {loadingRegisterPersonalInfo ?    <FadeLoader color="white"  />  : "  Devam et "}
              </span>

              <div className="have-account">
                <span



                > Zaten hesabın var mı? </span>
                <Link to="/" className="color-green">
                  Giriş yap
                </Link>
              </div>
            </div>
          </div>

        ) : successRegisterPersonalInfo && successSignUp  ? (
           <div className="regis-parts-cat">
        <h3>Aramıza hoşgeldin jurnalci</h3>
        <span className="box-title weight-light">
          Haberdar olmak istediğin konuları ve kategorileri aşağıdan
          seçebilirsin. Böylelikle ana akışını kişiselleştirebiliriz. En az 5
          kategori seçmelisin.
        </span>

        <div className="regis-categories">
        <div className="categories-slct">
      {categories.map((category, index) => (
        <span
          key={index}
          className={
            selectedCategories.includes(index) ? 'single-cat selected-cat' : 'single-cat'
          }
          onClick={() => toggleCategory(index)}
        >
          {category} <img src={selectedCat} alt="" />
        </span>
      ))}
    </div>
        </div>

        <span className="regis-btns" onClick={
                handleFinishRegistration
              }>
              {loadingRegisterCategories ?    <FadeLoader color="white"  />  : " Tamamla"}
              </span>

           </div>
        )
        : (
         <div>NULL HERE </div>
        )
        }
      </div>
    </>
  );
};

export default Register;
