import { inject, injectable } from "inversify";
import type { INetworkManager } from "next-netkit";
import { RequestMethod } from "next-netkit";
import { APIConst } from "../../../../product/constants/apiConst";
import { ICategoryRemoteDataSource } from "./ICategoryRemoteDataSource";
import { CategoryModel } from "../../../../product/models/category/categoryModel";
import { UserCategoryEntity } from "../../../../product/models/category/UserCategoryEntity";

@injectable()
export class CategoryRemoteDataSource implements ICategoryRemoteDataSource {
  @inject("INetworkManager")
  private networkManager!: INetworkManager;

  getAlLCategories(): Promise<CategoryModel[]> {
    return this.networkManager.requestList<CategoryModel>({
      method: RequestMethod.GET,
      url: APIConst.CATEGORY,
    });
  }

  getSelectedCategories(): Promise<UserCategoryEntity[]> {
    return this.networkManager.requestList<UserCategoryEntity>({
      method: RequestMethod.GET,
      url: APIConst.USER_CATEGORIES,
    });
  }
}
