export type Either<L, R> = Left<L> | Right<R>;

export class Left<L> {
  constructor(public value: L) {}

  isLeft(): this is Left<L> {
    return true;
  }

  isRight(): this is Right<any> {
    return false;
  }

  fold<B, C>(ifLeft: (l: L) => B, ifRight: (r: any) => C): B | C {
    return ifLeft(this.value);
  }
}

export class Right<R> {
  constructor(public value: R) {}

  isLeft(): this is Left<any> {
    return false;
  }

  isRight(): this is Right<R> {
    return true;
  }

  fold<B, C>(ifLeft: (l: any) => B, ifRight: (r: R) => C): B | C {
    return ifRight(this.value);
  }
}
