import React, {FormEvent, useState} from "react";
import FooterRegister from "../../components/footerRegister/FooterRegister";
import SettingsContent from "../../components/settingsContent/settingsContent";
import HeaderRegister from "../../components/headerRegister/HeaderRegister";

import SideBar from "../../components/sideBar/sideBar";
import RightBlockMyProfle from  "../../components/rightBlockMyProfile/RightBlockMyProfile";
import { Link } from "react-router-dom";

import './landingRegister.css';

const LandingRegister: React.FC = () => {
  const WelcomeLogo : string = require("../../product/assets/logo/welcome-logo.svg").default;

  
  return (
      <>
        <HeaderRegister />

          <section className="register-landing flexed-boxes">

             <div className="landing-logo">
             <img src={WelcomeLogo} alt="logo"  />

             </div>
       
              <div className="welcome-text-box">
                 <h1>
                 Her Haberin Bir <span>Yeri </span>  Var.
                 </h1>

                 <p>
                 Konumundaki haberlere birebir kaynağından ulaş, yaşadığın bölgedeki 
                 gelişmelerden anlık olarak haberdar ol ve yerel topluluklarla bağlantıda kal.
                  Haberler düşündüğünden daha yakın, hemen paylaşıma katıl.
                 </p>

                 <Link to="/register" className="start-register-btn"> Başla</Link>
              </div>


              
          </section>
       
       {/* <FooterRegister/> */}
      </>
  );
};

export default LandingRegister;
