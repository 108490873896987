import { Link } from "react-router-dom";
import React, { useRef, useState } from "react";
import {
  ReactComponent as UserProfile,
} from "../../../product/assets/iconsV2/colored/user_profile.svg";

import "./UserAvatar.css";

const UserAvatar = ({ imageUrl, userId, width = 16, height = 16 }: {
  imageUrl: string | undefined,
  userId: number | undefined,
  width?: number,
  height?: number
}) => {

  const [imgError, setImgError] = useState(false);
  const imgRef = useRef<HTMLImageElement | null>(null);
  const stopPropagation = (event: React.MouseEvent) => {
    event.stopPropagation();
  };
  return (
    <Link to={`/other-profile/${userId}`} onClick={stopPropagation}>
      {!imageUrl || imgError ? (
        <UserProfile className="news-user-img" style={{ width, height }} />
      ) : (
        <img
          ref={imgRef}
          src={imageUrl}
          alt="avatar"
          className="news-user-img"
          style={{ width, height }}
          onError={() => setImgError(true)}
        />
      )}
    </Link>
  );
};

export default UserAvatar;