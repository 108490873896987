import { ContainerModule, interfaces } from "inversify";
import { INotificationRemoteDataSource } from "./data/dataSources/INotificationRemoteDataSource";
import { NotificationRemoteDataSource } from "./data/dataSources/NotificationRemoteDataSource";
import { NotificationContainerTypes } from "./notificationContainerTypes";
import { INotificationRepository } from "./domain/repositories/INotificationRepository";
import { NotificationRepository } from "./data/repositories/NotificationRepository";

const notificationContainer = new ContainerModule((bind: interfaces.Bind) => {
  bind<INotificationRemoteDataSource>(NotificationContainerTypes.INotificationRemoteDataSource).to(
    NotificationRemoteDataSource
  );
  bind<INotificationRepository>(NotificationContainerTypes.INotificationRepository).to(NotificationRepository);
});

export { notificationContainer };
