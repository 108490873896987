const NotificationContainerTypes = {
  INotificationRemoteDataSource: Symbol.for("INotificationRemoteDataSource"),
  INotificationRepository: Symbol.for("INotificationRepository"),
  GetNotifications: Symbol.for("GetNotifications"),
  GetDisabledNotifications: Symbol.for("GetDisabledNotifications"),
  GetUnreadNotificationsCount: Symbol.for("GetUnreadNotificationsCount"),
  ManageNotification: Symbol.for("ManageNotification"),
  MarkAllNotificationsAsRead: Symbol.for("MarkAllNotificationsAsRead"),
  MarkNotificationAsRead: Symbol.for("MarkNotificationAsRead"),
  RemoveAllReadNotifications: Symbol.for("RemoveAllReadNotifications"),
  RemoveNotification: Symbol.for("RemoveNotification"),
  RemoveAllNotifications: Symbol.for("RemoveAllNotifications"),
};

export { NotificationContainerTypes };
