import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./sideBar.css";

const SideBar: React.FC = () => {
  const location = useLocation();
  const home: string = require("../../product/assets/icons/home.svg").default;
  const categories: string = require("../../product/assets/icons/categories.svg").default;
  const userIcon: string = require("../../product/assets/icons/user.svg").default;
  const settings: string = require("../../product/assets/icons/settings.svg").default;
  const bookmark: string = require("../../product/assets/icons/bookmark.svg").default;
  const alarm: string = require("../../product/assets/icons/alarm.svg").default;
  const searchIcon: string = require("../../product/assets/icons/search.svg").default;
  const side_menu_ico: string = require("../../product/assets/icons/side_menu.svg").default;
  const [isToggled, setIsToggled] = useState<boolean>(false);
  const jurnalleLogo: string = require("../../product/assets/jurnalle.svg").default;

  const handleClick = () => {
    setIsToggled(prev => !prev);
    document.body.classList.toggle("menu-style", !isToggled);
  };

  return (
    <section className="left-block">
      <div className="buttons-box width160">
        <div className="jurnalle-logo ">
          <img
            onClick={handleClick}
            style={{ cursor: "pointer" }}
            src={side_menu_ico} alt="search" />
          <Link to="/">
            <img src={jurnalleLogo} alt="logo" className="logo-head" />
          </Link>
        </div>
        <Link to="/feeds"
              className={`button-link ${location.pathname === "/feeds" ? "active" : ""}`}>
          <img src={home} alt="search" className="search-ico" />
          <span> Anasayfa</span>
        </Link>

        <Link to="/" className={`button-link ${location.pathname === "/" ? "active" : ""}`}>
          <img src={alarm} alt="search" className="search-ico" />
          <span> Bildirimler</span>
        </Link>

        <Link to="/discover"
              className={`button-link ${location.pathname === "/discover" ? "active" : ""}`}>
          <img src={searchIcon} alt="search" className="search-ico" />
          <span> Keşfet</span>
        </Link>

        <Link to="/profile"
              className={`button-link ${location.pathname === "/profile" ? "active" : ""}`}>
          <img src={userIcon} alt="search" className="search-ico" />
          <span> Profil</span>
        </Link>

        <span className="thinline"></span>

        <Link to="/bookmarks"
              className={`button-link ${location.pathname === "/bookmarks" ? "active" : ""}`}>
          <img src={bookmark} alt="search" className="search-ico" />
          <span> Kaydelilenler</span>
        </Link>

        <Link to="/categories"
              className={`button-link ${location.pathname === "/categories" ? "active" : ""}`}>
          <img src={categories} alt="search" className="search-ico" />
          <span> Kategoriler</span>
        </Link>
      </div>

      <div className="confirm-mail-box hided-tempo">
        <h3>Mail adresini doğrula</h3>
        <p>
          Haber paylaşmak ve diğer özelliklere erişebilmek için mail doğrulaması yap
        </p>

        <Link to="/" className="confirm-mail-btn">
          <span> Hemen doğrula</span>
        </Link>
      </div>
      <br />

      <Link to="/settings"
            className={`button-link hided-tempo ${location.pathname === "/settings" ? "active"
              : ""}`}>
        <img src={settings} alt="search" className="search-ico" />
        <span> Ayarlar</span>
      </Link>
    </section>
  );
};

export default SideBar;