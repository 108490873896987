import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppConst } from "../../product/constants/appConst";
import { NotificationState } from "./NotificationState";
import {
  fetchNotifications,
  getDisabledNotifications,
  manageNotification,
  markAllNotificationsAsRead,
  markNotificationAsRead,
  refreshNotifications,
  removeAllNotifications,
  removeAllReadNotifications,
  removeNotification,
} from "./notificationThunks";

const initialState: NotificationState = {
  notifications: [],
  currentPage: AppConst.defaultInitialPage,
  isCurrentLastPage: false,
  loading: false,
  error: null,
  disabledNotificationIds: [],
};

const notificationSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    resetNotifications: (state) => {
      state.notifications = [];
      state.currentPage = AppConst.defaultInitialPage;
      state.isCurrentLastPage = false;
    },
    incrementPage: (state) => {
      state.currentPage += 1;
    },
    setIsCurrentLastPage: (state, action: PayloadAction<boolean>) => {
      state.isCurrentLastPage = action.payload;
    },
    notificationRemoved: (state, action: PayloadAction<number>) => {
      state.notifications = state.notifications.filter((notification) => notification.id !== action.payload);
    },
    notificationMarkedAsRead: (state, action: PayloadAction<number>) => {
      const index = state.notifications.findIndex((notification) => notification.id === action.payload);
      if (index !== -1) {
        state.notifications[index] = { ...state.notifications[index], read: true };
      }
    },
    allNotificationsMarkedAsRead: (state) => {
      state.notifications = state.notifications.map((notification) => ({ ...notification, read: true }));
    },
    notificationManaged: (_, __: PayloadAction<{ notificationTypeId: number; shouldEnable: boolean }>) => {
      // Handle notification management logic here
    },
    disabledNotificationsLoaded: (state, action: PayloadAction<number[]>) => {
      state.disabledNotificationIds = action.payload;
    },
    allReadNotificationsRemoved: (state) => {
      state.notifications = state.notifications.filter((notification) => !notification.read);
    },
    allNotificationsRemoved: (state) => {
      state.notifications = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchNotifications.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchNotifications.fulfilled, (state, action) => {
        state.notifications.push(...action.payload);
        state.loading = false;
      })
      .addCase(fetchNotifications.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to fetch notifications";
      })
      .addCase(refreshNotifications.pending, (state) => {
        state.loading = true;
      })
      .addCase(refreshNotifications.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(refreshNotifications.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to refresh notifications";
      })
      .addCase(removeNotification.rejected, (state, action) => {
        state.error = action.payload || "Failed to remove notification";
      })
      .addCase(markNotificationAsRead.rejected, (state, action) => {
        state.error = action.payload || "Failed to mark notification as read";
      })
      .addCase(markAllNotificationsAsRead.rejected, (state, action) => {
        state.error = action.payload || "Failed to mark all notifications as read";
      })
      .addCase(manageNotification.rejected, (state, action) => {
        state.error = action.payload || "Failed to manage notification";
      })
      .addCase(getDisabledNotifications.rejected, (state, action) => {
        state.error = action.payload || "Failed to get disabled notifications";
      })
      .addCase(removeAllReadNotifications.rejected, (state, action) => {
        state.error = action.payload || "Failed to remove all read notifications";
      })
      .addCase(removeAllNotifications.rejected, (state, action) => {
        state.error = action.payload || "Failed to remove all notifications";
      });
  },
});

export const {
  resetNotifications,
  incrementPage,
  setIsCurrentLastPage,
  notificationRemoved,
  notificationMarkedAsRead,
  allNotificationsMarkedAsRead,
  notificationManaged,
  disabledNotificationsLoaded,
  allReadNotificationsRemoved,
  allNotificationsRemoved,
} = notificationSlice.actions;

export default notificationSlice.reducer;
