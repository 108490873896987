import React, {ButtonHTMLAttributes} from 'react';
import {useNavigate} from "react-router-dom";
import './BaseOutlinedButton.css';

interface BaseOutlinedButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  onClick?: () => void;
  to?: string;
  href?: string;
}

const BaseOutlinedButton: React.FC<BaseOutlinedButtonProps> = ({
  children,
  onClick,
  href,
  to,
  ...props
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (href) {
      // Redirect to external link (open in new tab)
      window.open(href, "_blank");
    } else if (to) {
      // Navigate to internal route
      navigate(to);
    } else if (onClick) {
      // Call the onClick handler if provided
      onClick();
    }
  };
  return (
      <button type="button" className="base-outlined-button" onClick={handleClick} {...props}>
        {children}
      </button>
  );
};

export default BaseOutlinedButton;