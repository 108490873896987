import React, {FormEvent, useState} from "react";

import { Link } from "react-router-dom";


import './jurnalleWebSite.css';

const Header: React.FC = () => {
  const logoGreen: string = require("../../product/assets/jurnalle-green.svg").default;

  
  return (
      <>
        <section >
      <header className="header" id="header">
            <nav className="nav container">
                <Link to="/home.html" className="nav__logo">
                 <img src={logoGreen} alt="" className="nav__logo-img" />  
                </Link>
                <div className="nav__menu" id="nav-menu">
                    <ul className="nav__list">
                     
                        <li className="nav__item">
                            <Link to="/home" className="nav__link">Hakkında</Link>
                        </li>

                        <li className="nav__item" >
                        <Link to="/community_rules" className="footer__link">Topluluk Kuralları</Link>
                        </li>
                        <li className="nav__item">
                        <Link to="/terms_of_service" className="footer__link">Hizmet Şartları</Link>
                        </li>

                    

                    </ul>

                    <div className="nav__close" id="nav-close">
                        <i className='bx bx-x'></i>
                    </div>

                    <img src="assets/img/nav-img.png" alt="" className="nav__img" />
                </div>

                <div className="nav__toggle" id="nav-toggle">
                    <i className='bx bx-grid-alt'></i>
                </div>

            </nav>
        </header>
         </section>

      </>
    );
  };
  
  export default Header;

  