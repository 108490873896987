import { inject, injectable } from "inversify";
import { ResultFuture } from "../../../../core/types/types";
import { NewsModel } from "../../../../product/models/news/newsModel";
import type { INewsRepository } from "../repositories/iNewsRepository";
import { PaginationParams } from "../../../../product/params/paginationParams";
import { NewsContainerTypes } from "../../newsContainerTypes";

@injectable()
export class GetVerifiedNews {
  @inject(NewsContainerTypes.INewsRepository) private newsRepo!: INewsRepository;

  async execute(pagination: PaginationParams): ResultFuture<NewsModel[]> {
    return this.newsRepo.getVerifiedNews(pagination);
  }
}