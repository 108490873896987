import React from "react";
import "./ComingSoon.css";
import {
  ReactComponent as Categories,
} from "../../../product/assets/iconsV2/outlined/categories.svg";


const ComingSoon = () => {
  return (
    <div className="coming-soon-container">
      <h1 className="coming-soon-title">Yakında Geliyor</h1>
      <p className="coming-soon-description">Bu özelliği size sunmak için çok çalışıyoruz.</p>
      <Categories></Categories>
    </div>
  );
};

export default ComingSoon;