import {ContainerModule, interfaces} from 'inversify';
import {IAuthRepository} from './domain/repositories/auth.repository.interface';
import {AuthRepository} from './data/repositories/auth.repository';
import {SignIn} from './domain/useCases/sign-in.usecase';
import {
  IAuthRemoteDataSource
} from './data/data_sources/auth.remote.data-source.interface';
import {AuthRemoteDataSource} from './data/data_sources/auth.remote.data-source';
import {
  IAuthLocalDataSource
} from "./data/data_sources/auth.local.data-source.interface";
import {AuthLocalDataSource} from "./data/data_sources/auth.local.data-source";
import {IsAuthenticated} from "./domain/useCases/IsAuthenticated";

const authContainer = new ContainerModule((bind: interfaces.Bind) => {
  bind<IAuthRemoteDataSource>('IAuthRemoteDataSource').to(AuthRemoteDataSource);
  bind<IAuthLocalDataSource>('IAuthLocalDataSource').to(AuthLocalDataSource)
  bind<IAuthRepository>('IAuthRepository').to(AuthRepository);
  bind<SignIn>(SignIn).toSelf();
  bind<IsAuthenticated>(IsAuthenticated).toSelf();
});

export {authContainer};
