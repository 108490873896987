// src/components/BaseTextButton.tsx
import React from "react";
import { useNavigate } from "react-router-dom";
import "./BaseTextButton.css";

interface BaseTextButtonProps {
  children: React.ReactNode;
  to: string; // Can be an internal path or external URL
  isExternal?: boolean; // Optional flag to indicate external link
}

const BaseTextButton: React.FC<BaseTextButtonProps> = ({
  children,
  to,
  isExternal = false, // Default to internal link
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (isExternal) {
      // Open external link in a new tab
      window.open(to, "_blank");
    } else {
      // Navigate to internal route
      navigate(to);
    }
  };

  return (
      <button className="base-text-button" onClick={handleClick}>
        {children}
      </button>
  );
};

export default BaseTextButton;
