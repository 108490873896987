import React from "react";
import {useNavigate} from "react-router-dom";

import Footer from "../../../components/footer/Footer";

import "./AccountRemoved.css";

const AccountRemoved: React.FC = () => {
  const AppStore: string = require("../../../product/assets/AppStore.svg").default;
  const GooglePlay: string = require("../../../product/assets/GooglePlay.svg").default;

  const navigate = useNavigate();
  const handleAppStoreClick = () => {
    window.open("https://apps.apple.com/app/jurnalle/id6466410768", "_blank");
  };

  const handleGooglePlayClick = () => {
    window.open("https://play.google.com/store/apps/details?id=com.jurnalle.jurnalle", "_blank");
  };
  return (
      <>
        <div className="account-removed-content">
          <h1 className="account-removed-logo-text">
            <span className="account-removed-green-text">jurnalle</span>
            <span className="account-removed-gray-text"> | Hoşçakal.</span>
          </h1>
          <p className="account-removed-removal-message">Hesap silme işlemin başarıyla sonuçlandı.
            <br/>
            Dilediğin zaman yeniden jurnalle’ye katılabilirsin.</p>
          <div className="account-removed-store-buttons">
            <img src={AppStore} alt="App Store" onClick={handleAppStoreClick}/>
            <img src={GooglePlay} alt="Google Play" onClick={handleGooglePlayClick}/>
          </div>
        </div>
        <Footer/>

      </>
  );
};

export default AccountRemoved;

