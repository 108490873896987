import { ContainerModule, interfaces } from "inversify";
import { INetworkManager, NetworkErrorParams, NetworkManager } from "next-netkit";
import { APIConst } from "../constants/apiConst";

const networkErrorParams: NetworkErrorParams = {
  messageKey: "message",
  statusCodeKey: "status",
  couldNotParseError: "Could not parse error",
  jsonIsEmptyError: "JSON is empty",
  noInternetError: "No internet connection",
  jsonNullError: "JSON is null",
  jsonUnsupportedObjectError: "JSON is unsupported object",
  notMapTypeError: "Not map type",
};
const isTestMode = process.env.NODE_ENV !== "production";

const networkManagerInstance = new NetworkManager({
  baseUrl: APIConst.BASE_URL,
  devBaseUrl: APIConst.DEV_BASE_URL,
  testMode: false,
  baseOptions: {},
  errorParams: networkErrorParams,
  withCredentials: true,
  isClientSideWeb: typeof window !== "undefined" && typeof localStorage !== "undefined",
});

const networkContainer = new ContainerModule((bind: interfaces.Bind) => {
  bind<INetworkManager>("INetworkManager").toConstantValue(networkManagerInstance);
});

export { networkContainer };