import React, {FormEvent, useState} from "react";

import { Link } from "react-router-dom";

import Header from "./Header"
import Footer from "./Footer"
import './jurnalleWebSite.css';

const privacyPolicy: React.FC = () => {

  
  return (
    <section className="web-section">
<Header />
        <main className="main">
  
      <section className="container terms">
      <article className="page">
          <header className="page__header">
              <h1 className="page__title">Gizlilik Politikası</h1>
          </header>
          <div className="page__content">
              <p>
              </p><h3><br/>A - GİZLİLİK VE GÜVENLİK POLİTİKASI</h3>
              <p></p>
              <p>Mobil uygulamada verilen tüm servisler ve adresinde kayıtlı firmamıza aittir ve firmamız tarafından
                  işletilir. </p>
              <p>Mobil uygulama, çeşitli amaçlarla kişisel veriler toplayabilir. Aşağıda, toplanan kişisel verilerin
                  nasıl ve ne şekilde toplandığı, bu verilerin nasıl ve ne şekilde korunduğu belirtilmiştir. </p>
              <p>Üyelik nedeniyle çeşitli form ve anketlerin doldurulması suretiyle üyelerin kendileriyle ilgili bir
                  takım kişisel bilgileri (isim-soy isim, firma bilgileri, telefon, adres veya e-posta adresleri gibi)
                  Mobil uygulama tarafından işin doğası gereği toplanmaktadır. </p>
              <p>Mobil uygulama bazı dönemlerde müşterilerine ve üyelerine kampanya bilgileri, yeni ürünler hakkında
                  bilgiler, promosyon teklifleri gönderebilir. Üyelerimiz bu gibi bilgileri alıp almama konusunda her
                  türlü seçimi üye olurken yapabilir, sonrasında üye girişi yaptıktan sonra hesap bilgileri bölümünden
                  bu seçimi değiştirilebilir ya da kendisine gelen bilgilendirme iletisindeki linkle bildirim
                  yapabilir. </p>
              <p>Mobil uygulama üzerinden veya eposta ile gerçekleştirilen onay sürecinde, üyelerimiz tarafından
                  mağazamıza elektronik ortamdan iletilen kişisel bilgiler, Üyelerimiz ile yaptığımız "Kullanıcı
                  Sözleşmesi" ile belirlenen amaçlar ve kapsam dışında üçüncü kişilere açıklanmayacaktır.</p>
              <p>Sistemle ilgili sorunların tanımlanması ve verilen hizmet ile ilgili çıkabilecek sorunların veya
                  uyuşmazlıkların hızla çözülmesi için, Mobil uygulama, üyelerinin IP adresini kaydetmekte ve bunu
                  kullanmaktadır. IP adresleri, kullanıcıları genel bir şekilde tanımlamak ve kapsamlı demografik
                  bilgi toplamak amacıyla da kullanılabilir.</p>
              <p>Mobil uygulama, Üyelik Sözleşmesi ile belirlenen amaçlar ve kapsam dışında da, talep edilen bilgileri
                  kendisi veya işbirliği içinde olduğu kişiler tarafından doğrudan pazarlama yapmak amacıyla
                  kullanabilir. Kişisel bilgiler, gerektiğinde kullanıcıyla temas kurmak için de kullanılabilir. Mobil
                  uygulama tarafından talep edilen bilgiler veya kullanıcı tarafından sağlanan bilgiler; Mobil
                  uygulama ve işbirliği içinde olduğu kişiler tarafından, "Üyelik Sözleşmesi" ile belirlenen
                  amaçlar ve kapsam dışında da, üyelerimizin kimliği ifşa edilmeden çeşitli istatistiksel
                  değerlendirmeler, veri tabanı oluşturma ve pazar araştırmalarında kullanılabilir.</p>
              <p>Mobil uygulama, gizli bilgileri kesinlikle özel ve gizli tutmayı, bunu bir sır saklama yükümü olarak
                  addetmeyi ve gizliliğin sağlanması ve sürdürülmesi, gizli bilginin tamamının veya herhangi bir
                  kısmının kamu alanına girmesini veya yetkisiz kullanımını veya üçüncü bir kişiye ifşasını önlemek
                  için gerekli tüm tedbirleri almayı ve gerekli özeni göstermeyi taahhüt etmektedir.</p>
              <p>B - Kişisel bilgilerin toplanması
                  Aşağıdaki türden kişisel bilgiler toplanabilir, saklanabilir ve kullanılabilir:</p>
              <ol>
                  <li> IP adresi, coğrafi konum, tarayıcı türü ve versiyonu ile işletim sistemi dahil bilgisayarınız
                      hakkında bilgiler;</li>
                  <li> Referans kaynak, ziyaret süresi, sayfa görüntülemeleri ve sitede gezinme yolları dahil siteyi
                      ziyaretiniz ve kullanımınız ile ilgili bilgiler;</li>
                  <li> Mobil uygulamaya kayıt olmak için verdiğiniz e-posta adresiniz gibi bilgiler;</li>
                  <li> Örneğin adınız, profil resminiz, cinsiyetiniz, doğum gününüz, ilişki durumunuz, ilgi
                      alanlarınız ve hobileriniz, eğitim ve çalışma durumunuz gibi, Mobil uygulamada profil
                      oluştururken verdiğiniz bilgiler;</li>
                  <li> E-postalarımız ve/veya bültenlerimize abone olurken verdiğiniz ad ve e-posta adresi gibi
                      bilgiler;</li>
                  <li> Mobil uygulamadaki hizmetleri kullanırken girdiğiniz bilgiler;</li>
                  <li> Ne zaman, ne sıklıkta ve hangi koşullarda kullandığınız dahil, sitemizi kullanırken oluşturulan
                      bilgiler;</li>
                  <li> Adınız, adresiniz, telefon numaranız, e-posta adresiniz ve kredi kartı bilgileriniz dahil,
                      sitemiz üzerinden satın aldığınız herhangi bir şey, kullandığınız bir hizmet veya
                      gerçekleştirdiğiniz bir aktarım ile ilgili bilgiler;</li>
                  <li> Kullanıcı adınız, profil resminiz ve gönderinizin içeriği dahil, sitemizi kullanarak internette
                      paylaşmak amacıyla sitemize gönderdiğiniz bilgiler;</li>
                  <li> İletişim içerikleri ve üst veriler dahil, sitemiz veya e-posta yoluyla gönderdiğiniz her türlü
                      iletişim içerikleri;</li>
                  <li> Bize gönderdiğiniz diğer tüm kişisel bilgiler.
                      Bize başka bir kişinin kişisel bilgilerini iletmeden önce, o kişinin bilgilerin paylaşılacağına
                      ve bu ilkeye uygun olarak işleneceğine dair onayını almanız gerekir.</li>
              </ol>
              <p>C. Kişisel bilgilerin paylaşılması
                  Kişisel bilgilerinizi çalışanlarımıza, görevlilerimize, sigortacılarımıza, profesyonel
                  danışmanlarımıza, ajanslarımıza, tedarikçilerimize veya taşeronlarımıza, bu ilkede belirtilen
                  amaçlar gerektirdiği sürece iletebiliriz.
                  Kişisel bilgilerinizi şirketler grubumuzun tüm üyelerine (iştiraklerimiz, üst holding şirketimiz ve
                  onun iştirakleri anlamına gelir), bu ilkede belirtilen amaçlar gerektirdiği sürece iletebiliriz.
                  Kişisel bilgilerinizi şu durumlarda paylaşabiliriz:</p>
              <ol>
                  <li> Yasal olarak gerektiği durumlarda;</li>
                  <li> Devam eden veya olası yasal süreçlerle ilgili olarak;</li>
                  <li> Kendi yasal haklarımızı korumak, uygulamak ve savunmak için (dolandırıcılığı önleme ve kredi
                      riskini azaltmak amacıyla bilgileri diğerlerine iletmek dahil);</li>
                  <li> Sattığımız (veya satmayı düşündüğümüz) tüm işletme ve varlıkların alıcılarına (veya potansiyel
                      alıcılarına) ve</li>
                  <li> Kişisel bilgilerin açıklanması için mahkemeye başvurabileceğine inandığımız bir kişiye,
                      başvurması halinde mahkemenin veya yetkili kişi ve kurumların kişisel bilgilerin açıklanması
                      yönünde karar vereceğine dair makul görüşlerimiz olması halinde.
                      Bu ilkede belirtilen durumlar haricinde, kişisel bilgilerinizi üçüncü taraflarla paylaşmayız.
                  </li>
              </ol>
              <p>D. Uluslararası veri transferleri</p>
              <ol>
                  <li> Topladığımız bilgiler, bu ilkede belirtilen amaçlar doğrultusunda kullanılmak üzere faaliyet
                      gösterdiğimiz ülkeler arasında aktarılabilir, bu ülkelerde saklanabilir ve işlenebilir.</li>
                  <li> Topladığımız bilgiler, Avrupa Ekonomik Bölgesi’ndekine denk veri koruma yasaları olmayan şu
                      ülkelere aktarılabilir: Amerika Birleşik Devletleri, Rusya, Japonya, Çin ve Hindistan.</li>
                  <li> Mobil uygulamada paylaştığınız veya paylaşılması için gönderdiğiniz bilgilere internet sitemiz
                      üzerinden tüm dünyadan erişilebilir. Bu bilgilerin diğerleri tarafından istismar edilmesini
                      önleyemeyiz.
                      E. Kişisel bilgilerin saklanması</li>
                  <li> Bölüm E, kişisel bilgilerin saklanması ve silinmesi ile ilgili yasal yükümlülüklerimizi yerine
                      getirdiğimizden emin olmak için tasarlanan veri saklama ilkeleri ve prosedürlerini belirtir.
                  </li>
                  <li> İşlediğimiz kişisel bilgiler, belirtilen amaç veya amaçların gerektirdiğinden daha uzun süre
                      saklanamaz.</li>
                  <li> Bölüm E’deki diğer hükümlere bağlı olmaksızın kişisel veri içeren dokümanları (elektronik
                      dokümanlar dahil) saklarız:</li>
                  <li> yasal olarak gerektiği durumlarda;</li>
                  <li> dokümanların süren veya olası yasal süreçlerle ilgili olduğunu düşündüğümüz hallerde ve</li>
                  <li> kendi yasal haklarımızı korumak, uygulamak ve savunmak için (dolandırıcılığı önleme ve kredi
                      riskini azaltmak amacıyla bilgileri diğerlerine iletmek dahil).
                      F. Kişisel bilgilerinizin kullanılması
                      Mobil uygulama üzerinden bize gönderilen kişisel bilgiler bu ilkede veya sitenin ilgili
                      sayfalarında belirtilen amaçlar için kullanılacaktır. Kişisel bilgilerinizi şu amaçlar için
                      kullanabiliriz:</li>
                  <li> Mobil uygulama ve işletmemizi yönetmek;</li>
                  <li> Mobil uygulama sizin için kişiselleştirmek;</li>
                  <li> Mobil uygulamadaki hizmetleri kullanmanızı sağlamak;</li>
                  <li> Mobil uygulamadan satın aldığınız ürünleri size göndermek;</li>
                  <li> Mobil uygulamadan satın alınan hizmetleri temin etmek;</li>
                  <li> Size bildirim, fatura ve ödeme hatırlatıcıları göndermek ve sizden ödeme almak;</li>
                  <li> Sizinle pazarlama harici ticari iletişim kurmak;</li>
                  <li> Size özellikle talep ettiğiniz e-posta bildirimlerini göndermek;</li>
                  <li> Talep ettiyseniz size e-posta bültenimizi göndermek (bülteni istemiyorsanız bize her an
                      bildirebilirsiniz);</li>
                  <li> Size işletmemiz veya özenle seçilmiş üçüncü taraf işletmeler tarafından, işinize yarayacağını
                      düşündüğümüz pazarlama içeriklerini özellikle kabul ettiğiniz takdirde posta, e-posta veya
                      benzer teknolojilerle göndermek (pazarlama içeriklerini istemiyorsanız bize her an
                      bildirebilirsiniz);</li>
                  <li> Üçüncü taraflara kullanıcılarımız hakkında istatistiksel bilgiler sunmak (ancak bu üçüncü
                      taraflar bu bilgileri kullanarak hiçbir kullanıcının kimliğini belirleyemezler);</li>
                  <li> Mobil uygulamayla ilgili sizin tarafınızdan veya sizinle ilgili yapılan şikayetleri ve
                      talepleri çözmek;</li>
                  <li> Mobil uygulamayı güvenli tutmak ve dolandırıcılığı önlemek;</li>
                  <li> İnternet sitemizin, ilgili şartlar ve koşullara uygun olarak kullanıldığını doğrulamak
                      (sitemizdeki özel mesaj hizmeti ile gönderilen mesajları izlemek dahil) ve</li>
                  <li> Diğer amaçlar için.
                      Mobil uygulamada yayınlanması için kişisel bilgiler gönderirseniz, bu bilgileri yayınlarız veya
                      bize sunduğunuz lisans kapsamında kullanabiliriz.</li>
              </ol>
              <p>Gizlilik ayarlarınız Mobil uygulamada bilgilerinizin yayınlanmasını sınırlandırabilir ve mobil
                  uygulamadaki gizlilik kontrolleri üzerinden değiştirilebilir.</p>
              <p>Açıkça izin vermediğiniz sürece kişisel bilgilerinizi hiçbir üçüncü tarafa veya diğer üçüncü
                  tarafların doğrudan pazarlama bölümlerine iletmeyeceğiz.</p>
              <p>G- ÜÇÜNCÜ TARAF WEB SİTELERİ VE UYGULAMALAR
                  Mobil uygulama, web sitesi dâhilinde başka sitelere link verebilir. Firmamız, bu linkler vasıtasıyla
                  erişilen sitelerin gizlilik uygulamaları ve içeriklerine yönelik herhangi bir sorumluluk
                  taşımamaktadır. Firmamıza ait sitede yayınlanan reklamlar, reklamcılık yapan iş ortaklarımız
                  aracılığı ile kullanıcılarımıza dağıtılır. İş bu sözleşmedeki Gizlilik Politikası Prensipleri,
                  sadece Mobil uygulama kullanımına ilişkindir, üçüncü taraf web sitelerini kapsamaz. </p>
              <p>H- İSTİSNAİ HALLER
                  Aşağıda belirtilen sınırlı hallerde Firmamız, işbu "Gizlilik Politikası" hükümleri dışında
                  kullanıcılara ait bilgileri üçüncü kişilere açıklayabilir. Bu durumlar sınırlı sayıda olmak üzere;
              </p>
              <p>1.Kanun, Kanun Hükmünde Kararname, Yönetmelik v.b. yetkili hukuki otorite tarafından çıkarılan ve
                  yürürlülükte olan hukuk kurallarının getirdiği zorunluluklara uymak;
                  2. Mobil uygulamanın kullanıcılarla akdettiği "Üyelik Sözleşmesi"'nin ve diğer
                  sözleşmelerin gereklerini yerine getirmek ve bunları uygulamaya koymak amacıyla;
                  3.Yetkili idari ve adli otorite tarafından usulüne göre yürütülen bir araştırma veya soruşturmanın
                  yürütümü amacıyla kullanıcılarla ilgili bilgi talep edilmesi;
                  4.Kullanıcıların hakları veya güvenliklerini korumak için bilgi vermenin gerekli olduğu hallerdir.
              </p>
              <p>J- E-POSTA GÜVENLİĞİ
                  Mobil uygulamanın Müşteri Hizmetleri’ne, herhangi bir siparişinizle ilgili olarak göndereceğiniz
                  e-postalarda, asla kredi kartı numaranızı veya şifrelerinizi yazmayınız. E-postalarda yer alan
                  bilgiler üçüncü şahıslar tarafından görülebilir. Firmamız e-postalarınızdan aktarılan bilgilerin
                  güvenliğini hiçbir koşulda garanti edemez.</p>
              <p>K- TARAYICI ÇEREZLERİ
                  Mobil uygulama, kullanıcılar ve kullanıcıların web sitesini kullanımı hakkındaki bilgileri teknik
                  bir iletişim dosyası (Çerez-Cookie) kullanarak elde edebilir. Bahsi geçen teknik iletişim dosyaları,
                  ana bellekte saklanmak üzere bir internet sitesinin kullanıcının tarayıcısına (browser) gönderdiği
                  küçük metin dosyalarıdır. Teknik iletişim dosyası site hakkında durum ve tercihleri saklayarak
                  İnternet'in kullanımını kolaylaştırır.</p>
              <p>Teknik iletişim dosyası, siteyi kaç kişinin ziyaret ettiğini, bir kişinin siteyi hangi amaçla, kaç
                  kez ziyaret ettiğini ve ne kadar sitede kaldıkları hakkında istatistiksel bilgileri elde etmeye ve
                  kullanıcılar için özel tasarlanmış kullanıcı sayfalarından dinamik olarak reklam ve içerik
                  üretilmesine yardımcı olur. Teknik iletişim dosyası, ana bellekte veya e-postanızdan veri veya
                  başkaca herhangi bir kişisel bilgi almak için tasarlanmamıştır. Tarayıcıların pek çoğu başta teknik
                  iletişim dosyasını kabul eder biçimde tasarlanmıştır ancak kullanıcılar dilerse teknik iletişim
                  dosyasının gelmemesi veya teknik iletişim dosyasının gönderildiğinde uyarı verilmesini sağlayacak
                  biçimde ayarları değiştirebilirler.</p>
              <p>Firmamız, işbu "Gizlilik Politikası" hükümlerini dilediği zaman sitede yayınlamak veya
                  kullanıcılara elektronik posta göndermek veya sitesinde yayınlamak suretiyle değiştirebilir.
                  Gizlilik Politikası hükümleri değiştiği takdirde, yayınlandığı tarihte yürürlük kazanır.</p>
              <p>Gizlilik politikamız ile ilgili her türlü soru ve önerileriniz için ……………….. adresine email
                  gönderebilirsiniz. Firmamız’a ait aşağıdaki iletişim bilgilerinden ulaşabilirsiniz.</p>
              <p>L. Kişisel bilgilerinizin güvenliği</p>
              <ol>
                  <li> Kişisel verilerinizin kaybolması, istismar edilmesi veya değiştirilmesini önlemek için makul
                      teknik ve organizasyonel önlemler alırız.</li>
                  <li> Tüm kişisel bilgilerinizi güvenli (şifre ve güvenlik duvarı korumalı) sunucularda saklarız.
                  </li>
                  <li> Mobil uygulama üzerinden gerçekleştirilen tüm finansal aktarımlar şifreleme teknolojisi ile
                      korunmaktadır.</li>
                  <li> İnternet üzerinden veri aktarımının internetin doğası gereği güvenli olmadığını ve internet
                      üzerinden gönderilen verilerin güvenliğini garanti edemeyeceğimizi kabul etmiş sayılırsınız.
                  </li>
                  <li> Mobil uygulamaya erişmek için kullandığınız şifreyi gizli tutmak sizin sorumluluğunuzdadır,
                      sizden şifrenizi vermenizi istemeyiz (sitemize giriş yapmanız haricinde).</li>
              </ol>
              <p>
              </p><h4>Saklama ve İmha</h4>
              Şirketimiz kişisel verileri saklama ve silme işlemleri için bir Saklama ve İmha Politikası
              oluşturmuştur. Kişisel verilerinize ilişkin saklama ve imha işlemleri bu politika kapsamında
              gerçekleştirilmektedir. Buna göre KVKK’da veya ilgili kanunlar ile sair ilgili mevzuatta verinin
              saklanması için bir süre belirlenmişse, söz konusu veri en az bu süre kadar saklanmak zorundadır.
              Olası bir mahkeme talebinin veya kanunla yetkili kılınmış bir idari merciinin ilgili veriye ilişkin
              talebinin tarafımıza geç ulaşması veya tarafı olabileceğimiz bir ihtilafın meydana gelmesi gibi
              ihtimaller gözetilerek, verilerinizin saklanması için mevzuatta öngörülen sürelere 6 ay ila 1 yıl
              arası bir süre eklenerek verilerin saklama süresi belirlenmekte ve belirlenen sürenin sonunda söz
              konusu veriler silinmekte, yok edilmekte veya anonimleştirilmektedir.
              Mevzuatta işlediğimiz verinin saklanma süresine yönelik bir süre öngörülmemiş ise aramızdaki
              ilişkinin gereği olarak olası uyuşmazlıklar da göz önüne alınarak hukuki ilişkimizin sona ermesinden
              itibaren 10 yıllık dava zamanaşımı süresinin geçmesiyle verileriniz herhangi bir talebinize gerek
              olmadan silinir, yok edilir veya anonim hale getirilir.
              Kişisel verilerin işleme şartlarının tamamı ortadan kalkmış ya da tarafımızca beyan edilen veya
              mevzuat kapsamında belirlenen saklama süresi dolmuş ise verileriniz, ilk periyodik imha tarihinde
              veya en geç 6 ay içerisinde re’sen silinir yok edilir veya anonim hale getirilir. Geçerli bir sebep
              ile verilerinizin silinmesine dair talepte bulunmanız halinde ise verileriniz yasal olarak mümkün
              olduğu nispette en geç 30 gün içerisinde silinir. Saklama süresi mevzuatta belirlenmiş verilerinizin
              öngörülen sürelerden önce silinmesini veya imha edilmesini talep etmeniz halinde söz konusu
              talebiniz gerçekleştirilemeyecektir.
              HAKLAR
              KVKK ve ilgili mevzuat kapsamında kişisel verilerinize ilişkin olarak;
              • Kişisel verilerinizin işlenip işlenmediğini öğrenme,
              • Kişisel verileriniz işlenmişse buna ilişkin bilgi talep etme,
              • Kişisel verilerin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme,
              • Kişisel verilerinizin aktarıldığı üçüncü kişileri bilme,
              • Kişisel verilerinizin eksik veya yanlış işlenmiş olması halinde bunların düzeltilmesini isteme,
              • KVKK mevzuatında öngörülen şartlar çerçevesinde kişisel verilerinizin silinmesini veya yok
              edilmesini isteme,
              • Eksik veya yanlış verilerin düzeltilmesi ile kişisel verilerinizin silinmesi veya yok edilmesini
              talep ettiğinizde, bu durumun kişisel verilerinizi aktardığımız üçüncü kişilere bildirilmesini
              isteme,
              • İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle kişinin
              kendisi aleyhine bir sonucun ortaya çıkmasına itiraz etme ve
              • Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara uğramanız halinde bu zararın
              giderilmesini talep etme
              haklarına sahipsiniz.<p></p>
              <p>Başvuru
                  Kişisel verileriniz ile ilgili başvuru ve taleplerinizi dilerseniz İlgili Kişi Başvuru Formu’nu
                  kullanarak;
                  • Islak imzalı ve kimlik fotokopisi ile adresine göndererek,
                  • Geçerli bir kimlik belgesi ile birlikte Şirketimize bizzat başvurarak,
                  • Kayıtlı elektronik posta (KEP) adresi ve güvenli elektronik imza ya da mobil imza kullanmak
                  suretiyle kayıtlı elektronik posta adresimize göndererek veya
                  • Şirketimize daha önce bildirilen ve sistemimizde kayıtlı bulunan elektronik posta adresinizden
                  adresimize göndererek
                  Bize iletebilirsiniz.
                  Veri Sorumlusuna Başvuru Usul ve Esasları Hakkında Tebliğ uyarınca ilgili kişilerin bu
                  başvurularında ad – soyad, başvuru yazılı ise imza, T.C. kimlik numarası (başvuruda bulunan kişinin
                  yabancı olması halinde pasaport numarası), tebligata esas yerleşim yeri veya iş yeri adresi, varsa
                  bildirime esas elektronik posta adresi, telefon numarası ve faks numarası ile talep konusuna dair
                  bilgilerin bulunması zorunludur.
                  Veri sahibi ilgili kişi, yukarıda belirtilen hakları kullanmak için yapacağı ve kullanmayı talep
                  ettiği hakka ilişkin açıklamaları içeren başvuruda talep edilen hususu açık ve anlaşılır şekilde
                  belirtilmelidir. Başvuruya ilişkin bilgi ve belgelerin başvuruya eklenmesi gerekmektedir.
                  Talep konusunun başvuranın şahsı ile ilgili olması gerekmekle birlikte, başkası adına hareket
                  ediliyor ise başvuruyu yapanın bu konuda özel olarak yetkili olması ve bu yetkinin belgelendirilmesi
                  (özel vekâletname) gerekmektedir. Ayrıca başvurunun kimlik ve adres bilgilerini içermesi ve
                  başvuruya kimliği doğrulayıcı belgelerin eklenmesi gerekmektedir.
                  Yetkisiz üçüncü kişilerin başkası adına yaptığı talepler değerlendirmeye alınmayacaktır.
                  Kişisel verilerinize ilişkin hak talepleriniz değerlendirilerek, Şirketimize ulaştığı tarihten
                  itibaren en geç 30 gün içerisinde cevaplanır. Başvurunuzun olumsuz değerlendirilmesi halinde,
                  gerekçeli ret sebepleri başvuruda belirttiğiniz adrese elektronik posta veya posta yolu başta olmak
                  üzere eğer mümkünse talebin yapıldığı usul vasıtasıyla size iletilir.</p>
              <p>
              </p><h4>Kullanıcı Hesaplarınızın ve İlişkili Verilerinizin Silinmesini Dilediğinizde Destek Ekibimize Mail
                  Aracılığı ile
                  Ulaşarak Bildirebilirsiniz</h4>
              <p></p>
              <p>İletişim:<a href="mailto:hello@jurnalle.app">hello@jurnalle.app</a></p>
              <p>İletişim:<a href="mailto:support@jurnalle.app">support@jurnalle.app</a></p>
          </div>
      </article>
  
  
   </section>
  </main>
  <Footer />
  </section>
  );
};

export default privacyPolicy;
