import React , {useState} from "react";
import "./header.css";
import { Link, useNavigate } from "react-router-dom";




interface HeaderProps {
  noSearchBox?: boolean;
}

const Header: React.FC<HeaderProps> = (props) => {
  const navigate = useNavigate();
  const searchIcon: string = require("../../product/assets/search-ico.svg").default;
  const side_menu_ico : string = require("../../product/assets/icons/side_menu.svg").default;
  const Notifications_ico : string = require("../../product/assets/icons/Notifications.svg").default;
  const avatar_profile_ico : string = require("../../product/assets/icons/avatar_profile.svg").default;
  const plus_ico : string = require("../../product/assets/icons/plus.svg").default;

  const [isToggled, setIsToggled] = useState<boolean>(false);

  const handleClick = () => {
    setIsToggled(prev => !prev);
    document.body.classList.toggle('menu-style', !isToggled);
  };

  const handleCreateNewsClick = () => {
    navigate('/create-news');
  };

  return (
      <header className="header">
        <div className="header-bar">

          {!props.noSearchBox && (
              <div className="nav-right-boxes">
                <div className="nav-right-search nav-right">
                  <img src={searchIcon} alt="search" className={props.noSearchBox ? "search-ico display-none" : "search-ico"} />
                  <input
                      type="text"
                      placeholder="Jurnalci, haber ara"
                      className="header-input"
                  />
                </div>
                <div className="right-box" onClick={handleCreateNewsClick}>
                  <img src={plus_ico} alt="search"/>
                  <span className="make-news">Oluştur</span>
                </div>
                <div className="right-box">
                  <img src={Notifications_ico} alt="search"/>
                </div>
                <div className="right-box">
                  <img src={avatar_profile_ico} alt="search"/>
                </div>
              </div>
          )}
        </div>
      </header>
  );
};

export default Header;

