import React from "react";
import "./App.css";
import { useAppSelector } from "./stores/hooks";
import { selectAuthStatus } from "./stores/auth/authSlice";
import { HelmetProvider } from "react-helmet-async";
import { Navigate, Route, Routes } from "react-router-dom";
import LandingRegister from "./views/landingRegister/LandingRegister";
import Register from "./views/register/Register";
import Login from "./views/auth/login/Login";
import TermsOfService from "./views/jurnalleWebSite/Terms_of_service";
import CommunityRules from "./views/jurnalleWebSite/CommunityRules";
import MainLayout from "./views/v2/_main/layouts/MainLayout";
import Notifications from "./views/notifications/Notifications";
import { AuthStatus } from "./stores/auth/AuthStatus";
import AccountRemoved from "./views/auth/accountRemoved/AccountRemoved";
import PrivacyPolicy from "./views/jurnalleWebSite/PrivacyPolicy";
import Cookies from "./views/jurnalleWebSite/Cookies";
import Sss from "./views/jurnalleWebSite/Sss";
import HomeJurnalleWebSite from "./views/jurnalleWebSite/Home";
import RemoveAccount from "./views/auth/removeAccount/RemoveAccount";
import Discover from "./views/v2/discover/Discover";
import FeedV2 from "./views/v2/feed/FeedV2";
import MyProfileV2 from "./views/v2/profile/ProfileV2";
import SettingsV2 from "./views/v2/settings/SettingsV2";
import BookmarkedNews from "./views/v2/bookmarkedNews/BookmarkedNews";
import CreateNewsLayout from "./views/v2/_main/layouts/CreateNewsLayout";
import CreateNewsV2 from "./views/v2/createNews/CreteNewsV2";
import CreateNewsDetailsV2 from "./views/v2/createNews/CreteNewsDetailsV2";
import NewsDetailsV2 from "./views/v2/newsDetails/NewsDetailsV2";
import OtherProfileV2 from "./views/v2/otherProfile/OtherProfile";
import ProtectedRouteV2 from "./product/components/ProtectedRouteV2";

function App() {
  const authStatus = useAppSelector(selectAuthStatus);
  return (
    <HelmetProvider>
      <Routes>
        <Route path='/' element={<Navigate to='/feed' replace />} />
        <Route path='/landing' element={<LandingRegister />} />
        <Route path='/register' element={<Register />} />
        <Route path='/login' element={<Login />} />
        <Route path='/terms_of_service' element={<TermsOfService />} />
        <Route path='/community_rules' element={<CommunityRules />} />
        <Route path='/privacy_policy' element={<PrivacyPolicy />} />
        <Route path='/cookies' element={<Cookies />} />
        <Route path='/sss' element={<Sss />} />
        <Route path='/home' element={<HomeJurnalleWebSite />} />
        <Route path='/about' element={<HomeJurnalleWebSite />} />
        {/* Deprecated views */}
        <Route path='/privacy_policy.html' element={<PrivacyPolicy />} />
        <Route path='/terms_of_service.html' element={<TermsOfService />} />
        <Route path='/cookies.html' element={<Cookies />} />
        <Route path='/community_rules.html' element={<CommunityRules />} />
        <Route path='/sss.html' element={<Sss />} />
        <Route path='/home.html' element={<HomeJurnalleWebSite />} />
        <Route path='/remove-account' element={<RemoveAccount />} />

        <Route element={<ProtectedRouteV2 />}>
          {/* Routes with Sidebar */}
          <Route element={<MainLayout />}>
            <Route path='/feed' element={<FeedV2 />} />
            <Route path='/discover' element={<Discover />} />
            <Route path='/my-profile' element={<MyProfileV2 />} />
            <Route path='/notifications' element={<Notifications />} />
            <Route path='/bookmarked-news' element={<BookmarkedNews />} />

            <Route path='/settings' element={<SettingsV2 />} />
            <Route path='/news-details/:param' element={<NewsDetailsV2 />} />
            <Route path='/other-profile/:hashedUserId' element={<OtherProfileV2 />} />

            {/* Add other routes with sidebar here */}
          </Route>

          <Route element={<CreateNewsLayout />}>
            <Route path='/create-news' element={<CreateNewsV2 />} />
            <Route path='/create-news/details' element={<CreateNewsDetailsV2 />} />
          </Route>
        </Route>
        {/* Conditional Route for User Removed */}
        <Route
          path='/account-removed'
          element={
            authStatus === AuthStatus.UserRemoved ? <AccountRemoved /> : <Navigate to='/remove-account' replace />
          }
        />

        {/* Catch-All Redirect to Home */}
        <Route path='*' element={<Navigate to='/' />} />
      </Routes>
    </HelmetProvider>
  );
}

export default App;
