import { ActionReducerMapBuilder, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { SignInRequest } from "../../features/auth/domain/entity/SignInRequest";
import { AuthState } from "./AuthState";
import { AuthStatus } from "./AuthStatus";
import { RootState } from "../index";
import { removeAccount } from "../../features/auth/data/data_sources/services/auth/auth";
import { container } from "../../product/di_containers/inversify.config";
import { SignIn } from "../../features/auth/domain/useCases/sign-in.usecase";
import { SignInDto } from "../../features/auth/data/dto/sign-in.dto";
import { Failure } from "../../core/error/failure";
import { ResultVoid } from "../../core/types/types";

const initialToken = localStorage.getItem("accessToken");

const initialState: AuthState = {
  user: null,
  status: initialToken ? AuthStatus.Authenticated : AuthStatus.Idle,
  error: null,
  isProfileComplete: false,
};

export const removeUserAccount = createAsyncThunk<void, SignInRequest, { rejectValue: string }>(
  "auth/remove-account",
  async (removeRequest: SignInRequest, thunkAPI) => {
    try {
      await removeAccount(removeRequest);
      localStorage.removeItem("token");
      return thunkAPI.fulfillWithValue(undefined);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message || "Authentication failed");
    }
  }
);

export const signInUser = createAsyncThunk<void, SignInDto, { rejectValue: string }>(
  "auth/signIn",
  async (dto: SignInDto, thunkAPI) => {
    const signInUseCase = container.get<SignIn>(SignIn);

    const result: ResultVoid = await signInUseCase.execute(dto);

    return result.fold(
      (error: Failure) => thunkAPI.rejectWithValue(error.message), // Reject with ApiFailure if
      // sign-in fails
      () => thunkAPI.fulfillWithValue(undefined) // Fulfill with void if sign-in succeeds
    );
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    // WARNING: This should be removed after Auth migration to C/A
    setAuthenticated(state) {
      state.status = AuthStatus.Authenticated;
    },
  },
  extraReducers: (builder) => {
    // Sign-in cases
    signIn(builder);

    // Account removal cases
    accountRemovedSlice(builder);
  },
});

function signIn(builder: ActionReducerMapBuilder<AuthState>) {
  builder
    .addCase(signInUser.pending, (state) => {
      state.status = AuthStatus.Loading;
      state.error = null;
    })
    .addCase(signInUser.fulfilled, (state, action) => {
      state.status = AuthStatus.Authenticated;
    })
    .addCase(signInUser.rejected, (state, action) => {
      state.status = AuthStatus.Failed;
      state.error = action.payload || "Sign-in failed. Please try again.";
    });
}

function accountRemovedSlice(builder: ActionReducerMapBuilder<AuthState>) {
  builder
    .addCase(removeUserAccount.pending, (state) => {
      state.status = AuthStatus.Loading;
      state.error = null;
    })
    .addCase(removeUserAccount.fulfilled, (state) => {
      state.status = AuthStatus.UserRemoved;
      state.user = null;
      state.isProfileComplete = false;
    })
    .addCase(removeUserAccount.rejected, (state, action) => {
      state.status = AuthStatus.Failed;
      state.error = action.payload || "Failed to remove account.";
    });
}

export const { setAuthenticated } = authSlice.actions;
export const selectUser = (state: RootState) => state.auth.user;
export const selectIsProfileComplete = (state: RootState) => state.auth.isProfileComplete;
export const selectAuthStatus = (state: RootState) => state.auth.status;
export const selectAuthError = (state: RootState) => state.auth.error;

export default authSlice.reducer;
