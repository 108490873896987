import React, { FormEvent, useState } from "react";

import { Link } from "react-router-dom";


import './jurnalleWebSite.css';
import Header from "./Header"
import Footer from "./Footer"

const CommunityRules: React.FC = () => {


  return (
    <section className="web-section">
        <Header />
      
    <main className="main">


      <section className="container terms">
        <article className="page">
          <header className="page__header">
            <h1 className="page__title">jurnalle Topluluk Kuralları</h1> <br />
          </header>
          <div className="page__content">
            


              <h4>Jurnalle topluluğu, adil, saygılı ve bilgilendirici bir ortamın korunması için aşağıdaki kurallara uymalıdır: </h4><br />

              <li>Doğruluk ve Güvenilirlik:
                Haberlerin doğru ve güvenilir olması esastır. Gerçek dışı veya yanıltıcı içerikler paylaşılmamalıdır. </li>

              <li>Saygı ve Hoşgörü:
                Her kullanıcıya karşı saygılı olunmalı ve farklı görüşlere hoşgörüyle yaklaşılmalıdır. Taciz, tehdit veya nefret söylemi kabul edilemez.
                İçerik Kalitesi: Paylaşılan içeriklerin kaliteli ve anlamlı olması önemlidir. Boş veya anlamsız içerikler paylaşılmamalıdır. </li>

              <li>Tartışma ve Katılım:
                Tartışmalarda yapıcı olunmalı ve fikirler açıkça ifade edilmelidir. Diğer kullanıcıların görüşlerine saygı gösterilmelidir. </li>

              <li>Telif Hakkı:
                Başkalarının telif haklarına saygı gösterilmeli ve izin alınmadan içerik paylaşılmamalıdır.
                Kişisel Bilgi ve Gizlilik: Kullanıcıların kişisel bilgileri ve gizliliği önemlidir. Başka kullanıcıların kişisel bilgilerini paylaşmak veya istismar etmek yasaktır. </li>

              <li>Spam ve İstenmeyen İçerik:
                Spam içerikler veya istenmeyen reklamlar paylaşılmamalıdır. Ayrıca, platformun amacı dışında içeriklerin paylaşılması da yasaktır. </li>

              <li>Haberin Kaynağı Her Şeydir:
                Haber kaynaklarına saygı göstermeli ve güvenilir kaynaklardan alınmış içerikleri paylaşmalıyız. Bilgi, doğru kaynaktan gelir! </li>

              <li>Gerçeğin Çelik Çemberleri:
                Doğruluk, platformumuzun temel taşıdır. Paylaşılan haberlerin gerçekliğini doğrulamak için elimizden gelenin en iyisini yapmalıyız. </li>

              <li>Kapsamlı Kategori Yönetimi:
                Her haberin uygun bir kategoriye yerleştirilmesi, kullanıcıların bilgiye daha kolay erişmesini sağlar. Kategorilerimizde derinlemesine bir araştırma yapalım!
                Kaynak Doğrulama Sürüşü: Doğrulanmış kaynaklar, haberlerin güvenilirliğinde kritik bir rol oynar. Onların sağladığı içeriklere ayrı bir özen göstermeliyiz. </li>

              <li>Fikir Alışverişi Çağlayanı:
                Farklı görüşler, sağlıklı bir demokrasinin temelidir. Tartışmalarımızı mantıklı ve saygılı bir şekilde sürdürmeliyiz. Her görüşün önemli olduğunu unutmayalım! Bilgi Gizliliği Kalesi: Kişisel bilgilerin gizliliğine son derece önem veriyoruz. Herkesin bilgileri güvenli ve korunaklı olmalı. </li>

              <li>Spam Karanlık Dalgaları:
              Spam ve istenmeyen içerikler, platformumuzun denizinde hiçbir zaman yüzmeyecek. Temiz bir haber akışı sağlamak için spam ile savaşacağız! Kuralların Gazetesi: Kurallarımız, Jurnalle topluluğunun kalbinde atar. Onlara uymak, platformumuzun temel değerlerini korumamıza yardımcı olur.</li>

            <li>Kurallara Uyma:
              Tüm kullanıcılar, Jurnalle platformunun kurallarına uymakla yükümlüdür.          Kurallara uymayan içerikler bildirilmelidir.
              Bu kurallar, Jurnalle topluluğunun güvenli ve verimli bir ortamda etkileşimde bulunmasını sağlamak için oluşturulmuştur. Her kullanıcı, bu kurallara uymakla sorumludur.
              Bu kurallar, Jurnalle'nin topluluk standartlarını korumak ve platformda olumlu bir deneyim sağlamak için temel bir çerçeve sunar.</li>

            <p>İletişim:<a href="mailto:hello@jurnalle.app">hello@jurnalle.app</a></p>
            <p>İletişim:<a href="mailto:support@jurnalle.app">support@jurnalle.app</a></p>
          </div>
        </article>
      </section>
    </main>
    <Footer />
    </section>
 

  );
};

export default CommunityRules;

