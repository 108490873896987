import { inject, injectable } from "inversify";
import type { INewsRepository } from "../repositories/iNewsRepository";
import { ResultFuture } from "../../../../core/types/types";
import { NewsContainerTypes } from "../../newsContainerTypes";

@injectable()
export class DenyNews {
  @inject(NewsContainerTypes.INewsRepository) private newsRepo!: INewsRepository;

  async execute(id: number): ResultFuture<boolean> {
    return this.newsRepo.denyNews(id);
  }
}

