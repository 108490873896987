import React, { useEffect, useState } from "react";
import "../../../components/header/header.css";
import { useNavigate } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import "react-tabs/style/react-tabs.css";

import "./NotificationPopup.css";

const NotificationPopup: React.FC = (props) => {
  const navigate = useNavigate();
  const cancelIco: string = require("../../assets/icons/cance.svg").default;
  const dotsHorizontal: string = require("../../assets/icons/dots-horizontal.svg").default;
  const user_profile: string = require("../../assets/icons/user_profile.svg").default;

  const [isClassToggled, setIsClassToggled] = useState(false);

  useEffect(() => {
    if (isClassToggled) {
      document.body.classList.add("notification-pop");
    } else {
      document.body.classList.remove("notification-pop");
    }

    return () => {
      document.body.classList.remove("notification-pop");
    };
  }, [isClassToggled]);

  const ToogleNotificationDisplay = () => {
    setIsClassToggled((prevState) => !prevState);
  };

  return (
    <div className='popup-notifications'>
      <div className='popup-not-head'>
        <h4>Bildiriler (3)</h4>
        <div className='popup-not-head-right'>
          <img src={dotsHorizontal} alt='search' className='right-20' />
          <img src={cancelIco} alt='cancel' className='hover-cursor' onClick={ToogleNotificationDisplay} />
        </div>
      </div>

      <Tabs>
        <TabList>
          <Tab>Tümü</Tab>
          <Tab>Hesap</Tab>
        </TabList>

        <TabPanel>
          <div className='popup-not-head-all'>
            <ul>
              <li>
                <div className='popup-not-line1'>
                  <div className='set-flexed'>
                    <div className='popup-not-cnt'>
                      <h5>E-posta adresini doğrula</h5>
                      <span className='notification-text'>
                        Jurnalledeki özelliklerin tamamını kullanabilmek için hesabını doğrula!{" "}
                      </span>
                    </div>

                    <div>
                      <span className='time-info-not'>10:44</span>
                    </div>
                  </div>

                  <div className='btn-wide'>
                    <button className='btn-notificaiton'>Görüntüle</button>
                  </div>
                </div>
              </li>

              <li>
                <div className='popup-not-line1'>
                  <div className='set-flexed'>
                    <div className='popup-not-cnt'>
                      <h5>E-posta adresini doğrula</h5>
                      <span className='notification-text'>
                        Jurnalledeki özelliklerin tamamını kullanabilmek için hesabını doğrula!{" "}
                      </span>
                    </div>

                    <div>
                      <span className='time-info-not'>10:44</span>
                    </div>
                  </div>

                  <div className='btn-wide'>
                    <button className='btn-notificaiton'>Görüntüle</button>
                  </div>
                </div>
              </li>

              <li>
                <div className='popup-not-line2'>
                  <div className='comment-notif'>
                    <div className='comment-notif-user'>
                      <img src={user_profile} />
                      <div>
                        <span className='comment-user-not'> Ceyhun Samyeli </span>
                        <span className='comment-user-txt'> Gönderine yorum bıraktı.</span>
                      </div>
                    </div>

                    <div>
                      <span className='time-info-not'>10:44</span>
                    </div>
                  </div>

                  <div className='comment-detail-short'>
                    <div className='img-cnt'>
                      <img src='https://jurnalle-staging.s3.eu-central-1.amazonaws.com/1731368095-image_cropper_1731367855104.jpg' />
                    </div>
                    <span>
                      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                      labore et dolore magna aliqua.”
                    </span>
                  </div>
                </div>
              </li>

              <li>
                <div className='popup-not-line2'>
                  <div className='comment-notif'>
                    <div className='comment-notif-user'>
                      <img src={user_profile} />
                      <div>
                        <span className='comment-user-not'> Ceyhun Samyeli </span>
                        <span className='comment-user-txt'>Seni takip etmeye başladı.</span>
                      </div>
                    </div>

                    <div>
                      <span className='time-info-not'>10:44</span>
                    </div>
                  </div>

                  <div className='btn-wide btn-wide-flex'>
                    <button className='btn-notificaiton marg-10'>Kapat</button>

                    <button className='btn-notificaiton bg-green'>Görüntüle</button>
                  </div>
                </div>
              </li>

              <li>
                <div className='popup-not-line2'>
                  <div className='comment-notif'>
                    <div className='comment-notif-user'>
                      <img src={user_profile} />
                      <div>
                        <span className='comment-user-not'> Masaaki Stephane </span>
                        <span className='comment-user-txt'> Gönderini doğruladı.</span>
                      </div>
                    </div>

                    <div>
                      <span className='time-info-not'>10:44</span>
                    </div>
                  </div>

                  <div className='comment-detail-short'>
                    <div className='img-cnt'>
                      <img src='https://jurnalle-staging.s3.eu-central-1.amazonaws.com/1731368095-image_cropper_1731367855104.jpg' />
                    </div>
                    <span>Kalifornia valisi tartışmalı yapay zeka tasarısını veto ederek BigTech’e kazandırdı</span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </TabPanel>
        <TabPanel>
          <div className='popup-not-head-all'>
            <ul>
              <li>
                <div className='popup-not-line2'>
                  <div className='comment-notif'>
                    <div className='comment-notif-user'>
                      <img src={user_profile} />
                      <div>
                        <span className='comment-user-not'> Masaaki Stephane </span>
                        <span className='comment-user-txt'> Gönderini doğruladı.</span>
                      </div>
                    </div>

                    <div>
                      <span className='time-info-not'>10:44</span>
                    </div>
                  </div>

                  <div className='comment-detail-short'>
                    <div className='img-cnt'>
                      <img src='https://jurnalle-staging.s3.eu-central-1.amazonaws.com/1731368095-image_cropper_1731367855104.jpg' />
                    </div>
                    <span>Kalifornia valisi tartışmalı yapay zeka tasarısını veto ederek BigTech’e kazandırdı</span>
                  </div>
                </div>
              </li>

              <li>
                <div className='popup-not-line1'>
                  <div className='set-flexed'>
                    <div className='popup-not-cnt'>
                      <h5>E-posta adresini doğrula</h5>
                      <span className='notification-text'>
                        Jurnalledeki özelliklerin tamamını kullanabilmek için hesabını doğrula!{" "}
                      </span>
                    </div>

                    <div>
                      <span className='time-info-not'>10:44</span>
                    </div>
                  </div>

                  <div className='btn-wide'>
                    <button className='btn-notificaiton'>Görüntüle</button>
                  </div>
                </div>
              </li>

              <li>
                <div className='popup-not-line1'>
                  <div className='set-flexed'>
                    <div className='popup-not-cnt'>
                      <h5>E-posta adresini doğrula</h5>
                      <span className='notification-text'>
                        Jurnalledeki özelliklerin tamamını kullanabilmek için hesabını doğrula!{" "}
                      </span>
                    </div>

                    <div>
                      <span className='time-info-not'>10:44</span>
                    </div>
                  </div>

                  <div className='btn-wide'>
                    <button className='btn-notificaiton'>Görüntüle</button>
                  </div>
                </div>
              </li>

              <li>
                <div className='popup-not-line2'>
                  <div className='comment-notif'>
                    <div className='comment-notif-user'>
                      <img src={user_profile} />
                      <div>
                        <span className='comment-user-not'> Ceyhun Samyeli </span>
                        <span className='comment-user-txt'> Gönderine yorum bıraktı.</span>
                      </div>
                    </div>

                    <div>
                      <span className='time-info-not'>10:44</span>
                    </div>
                  </div>

                  <div className='comment-detail-short'>
                    <div className='img-cnt'>
                      <img src='https://jurnalle-staging.s3.eu-central-1.amazonaws.com/1731368095-image_cropper_1731367855104.jpg' />
                    </div>
                    <span>
                      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                      labore et dolore magna aliqua.”
                    </span>
                  </div>
                </div>
              </li>

              <li>
                <div className='popup-not-line2'>
                  <div className='comment-notif'>
                    <div className='comment-notif-user'>
                      <img src={user_profile} />
                      <div>
                        <span className='comment-user-not'> Ceyhun Samyeli </span>
                        <span className='comment-user-txt'>Seni takip etmeye başladı.</span>
                      </div>
                    </div>

                    <div>
                      <span className='time-info-not'>10:44</span>
                    </div>
                  </div>

                  <div className='btn-wide btn-wide-flex'>
                    <button className='btn-notificaiton marg-10'>Kapat</button>

                    <button className='btn-notificaiton bg-green'>Görüntüle</button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default NotificationPopup;
