import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./SideBar.css";

const SideBarV2: React.FC = () => {
  const [isToggled, setIsToggled] = useState<boolean>(false);
  const jurnalleLogo: string = require("../../assets/jurnalle.svg").default;
  const home: string = require("../../assets/icons/home.svg").default;
  const userIcon: string = require("../../assets/icons/user.svg").default;
  const settings: string = require("../../assets/icons/settings.svg").default;
  const bookmark: string = require("../../assets/icons/bookmark.svg").default;
  const searchIcon: string = require("../../assets/icons/search.svg").default;
  const side_menu_ico: string = require("../../assets/icons/side_menu.svg").default;
  const handleClick = () => {
    setIsToggled((prev) => !prev);
    document.body.classList.toggle("menu-style", !isToggled);
  };

  const location = useLocation();

  // Helper to determine if the link should have an active class
  const isActiveLink = (path: string) => location.pathname === path;

  return (
    <section className='left-block'>
      <div className='buttons-box width160'>
        <div className='jurnalle-logo'>
          <img onClick={() => handleClick()} style={{ cursor: "pointer" }} src={side_menu_ico} alt='Toggle Sidebar' />
          <Link to='/feeds'>
            <img src={jurnalleLogo} alt='logo' className='logo-head' />
          </Link>
        </div>
        <Link to='/feeds' className={`button-link ${isActiveLink("/feed") ? "active" : ""}`}>
          <img src={home} alt='home' className='search-ico' />
          <span> Anasayfa</span>
        </Link>

        <Link to='/discover' className={`button-link ${isActiveLink("/discover") ? "active" : ""}`}>
          <img src={searchIcon} alt='discover' className='search-ico' />
          <span> Keşfet</span>
        </Link>

        <Link to='/my-profile' className={`button-link ${isActiveLink("/my-profile") ? "active" : ""}`}>
          <img src={userIcon} alt='profile' className='search-ico' />
          <span> Profil</span>
        </Link>

        <Link to='/bookmarked-news' className={`button-link ${isActiveLink("/bookmarked-news") ? "active" : ""}`}>
          <img src={bookmark} alt='bookmarks' className='search-ico' />
          <span> Kaydedilenler</span>
        </Link>

        <Link to='/settings' className={`button-link ${isActiveLink("/settings") ? "active" : ""}`}>
          <img src={settings} alt='settings' className='search-ico' />
          <span> Ayarlar</span>
        </Link>
      </div>
    </section>
  );
};

export default SideBarV2;
