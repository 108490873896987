import "reflect-metadata";
import { inject, injectable } from "inversify";
import { IAuthRepository } from "../../domain/repositories/auth.repository.interface";
import { SignInDto } from "../dto/sign-in.dto";
import { ApiFailure } from "../../../../core/error/failure";
import { ResultFutureVoid } from "../../../../core/types/types";
import { Left, Right } from "../../../../core/types/either";
import { ApiException } from "next-netkit";
import type { IAuthLocalDataSource } from "../data_sources/auth.local.data-source.interface";
import type { IAuthRemoteDataSource } from "../data_sources/auth.remote.data-source.interface";
import { jwtDecode } from "jwt-decode";

@injectable()
export class AuthRepository implements IAuthRepository {
  @inject("IAuthLocalDataSource")
  public localDataSource!: IAuthLocalDataSource;
  @inject("IAuthRemoteDataSource")
  private remoteDataSource!: IAuthRemoteDataSource;

  // constructor(
  // @inject("IAuthRemoteDataSource") private remoteDataSource: IAuthRemoteDataSource,
  // @inject("IAuthLocalDataSource") public localDataSource: IAuthLocalDataSource,
  // ) {}

  async signIn(dto: SignInDto): ResultFutureVoid {
    try {
      const response = await this.remoteDataSource.signIn(dto);
      this.localDataSource.saveUser(response.user);
      return new Right(undefined);
    } catch (error) {
      if (error instanceof ApiException) {
        return new Left(ApiFailure.fromException(error));
      }
      return new Left(new ApiFailure({}));
    }

  }

  isAuthenticated(): boolean {
    const token = this.localDataSource.getAccessToken();
    if (!token) {
      return false;
    }

    try {
      const decodedToken: { exp: number } = jwtDecode(token);
      const currentTime = Math.floor(Date.now() / 1000);

      return decodedToken.exp > currentTime;
    } catch (error) {
      return false;
    }
  }

  async removeAccount(credentials: SignInDto): ResultFutureVoid {
    try {
      this.remoteDataSource.removeUser(credentials);
      return new Right(undefined);
    } catch (error) {
      if (error instanceof ApiException) {
        return new Left(ApiFailure.fromException(error));
      }
      return new Left(new ApiFailure({}));
    }

  }
}