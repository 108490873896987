import React, { useCallback, useEffect, useRef } from "react";
import FeedNewsCard from "./card/FeedNewsCard";
import { useAppDispatch, useAppSelector } from "../../../../stores/hooks";
import { fetchFeedNews, resetFeed } from "../../../../stores/news/feed/feedSlice";
import FeedNewsCardShimmer from "./card/FeedNewsCardShimmer";
import Footer from "../../../../components/footer/Footer";

const FeedNewsList: React.FC = () => {
  const dispatch = useAppDispatch();
  const { news, page, categoryId, isLastPage, loading } = useAppSelector((state) => state.feed);

  const loadMoreTriggerRef = useRef<HTMLDivElement | null>(null);

  const loadMore = useCallback(() => {
    if (!isLastPage && !loading) {
      dispatch(fetchFeedNews());
    }
  }, [dispatch, isLastPage, loading]);

  // Observe loadMoreTriggerRef element for bottom scroll detection
  useEffect(() => {
    const observerOptions = {
      root: null, // Use viewport as root
      rootMargin: "100px", // Start loading slightly before reaching the bottom
      threshold: 0.1, // Trigger when 10% of the target is visible
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          loadMore();
        }
      });
    }, observerOptions);

    if (loadMoreTriggerRef.current) {
      observer.observe(loadMoreTriggerRef.current);
    }

    return () => {
      if (loadMoreTriggerRef.current) {
        observer.unobserve(loadMoreTriggerRef.current);
      }
    };
  }, [loadMore]);

  // Reset feed and fetch news when categoryId changes
  useEffect(() => {
    dispatch(resetFeed());
    dispatch(fetchFeedNews());
  }, [dispatch, categoryId]);

  function NewsShimmerInitialList() {
    return (
      <>
        <FeedNewsCardShimmer />
        <FeedNewsCardShimmer />
        <FeedNewsCardShimmer />
        <FeedNewsCardShimmer />
        <FeedNewsCardShimmer />
      </>
    );
  }

  return (
    <div className='news-list'>
      {/* Show shimmer placeholder if loading and no news is present */}
      {loading && news.length === 0 ? (
        <NewsShimmerInitialList />
      ) : (
        news.map((item) => <FeedNewsCard key={item.id} news={item} />)
      )}

      {/* Show shimmer loading indicator if loading more pages */}
      {loading && news.length > 0 && <FeedNewsCardShimmer />}

      {/* Trigger to detect bottom scroll for infinite loading */}
      <div ref={loadMoreTriggerRef} style={{ height: 50 }} />

      {/* Indicate end of news list */}
      {isLastPage && <p className='feed-list-end'>{<Footer />}</p>}
    </div>
  );
};

export default FeedNewsList;
