import React, { FormEvent, useState } from "react";

import { Link } from "react-router-dom";

import Header from "./Header"
import Footer from "./Footer"
import './jurnalleWebSite.css';

const TermsOfService: React.FC = () => {


    return (
      <section className="web-section">
        <Header/>
    <main className="main">
  
                <section className="container terms">

              
    <article className="page">
  <header className="page__header">
    <h1 className="page__title">Hizmet Şartları</h1>
  </header>

  <div className="page__content">
    <p>Üyelik sözleşmesi</p>
    <ol>
      <li>
        <p>
          Taraflar a Mobil uygulamanın (mobil uygulama bundan sonra “Platform”
          olarak anılacaktır.) faaliyetlerini yürüten …… adresinde mukim …..
          (Bundan böyle “Uygulama Yöneticisi” olarak anılacaktır). b Platform’a
          üye olan internet kullanıcısı ("Üye")
        </p>
      </li>
      <li>
        <p>
          Sözleşmenin Konusu İşbu Sözleşme’nin konusu Uygulama Yöneticisi’nin
          sahip olduğu Platform’dan üyenin faydalanma şartlarının
          belirlenmesidir.
        </p>
      </li>
      <li>
        <p>Tarafların Hak ve Yükümlülükleri</p>
      </li>
      <li>
        Platform’a üye olunurken üyelik sayfasında belirtilen bilgilerin doğru
        ve eksiksiz şekilde girilmesi ve Üyelik Sözleşmesi’nin onaylanması
        gerekmektedir. Üye verdiği kişisel ve diğer sair bilgilerin kanunlar
        önünde doğru olduğunu, jurnalle'nin bu bilgilerin gerçeğe aykırılığı
        nedeniyle uğrayacağı tüm zararları aynen ve derhal tazmin edeceğini
        beyan ve taahhüt eder.
      </li>
      <li>
        Üye, Platform’a üye olurken belirlediği şifreyi başka kişi ya da
        kuruluşlara veremez, üyenin söz konusu şifreyi kullanma hakkı bizzat
        kendisine aittir. Üye’nin Platform’a kayıt olurken kullandığı e posta
        adresinin ve belirlediği şifrenin güvenliği ve gizliliği tamamen Üye’nin
        sorumluluğundadır. Üye, kendisine ait e-posta ve şifre ile
        gerçekleştirilen işlemlerin kendisi tarafından gerçekleştirilmiş
        olduğunu, bu sebeple doğabilecek tüm sorumluluk ile üçüncü kişiler veya
        yetkili merciler tarafından Mobil Uygulama'ya karşı ileri sürülebilecek
        tüm iddia ve taleplere karşı münhasıran sorumlu olduğunu ve Mobil
        Uygulama’nın yapılan haberlerden kaynaklı (gerçeği yansıtmayan,
        spekülasyon, abartı vb.) uğrayacağı her türlü zarar ve ziyanı
        karşılayacağını kabul, beyan ve taahhüt eder.
      </li>
      <li>
        Üye Platform’u kullanırken yasal mevzuat hükümlerine riayet etmeyi ve
        bunları ihlal etmemeyi kabul ve taahhüt eder. Aksi takdirde, doğacak tüm
        hukuki ve cezai yükümlülükler tamamen ve münhasıran üyeye ait olacaktır.
      </li>
      <li>
        Üye, Platform’u hiçbir şekilde kamu düzenini bozucu, genel ahlaka
        aykırı, başkalarını rahatsız ve taciz edici şekilde, yasalara aykırı bir
        amaç için, başkalarının fikri ve telif haklarına tecavüz edecek şekilde
        kullanamaz. Ayrıca, üye başkalarının hizmetleri kullanmasını önleyici
        veya zorlaştırıcı faaliyet (spam, virus, truva atı, vb.) ve işlemlerde
        bulunamaz. Platform’un bütününü veya herhangi bir bölümünü bozma,
        değiştirme, tersine mühendislik yapma amacıyla kullanamaz. Platform’a,
        Platform’un veri tabanına ve Platform üzerinde yer alan herhangi bir
        içeriğe zarar verici bir teknoloji kullanamaz veya virüs yayamaz.
        Platform’u herhangi yasadışı veya hileli amaçla kullanamaz.
      </li>
      <li>
        Platform’da üyeler tarafından beyan edilen, yazılan, kullanılan fikir,
        düşünce ve yorumlar, tamamen üyelerin kendi kişisel görüşleridir ve
        Mobil Uygulama’nın bu fikir, düşünce ve yorumlarla ilgili herhangi bir
        sorumluluğu bulunmamaktadır. Mobil Uygulama’ya yöneltilebilecek herhangi
        bir talepte Mobil Uygulama ilgili talebi fikir, düşünce ve yorum
        sahibine iletecek olup tüm zarar ve ziyanını fikir, düşünce ve yorum
        sahibine rücu etme hakkı saklı olacaktır.
      </li>
      <li>
        İşbu üyelik sözleşmesi içerisinde sayılan maddelerden bir ya da
        birkaçını ihlal eden üye işbu ihlal nedeniyle cezai ve hukuki olarak
        şahsen sorumlu olup, Mobil Uygulama’yı bu ihlallerin hukuki ve cezai
        sonuçlarından ari tutacaktır. Ayrıca; işbu ihlal nedeniyle, olayın hukuk
        alanına intikal ettirilmesi halinde, Mobil Uygulama’nın üyeye karşı
        üyelik sözleşmesine uyulmamasından dolayı tazminat talebinde bulunma
        hakkı saklıdır.
      </li>
      <li>
        Mobil Uygulama’nın her zaman tek taraflı olarak gerektiğinde üyenin
        üyeliğine son verme hakkı saklıdır. Üye, Mobil Uygulama’nın bu hakkını
        kullanması halinde herhangi bir itirazda bulunmayacağını peşinen kabul
        eder.
      </li>
      <li>
        Mobil Uygulama markası ve logosu, Platform’un yazılım ve tasarımı ve
        bunlara ilişkin olarak Mobil Uygulama tarafından oluşturulan her türlü
        marka, tasarım, logo, slogan ve benzeri tüm içerik Mobil Uygulama
        mülkiyetindedir. Bunlara ilişkin sınai mülkiyet hakkı ve/veya diğer
        fikri mülkiyet hakları ilgili kanunlarca korunmakta olup, bunlar üye
        tarafından izinsiz kullanılamaz, iktisap edilemez ve değiştirilemez.
        Üye’nin Mobil Uygulama’nın üçüncü kişilerin fikri mülkiyet haklarını
        ihlal etmesi halinde Mobil Uygulama’nın söz konusu ihlalden kaynaklanan
        her türlü zararını tazmin ve sair talep hakkı saklıdır.
      </li>
      Üye, Mobil Uygulama tarafından Platform’un iyileştirilmesi,
      geliştirilmesine yönelik olarak ve/veya yasal mevzuat çerçevesinde siteye
      erişmek için kullanılan İnternet servis sağlayıcısının adı ve Internet
      Protokol (IP) adresi, Siteye erişilen tarih ve saat, sitede bulunulan
      sırada erişilen sayfalar ve siteye doğrudan bağlanılmasını sağlayan Web
      sitesinin Internet adresi gibi birtakım bilgilerin toplanabileceğini kabul
      ve beyan eder. Mobil Uygulama, Platform’un virus ve benzeri amaçlı
      yazılımlardan arındırılmış olması için mevcut imkanlar dahilinde tedbir
      alınmıştır. Bunun yanında nihai güvenliğin sağlanması için kullanıcının,
      kendi virus koruma sistemini tedarik etmesi ve gerekli korunmayı sağlaması
      gerekmektedir. Bu bağlamda üye Platform’a girmesiyle, kendi yazılım ve
      işletim sistemlerinde oluşabilecek tüm hata ve bunların doğrudan ya da
      dolaylı sonuçlarından kendisinin sorumlu olduğunu kabul etmiş sayılır.
      <li>
        Mobil Uygulama, sitenin içeriğini dilediği zaman değiştirme,
        kullanıcılara sağlanan herhangi bir hizmeti değiştirme ya da sona
        erdirme hakkını saklı tutar.
      </li>
      <li>
        Mobil Uygulama, üyelik sözleşmesinin koşullarını hiçbir şekil ve surette
        ön ihbara ve/veya ihtara gerek kalmaksızın her zaman değiştirebilir,
        güncelleyebilir veya iptal edebilir. Değiştirilen, güncellenen ya da
        yürürlükten kaldırılan her hüküm, yayın tarihinde tüm üyeler bakımından
        hüküm ifade edecektir.
      </li>
      Taraflar, Mobil Uygulama 'a ait tüm bilgisayar kayıtlarının tek ve gerçek
      münhasır delil olarak, HMK madde 193’e uygun şekilde esas alınacağını ve
      söz konusu kayıtların bir delil sözleşmesi teşkil ettiği hususunu kabul ve
      beyan eder.
      <li>
        Üye ticari elektronik ileti tercihlerini “Hesabım” bölümündeki “Üyelik
        Bilgisi Güncelleme” kısmından yönetebileceğini bildiğini kabul eder.
      </li>
      Mobil Uygulama, Üyeler’in verilerini 6698 sayılı Kişisel Verilerin
      Korunması Kanunu ile uyumlu olarak işlemekte ve korumaktadır. Üye kişisel
      verilerinin işlenmesi ve korunmasına ilişkin detaylı bilgiye Mobil
      Uygulama’dan ulaşabilir.
      <li>
        <p>
          Sözleşmenin Feshi İşbu sözleşme üyenin üyeliğini iptal etmesi veya
          Mobil Uygulama tarafından üyeliğinin iptal edilmesine kadar yürürlükte
          kalacaktır. Taraflardan her biri Üyelik Sözleşmesi'ni tek taraflı
          olarak ve tazminat ödemeksizin her zaman feshedebilir. Mobil Uygulama
          üyenin üyelik sözleşmesinin herhangi bir hükmünü ihlal etmesi
          durumunda üyenin üyeliğini iptal ederek sözleşmeyi tek taraflı olarak
          feshedebilecektir.
        </p>
      </li>
      <li>
        <p>
          İhtilafların Halli İşbu sözleşmeye ilişkin ihtilaflarda Mersin
          Mahkemeleri ve İcra Daireleri yetkilidir.
        </p>
      </li>
      <li>
        <p>
          Yürürlük Üyenin, üyelik kaydı yapması üyenin üyelik sözleşmesinde yer
          alan tüm maddeleri okuduğu ve üyelik sözleşmesinde yer alan maddeleri
          kabul ettiği anlamına gelir. İşbu Sözleşme üyenin üye olması anında
          akdedilmiş ve karşılıklı olarak yürürlülüğe girmiştir. MOBİL UYGULAMA
          KULLANIM ŞARTLARI Lütfen mobil uygulamayı kullanmadan evvel bu ‘mobil
          uygulama kullanım şartları’nı dikkatlice okuyunuz. Bu uygulamayı
          kullanan ve haber yükleyen kullanıcılarımız aşağıdaki şartları kabul
          etmiş varsayılmaktadır: Sizler (‘Kullanıcı’) Mobil Uygulamada sunulan
          tüm hizmetleri kullanırken aşağıdaki şartlara tabi olduğunuzu,
          sitedeki hizmetten yararlanmakla ve kullanmaya devam etmekle; Bağlı
          olduğunuz yasalara göre sözleşme imzalama hakkına, yetkisine ve hukuki
          ehliyetine sahip ve 18 yaşın üzerinde olduğunuzu, bu sözleşmeyi
          okuduğunuzu, anladığınızı ve sözleşmede yazan şartlarla bağlı
          olduğunuzu kabul etmiş sayılırsınız. İşbu sözleşme taraflara sözleşme
          konusu site ile ilgili hak ve yükümlülükler yükler ve taraflar işbu
          sözleşmeyi kabul ettiklerinde bahsi geçen hak ve yükümlülükleri
          eksiksiz, doğru, zamanında, işbu sözleşmede talep edilen şartlar
          dâhilinde yerine getireceklerini beyan ederler. 7 - SORUMLULUKLAR
        </p>
      </li>
      <li>
        jurnalle, faaliyetler ve hizmetler üzerinde değişiklik yapma hakkını her
        zaman saklı tutar.
      </li>
      <li>
        <p>
          2 Firma, üyenin sözleşme konusu hizmetlerden, teknik arızalar dışında
          yararlandırılacağını kabul ve taahhüt eder.
        </p>
      </li>
      <li>
        <p>
          3 Kullanıcı, Mobil Uygulamanın kullanımında tersine mühendislik
          yapmayacağını ya da bunların kaynak kodunu bulmak veya elde etmek
          amacına yönelik herhangi bir başka işlemde bulunmayacağını aksi halde
          ve 3. Kişiler nezdinde doğacak zararlardan sorumlu olacağını, hakkında
          hukuki ve cezai işlem yapılacağını peşinen kabul eder.{" "}
        </p>
      </li>
      <li>
        <p>
          4 Kullanıcı, site içindeki faaliyetlerinde, sitenin herhangi bir
          bölümünde veya iletişimlerinde genel ahlaka ve adaba aykırı, kanuna
          aykırı, 3. Kişilerin haklarını zedeleyen, yanıltıcı, saldırgan,
          müstehcen, pornografik, kişilik haklarını zedeleyen, telif haklarına
          aykırı, yasa dışı faaliyetleri teşvik eden içerikler üretmeyeceğini,
          paylaşmayacağını kabul eder. Aksi halde oluşacak zarardan tamamen
          kendisi sorumludur ve bu durumda ‘Mobil Uygulama’ yetkilileri, bu tür
          hesapları askıya alabilir, sona erdirebilir, yasal süreç başlatma
          hakkını saklı tutar. Bu sebeple yargı mercilerinden etkinlik veya
          kullanıcı hesapları ile ilgili bilgi talepleri gelirse paylaşma
          hakkını saklı tutar.
        </p>
      </li>
      <li>
        <p>
          5 Mobil Uygulamanın üyelerinin birbirleri veya üçüncü şahıslarla olan
          ilişkileri kendi sorumluluğundadır.{" "}
        </p>
      </li>
      <li>
        <p> Fikri Mülkiyet Hakları</p>
      </li>
      <li>
        İşbu Mobil Uygulama’da yer alan ünvan, işletme adı, marka, patent, logo,
        tasarım, bilgi ve yöntem gibi tescilli veya tescilsiz tüm fikri mülkiyet
        hakları site işleteni ve sahibi firmaya veya belirtilen ilgilisine ait
        olup, ulusal ve uluslararası hukukun koruması altındadır. İşbu Site’nin
        ziyaret edilmesi veya bu Mobil Uygulama’daki hizmetlerden yararlanılması
        söz konusu fikri mülkiyet hakları konusunda hiçbir hak vermez.
      </li>
      <li>
        Mobil Uygulama’da yer alan bilgiler hiçbir şekilde çoğaltılamaz,
        yayınlanamaz, kopyalanamaz, sunulamaz ve/veya aktarılamaz. Mobil
        Uygulama’nın bütünü veya bir kısmı diğer bir internet sitesinde izinsiz
        olarak kullanılamaz.
      </li>
      <li>
        <p>Gizli Bilgi</p>
      </li>
      <li>
        jurnalle, site üzerinden kullanıcıların ilettiği kişisel bilgileri 3.
        Kişilere açıklamayacaktır. Bu kişisel bilgiler; kişi adı-soyadı, adresi,
        telefon numarası, cep telefonu, e-posta adresi gibi Kullanıcı’yı
        tanımlamaya yönelik her türlü diğer bilgiyi içermekte olup, kısaca
        ‘Gizli Bilgiler’ olarak anılacaktır.
      </li>
      <li>
        Kullanıcı, sadece tanıtım, reklam, kampanya, promosyon, duyuru vb.
        pazarlama faaliyetleri kapsamında kullanılması ile sınırlı olmak üzere,
        Mobil Uygulama’nın sahibi olan firmanın kendisine ait iletişim, portföy
        durumu ve demografik bilgilerini iştirakleri ya da bağlı bulunduğu grup
        şirketleri ile paylaşmasına muvafakat ettiğini kabul ve beyan eder. Bu
        kişisel bilgiler firma bünyesinde Kullanıcı profili belirlemek,
        Kullanıcı profiline uygun promosyon ve kampanyalar sunmak ve
        istatistiksel çalışmalar yapmak amacıyla kullanılabilecektir.
      </li>
      <li>
        Gizli Bilgiler, ancak resmi makamlarca usulü dairesinde bu bilgilerin
        talep edilmesi halinde ve yürürlükteki emredici mevzuat hükümleri
        gereğince resmi makamlara açıklama yapılmasının zorunlu olduğu
        durumlarda resmi makamlara açıklanabilecektir.
      </li>
      <li>
        Garanti Vermeme: işbu sözleşme maddesi uygulanabilir kanunun izin
        verdiği azami ölçüde geçerli olacaktır. Firma tarafından sunulan
        hizmetler "olduğu gibi” ve "mümkün olduğu” temelde sunulmakta ve
        pazarlanabilirlik, belirli bir amaca uygunluk veya ihlal etmeme
        konusunda tüm zımni garantiler de dâhil olmak üzere hizmetler veya
        uygulama ile ilgili olarak (bunlarda yer alan tüm bilgiler dâhil) sarih
        veya zımni, kanuni veya başka bir nitelikte hiçbir garantide
        bulunmamaktadır.
      </li>
      <li>
        <p>
          Kayıt ve Güvenlik Kullanıcı, doğru, eksiksiz ve güncel kayıt
          bilgilerini vermek zorundadır. Aksi halde bu Sözleşme ihlal edilmiş
          sayılacak ve Kullanıcı bilgilendirilmeksizin hesap kapatılabilecektir.
          Kullanıcı, site ve üçüncü taraf sitelerdeki şifre ve hesap
          güvenliğinden kendisi sorumludur. Aksi halde oluşacak veri
          kayıplarından ve güvenlik ihlallerinden veya donanım ve cihazların
          zarar görmesinden Firma sorumlu tutulamaz.
        </p>
      </li>
      <li>
        <p>
          Mücbir Sebep Tarafların kontrolünde olmayan; tabii afetler, yangın,
          patlamalar, iç savaşlar, savaşlar, ayaklanmalar, halk hareketleri,
          seferberlik ilanı, grev, lokavt ve salgın hastalıklar, altyapı ve
          internet arızaları, elektrik kesintisi gibi sebeplerden (aşağıda
          birlikte "Mücbir Sebep” olarak anılacaktır.) dolayı sözleşmeden doğan
          yükümlülükler taraflarca ifa edilemez hale gelirse, taraflar bundan
          sorumlu değildir. Bu sürede Taraflar’ın işbu Sözleşme’den doğan hak ve
          yükümlülükleri askıya alınır.{" "}
        </p>
      </li>
      <li>
        <p>
          Sözleşmenin Bütünlüğü ve Uygulanabilirlik İşbu sözleşme şartlarından
          biri, kısmen veya tamamen geçersiz hale gelirse, sözleşmenin geri
          kalanı geçerliliğini korumaya devam eder.
        </p>
      </li>
      <li>
        <p>
          Sözleşmede Yapılacak Değişiklikler Firma, dilediği zaman Mobil
          Uygulama’da sunulan hizmetleri ve işbu sözleşme şartlarını kısmen veya
          tamamen değiştirebilir. Değişiklikler sitede yayınlandığı tarihten
          itibaren geçerli olacaktır. Değişiklikleri takip etmek Kullanıcı’nın
          sorumluluğundadır. Kullanıcı, sunulan hizmetlerden yararlanmaya devam
          etmekle bu değişiklikleri de kabul etmiş sayılır.
        </p>
      </li>
      <li>
        <p>ÜRÜNLER: </p>
      </li>
    </ol>
    <p>
      14.1. Mobil Uygulama kendi takdiri ile uygulama ile ilgili her türlü
      değişikliği yapma hakkına sahiptir. 15. Tebligat İşbu Sözleşme ile ilgili
      taraflara gönderilecek olan tüm bildirimler, Firma’nın bilinen e.posta
      adresi ve kullanıcının üyelik formunda belirttiği e.posta adresi
      vasıtasıyla yapılacaktır. Kullanıcı, üye olurken belirttiği adresin
      geçerli tebligat adresi olduğunu, değişmesi durumunda 5 gün içinde yazılı
      olarak diğer tarafa bildireceğini, aksi halde bu adrese yapılacak
      tebligatların geçerli sayılacağını kabul eder. 16. Delil Sözleşmesi
      Taraflar arasında işbu sözleşme ile ilgili işlemler için çıkabilecek her
      türlü uyuşmazlıklarda Taraflar’ın defter, kayıt ve belgeleri ile ve
      bilgisayar kayıtları ve faks kayıtları 6100 sayılı Hukuk Muhakemeleri
      Kanunu uyarınca delil olarak kabul edilecek olup, kullanıcı bu kayıtlara
      itiraz etmeyeceğini kabul eder.
    </p>
    <p>
      VERİ KATEGORİLERİ 1- Kimlik Bilgileri İşlenen Kişisel Veriler:
      <br />
      Ad – Soyad, Doğum Tarihi, Cinsiyet, T.C. Kimlik Numarası Kişisel Veri
      İşleme Amaçları:
    </p>
    <p>
      Finans ve Muhasebe İşlerinin Yürütülmesi, İletişim Faaliyetlerinin
      Yürütülmesi; Firma / Ürün / Hizmetlere Bağlılık Süreçlerinin Yürütülmesi;
      Faaliyetlerin Mevzuata Uygun Yürütülmesi; Hukuk İşlerinin Takibi ve
      Yürütülmesi; İş Sürekliliğinin Sağlanması Faaliyetlerinin Yürütülmesi; İş
      Faaliyetlerinin Yürütülmesi ve Denetimi; Mal / Hizmet Satış Süreçlerinin
      Yürütülmesi; Mal / Hizmet Satış Sonrası Destek Hizmetlerinin Yürütülmesi;
      Kullanıcı İlişkileri Yönetimi Süreçlerinin Yürütülmesi; Kullanıcı
      Memnuniyetine Yönelik Aktivitelerin Yürütülmesi; Reklam, Kampanya ve
      Promosyon Süreçlerinin Yürütülmesi; Saklama ve Arşiv Faaliyetlerinin
      Yürütülmesi; Sözleşme ve Üyelik Süreçlerinin Yürütülmesi; Talep /
      Şikayetlerin Takibi; Ticari ve/veya İş Stratejilerinin Planlanması ve
      İcrası; Sunulan Ürün ve Hizmetlerden Faydalandırmak için Gerekli
      Çalışmaların İş Birimleri Tarafından Yapılması ve İlgili İş Süreçlerinin
      Yürütülmesi; Yetkili Kişi, Kurum ve Kuruluşlara Bilgi Verilmesi
    </p>
    <p>
      Kişisel Veri İşlemenin Hukuki Sebepleri
      <br />
      Bu kapsamdaki kişisel verileriniz, Mobil Uygulama tarafından yukarıda
      sayılan amaçların gerçekleştirilmesi için ilgili mevzuat ve KVKK’nın
      5’inci maddesinde belirtilen; • Kanunlarda açıkça öngörülmesi, • Bir
      sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili olması
      kaydıyla, sözleşmenin taraflarına ait kişisel verilerin işlenmesinin
      gerekli olması, • Veri sorumlusunun hukuki yükümlülüğünü yerine
      getirebilmesi için zorunlu olması ve • İlgili kişinin temel hak ve
      özgürlüklerine zarar vermemek kaydıyla, veri sorumlusunun meşru
      menfaatleri için veri işlenmesinin zorunlu olması hukuki sebeplerine
      dayanılarak işlenmektedir. Yukarıdaki amaçlar kapsamına girmeyen haller
      için, kişisel verileriniz detayları Rıza Metni'nde belirtilen açık
      rızanıza dayalı olarak işlenmektedir.
    </p>
    <p>
      Kişisel Verilerin Toplanma Yöntemleri:
      <br />
      Yukarıda belirtilen kişisel veriler internet sitemizdeki üyelik formları,
      hesabım sayfası içeriğinde yer alan ve tercihinize bağlı olarak
      doldurulabilecek alanlar, talep ve başvurularınız, sözleşmeler,
      kampanyalar ve üçüncü taraf kimlik doğrulama sistemleri (Ör: Google Login,
      Facebook Login vb.) vasıtasıyla toplanmaktadır.
    </p>
    <p>
      2- İletişim Bilgileri İşlenen Kişisel Veriler:
      <br />
      E-Posta Adresi, Fatura &amp; Teslimat Adresleri, Cep Telefonu Numarası
      Kişisel Veri İşleme Amaçları:
      <br />
      Finans ve Muhasebe İşlerinin Yürütülmesi; İletişim Faaliyetlerinin
      Yürütülmesi; İş Faaliyetlerinin Yürütülmesi; Firma / Ürün / Hizmetlere
      Bağlılık Süreçlerinin Yürütülmesi; Hukuk İşlerinin Takibin ve Yürütülmesi;
      Mal/Hizmet Satış Süreçlerinin Yürütülmesi; Mal / Hizmet Satış Sonrası
      Destek Hizmetlerinin Yürütülmesi; Kullanıcı İlişkileri Yönetimi
      Süreçlerinin Yürütülmesi; Kullanıcı Memnuniyetine Yönelik Aktivitelerin
      Yürütülmesi; Pazarlama Analiz Çalışmalarının Yürütülmesi; Reklam, Kampanya
      ve Promosyon Süreçlerinin Yürütülmesi; Talep / Şikayetlerin Takibi; Ürün /
      Hizmetlerin Pazarlama Süreçlerinin Yürütülmesi; Saklama ve Arşiv
      Süreçlerinin Yürütülmesi; Yetkili Kişi, Kurum ve Kuruluşlara Bilgi
      Verilmesi
    </p>
    <p>
      Kişisel Veri İşlemenin Hukuki Sebepleri
      <br />
      Bu kapsamdaki kişisel verileriniz, Mobil Uygulama tarafından yukarıda
      sayılan amaçların gerçekleştirilmesi için ilgili mevzuat ve KVKK’nın
      5’inci maddesinde belirtilen; • Kanunlarda açıkça öngörülmesi, • Bir
      sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili olması
      kaydıyla, sözleşmenin taraflarına ait kişisel verilerin işlenmesinin
      gerekli olması, • Veri sorumlusunun hukuki yükümlülüğünü yerine
      getirebilmesi için zorunlu olması ve • İlgili kişinin temel hak ve
      özgürlüklerine zarar vermemek kaydıyla, veri sorumlusunun meşru
      menfaatleri için veri işlenmesinin zorunlu olması hukuki sebeplerine
      dayanılarak işlenmektedir. Yukarıdaki amaçlar kapsamına girmeyen haller
      için, kişisel verileriniz detayları Rıza Metni'nde belirtilen açık
      rızanıza dayalı olarak işlenmektedir. Kişisel Verilerin Toplanma
      Yöntemleri:
      <br />
      Yukarıda belirtilen kişisel veriler internet sitemizdeki üyelik sayfaları,
      sözleşmeler, hesabım sayfası başta olmak üzere internet sitemizin
      içeriğinde yer alan ve tercihinize bağlı doldurulabilecek alanlar, talep
      ve başvurularınız, sözleşmeler, kampanyalar ve üçüncü taraf kimlik
      doğrulama sistemleri (Ör: Google Login, Facebook Login vb.) vasıtasıyla
      toplanmaktadır.
    </p>
    <p>
      3- Hukuki İşlem Bilgileri İşlenen Kişisel Veriler:
      <br />
      Uyuşmazlık Olması Halinde Dava Dosyasındaki Bilgiler, İhtarnameler, Adli
      ve İdari Makamlarla Yazışmalardaki Bilgiler Kişisel Veri İşleme Amaçları:
      <br />
      Hukuk İşlerinin Takibi ve Yürütülmesi; İş Faaliyetlerinin Yürütülmesi ve
      Denetimi; Yetkili Kişi, Kurum ve Kuruluşlara Bilgi Verilmesi;
      Faaliyetlerin Mevzuata Uygun Yürütülmesi; Saklama ve Arşiv Faaliyetlerinin
      Yürütülmesi; Risk Yönetimi Süreçlerinin Yürütülmesi; Sözleşme Süreçlerinin
      Yürütülmesi; Talep / Şikayetlerin Takibi; Veri Sorumlusu Operasyonlarının
      Güvenliğinin Temini Kişisel Veri İşlemenin Hukuki Sebepleri
      <br />
      Bu kapsamdaki kişisel verileriniz, Mobil Uygulama tarafından yukarıda
      sayılan amaçların gerçekleştirilmesi için ilgili mevzuat ve KVKK’nın
      5’inci maddesinde belirtilen; • Veri sorumlusunun hukuki yükümlülüğünü
      yerine getirebilmesi için zorunlu olması, • Bir hakkın tesisi,
      kullanılması veya korunması için veri işlemenin zorunlu olması ve • İlgili
      kişinin temel hak ve özgürlüklerine zarar vermemek kaydıyla, veri
      sorumlusunun meşru menfaatleri için veri işlenmesinin zorunlu olması
      hukuki sebeplerine dayanılarak işlenmektedir. Kişisel Verilerin Toplanma
      Yöntemleri:
      <br />
      Yukarıda belirtilen kişisel veriler adli ve idari makamlardan gelen resmi
      yazılar ve basılı / elektronik diğer belgeler vasıtasıyla toplanmaktadır.
      4- Kullanıcı İşlem Bilgileri İşlenen Kişisel Veriler:
      <br />
      Bağış ve Fatura Bilgileri, Talep Bilgileri, Haber Yorumları. Kişisel Veri
      İşleme Amaçları:
      <br />
      İş Faaliyetlerinin Yürütülmesi ve Denetimi; Yetkili Kişi, Kurum ve
      Kuruluşlara Bilgi Verilmesi; Faaliyetlerin Mevzuata Uygun Yürütülmesi;
      Finans ve Muhasebe İşlerinin Yürütülmesi; Saklama ve Arşiv Faaliyetlerinin
      Yürütülmesi; Sözleşme Süreçlerinin Yürütülmesi; Kullanıcı Memnuniyetine
      Yönelik Aktivitelerin Yürütülmesi; Pazarlama Analiz Çalışmalarının
      Yürütülmesi; Reklam, Kampanya ve Promosyon Süreçlerinin Yürütülmesi; Talep
      / Şikayetlerin Takibi Kişisel Veri İşlemenin Hukuki Sebepleri
      <br />
      Bu kapsamdaki kişisel verileriniz, Mobil Uygulama tarafından yukarıda
      sayılan amaçların gerçekleştirilmesi için ilgili mevzuat ve KVKK’nın
      5’inci maddesinde belirtilen; • Kanunlarda açıkça öngörülmesi, • Bir
      sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili olması
      kaydıyla, sözleşmenin taraflarına ait kişisel verilerin işlenmesinin
      gerekli olması, • Veri sorumlusunun hukuki yükümlülüğünü yerine
      getirebilmesi için zorunlu olması ve • İlgili kişinin temel hak ve
      özgürlüklerine zarar vermemek kaydıyla, veri sorumlusunun meşru
      menfaatleri için veri işlenmesinin zorunlu olması hukuki sebeplerine
      dayanılarak işlenmektedir. Yukarıdaki amaçlar kapsamına girmeyen haller
      için, kişisel verileriniz detayları Rıza Metni'nde belirtilen açık
      rızanıza dayalı olarak işlenmektedir. Kişisel Verilerin Toplanma
      Yöntemleri:
      <br />
      Yukarıda belirtilen kişisel veriler basılı/elektronik formlar, çağrı
      merkezi kayıtları, e-postalar ve SAP programları vasıtasıyla
      toplanmaktadır. 5- İşlem Güvenliği Bilgileri İşlenen Kişisel Veriler:
      <br />
      IP Adresi Bilgileri, İnternet Sitesi Giriş Çıkış Bilgileri, Kullanıcı Adı
      Bilgileri, Şifre Bilgileri, Trafik Verileri (Bağlantı Zamanı / Süresi vb.)
      Kişisel Veri İşleme Amaçları:
      <br />
      Bilgi Güvenliği Süreçlerinin Yürütülmesi; Denetim / Etik Faaliyetlerin
      Yürütülmesi; İş Faaliyetlerinin Yürütülmesi ve Denetimi; Saklama ve Arşiv
      Faaliyetlerinin Yürütülmesi; Veri Sorumlusu Operasyonlarının Güvenliğinin
      Temini; Yetkili Kişi, Kurum ve Kuruluşlara Bilgi Verilmesi; Faaliyetlerin
      Mevzuata Uygun Yürütülmesi; Hukuk İşlerinin Takibi ve Yürütülmesi; Mal /
      Hizmet Satış Süreçlerinin Yürütülmesi; Talep / Şikayetlerin Takibi Kişisel
      Veri İşlemenin Hukuki Sebepleri
      <br />
      Bu kapsamdaki kişisel verileriniz, Mobil Uygulama tarafından yukarıda
      sayılan amaçların gerçekleştirilmesi için ilgili mevzuat ve KVKK’nın
      5’inci maddesinde belirtilen; • Kanunlarda açıkça öngörülmesi, • Veri
      sorumlusunun hukuki yükümlülüğünü yerine getirebilmesi için zorunlu olması
      ve • İlgili kişinin temel hak ve özgürlüklerine zarar vermemek kaydıyla,
      veri sorumlusunun meşru menfaatleri için veri işlenmesinin zorunlu olması
      hukuki sebeplerine dayanılarak işlenmektedir. Yukarıdaki amaçlar kapsamına
      girmeyen haller için, kişisel verileriniz detayları Rıza Metni'nde
      belirtilen açık rızanıza dayalı olarak işlenmektedir. Kişisel Verilerin
      Toplanma Yöntemleri:
      <br />
      Yukarıda belirtilen kişisel veriler bilgi güvenliği sistemleri ve
      elektronik cihazlar aracılığı ile toplanmaktadır. 6- Finans Bilgileri
      İşlenen Kişisel Veriler:
      <br />
      Şifrelenmiş Kredi Kartı Bilgileri, Banka Hesap / IBAN Numarası Bilgileri
      Kişisel Veri İşleme Amaçları:
      <br />
      Faaliyetlerin Mevzuata Uygun Yürütülmesi; Hukuk İşlerinin Takibi ve
      Yürütülmesi; Finans ve Muhasebe İşlerinin Yürütülmesi; İade Süreçlerinin
      Yürütülmesi; İş Faaliyetlerinin Yürütülmesi ve Denetimi; Saklama ve Arşiv
      Faaliyetlerinin Yürütülmesi; Risk Yönetimi Süreçlerinin Yürütülmesi;
      Sözleşme Süreçlerinin Yürütülmesi; Yetki Kişi, Kurum ve Kuruluşlara Bilgi
      Verilmesi Kişisel Veri İşlemenin Hukuki Sebepleri
      <br />
      Bu kapsamdaki kişisel verileriniz, Mobil Uygulama tarafından yukarıda
      sayılan amaçların gerçekleştirilmesi için ilgili mevzuat ve KVKK’nın
      5’inci maddesinde belirtilen; • Bir sözleşmenin kurulması veya ifasıyla
      doğrudan doğruya ilgili olması kaydıyla, sözleşmenin taraflarına ait
      kişisel verilerin işlenmesinin gerekli olması ve • Veri sorumlusunun
      hukuki yükümlülüğünü yerine getirebilmesi için zorunlu olması hukuki
      sebeplerine dayanılarak işlenmektedir. Kişisel Verilerin Toplanma
      Yöntemleri:
      <br />
      Yukarıda belirtilen kişisel veriler basılı ve elektronik formlar,
      e-postalar ve internet sitesi içerisindeki talepler ve mesajlar
      vasıtasıyla toplanmaktadır. 7- Görsel ve İşitsel Kayıt Bilgileri İşlenen
      Kişisel Veriler:
      <br />
      Çağrı Merkezi Ses Kayıtları Kişisel Veri İşleme Amaçları:
      <br />
      Firma Ürün ve Hizmetlere Bağlılık Süreçlerinin Yürütülmesi; Kullanıcı
      İlişkileri Yönetimi Süreçlerinin Yürütülmesi; Kullanıcı Memnuniyetine
      Yönelik Aktivitelerin Yürütülmesi; Talep / Şikayetlerin Takibi; Ürün /
      Hizmetlerin Pazarlama Süreçlerinin Yürütülmesi; Kullanıcı İlişkileri
      Yönetimi Süreçlerinin Yürütülmesi; Pazarlama Analiz Çalışmalarının
      Yürütülmesi; Yetki Kişi, Kurum ve Kuruluşlara Bilgi Verilmesi Kişisel Veri
      İşlemenin Hukuki Sebepleri
      <br />
      Bu kapsamdaki kişisel verileriniz, Mobil Uygulama tarafından yukarıda
      sayılan amaçların gerçekleştirilmesi için ilgili mevzuat ve KVKK’nın
      5’inci maddesinde belirtilen; • Kanunlarda açıkça öngörülmesi, • Veri
      sorumlusunun hukuki yükümlülüğünü yerine getirebilmesi için zorunlu olması
      ve • İlgili kişinin temel hak ve özgürlüklerine zarar vermemek kaydıyla,
      veri sorumlusunun meşru menfaatleri için veri işlenmesinin zorunlu olması
      hukuki sebeplerine dayanılarak işlenmektedir. Kişisel Verilerin Toplanma
      Yöntemleri:
      <br />
      Yukarıda belirtilen kişisel veriler çağrı merkezimize yapılan aramalar
      sırasında elektronik ortamda toplanmaktadır. 8- Lokasyon Bilgileri İşlenen
      Kişisel Veriler:
      <br />
      Konum Bilgileri Kişisel Veri İşleme Amaçları:
      <br />
      Kullanıcı İlişkileri Yönetimi Süreçlerinin Yürütülmesi; Pazarlama Analiz
      Çalışmalarının Yürütülmesi; Reklam / Kampanya / Promosyon Süreçlerinin
      Yürütülmesi; Kullanıcı Memnuniyetine Yönelik Aktivitelerin Yürütülmesi;
      Ürün / Hizmetlerin Pazarlama Süreçlerinin Yürütülmesi; Mal / Hizmet Satış
      Süreçlerinin Yürütülmesi; Mal / Hizmet Üretim Ve Operasyon Süreçlerinin
      Yürütülmesi Kişisel Veri İşlemenin Hukuki Sebepleri
      <br />
      Bu kapsamdaki kişisel verileriniz, Mobil Uygulama tarafından yukarıda
      sayılan amaçların gerçekleştirilmesi için ilgili mevzuat ve KVKK’nın
      5’inci maddesinde belirtilen, detaylarına Rıza Metni'nden
      ulaşabileceğiniz; • Açık Rıza hukuki sebebine dayanılarak işlenmektedir.
      Kişisel Verilerin Toplanma Yöntemleri:
      <br />
      Yukarıda belirtilen kişisel veriler kullandığınız internet tarayıcısındaki
      ve Şirketimiz mobil uygulamasındaki tercihinize göre toplanmaktadır.
      KİŞİSEL VERİLERİN AKTARILMASI kullanıcılara ait kişisel veriler KVKK’nın
      “Kişisel Verilerin Aktarımı” başlıklı 8. maddesindeki ve/veya “Kişisel
      Verilerin Yurt Dışına Aktarılması” başlıklı 9. maddesindeki kurallara
      uyularak ve gerekli teknik ve idari tedbirler alınarak, sadece ilgili
      amacın gerçekleşmesi için gerekli olduğu ölçüde; • Yukarıda sayılan
      amaçlarla sınırlı olarak iştirakler ve bağlı ortaklıklarımıza, • Yine
      yukarıda sayılan amaçlarla aramızdaki sözleşmeler kapsamında mevzuata
      uygun bir şekilde (reklam paylaşımı) yapılan iş ortaklarına, • Hukuki
      yükümlülüğümüzü yerine getirebilmek için yasal düzenlemeler ve mevzuat
      gereği yetkili kişi ve resmi kurumlara aktarılabilmekte ve • Teknoloji
      alanındaki altyapı tedarikçilerimiz tarafından sağlanan / kullanımımıza
      sunulan programlarımıza ve/veya sistemlerimize kaydedilebilmektedir.
    </p>
    <p>
      Kişisel Verilerin İşlenmesine İlişkin Aydınlatma Metni Veri Sorumlusu
      <br />
      jurnalle
    </p>
    <p>
      jurnalle olarak 6698 sayılı Kişisel Verilerin Korunması Kanunu (“KVKK”)
      ile ilgili mevzuat ve yasal düzenlemelerden kaynaklanan faaliyetlerimiz
      çerçevesinde kişisel verilerinizin işlenmesi, saklanması ve aktarılmasına
      ilişkin veri sahibi ilgili kişileri aydınlatmak amacıyla işbu Kişisel
      Verilerin İşlenmesi Aydınlatma Metni’ni (“Aydınlatma Metni”) hazırladık.{" "}
    </p>
    <br />
    Siz kullanıcılarımızın çevrimiçi ve/veya basılı formlar vasıtasıyla
    paylaştığı kimlik bilgileriniz (isim, soyisim, doğum tarihi, uyruk, T.C.
    kimlik numarası, cinsiyet, pasaport geçerlilik tarihi, pasaport numarası),
    iletişim bilgileriniz (e-posta adresi, fatura ve teslimat adresleri, cep
    telefonu numarası); internet sitemizi kullanımız sırasında toplanan
    kullanıcı işlem bilgileriniz (talep bilgileri, Haber bilgileri, kullanıcı
    yorumları, miles&amp;smiles numarası, hes kodu),(çerez kayıtları, haber
    verileri, konum verileri, açık veya örtülü kullanıcı reytingleri), işlem
    güvenliği bilgileri (IP Adresi Bilgileri, İnternet Sitesi Giriş Çıkış
    Bilgileri, Kullanıcı Adı Bilgileri, Şifre Bilgileri, Bağlantı Zamanı /
    Süresi gibi Trafik Verileri) ile internet tarayıcınız ve/veya Şirketimiz
    mobil uygulamasındaki veya internet sitemizi kullanırken internet
    tarayıcınızdaki tercihlerinize göre toplanabilecek lokasyon verileriniz
    işlenecektir.
    <p />
    İşlenen Kişisel Verilere İlişkin Haklarınız:
    <br />
    KVKK’nın 11. maddesi kapsamında; • Kişisel verilerinizin işlenip
    işlenmediğini öğrenme, • Kişisel verileriniz işlenmişse buna ilişkin bilgi
    talep etme, • Kişisel verilerinizin işlenme amacını ve bunların amacına
    uygun kullanılıp kullanılmadığını öğrenme, • Yurt içinde veya yurt dışında
    kişisel verilerinizin aktarıldığı üçüncü kişileri bilme, • Kişisel
    verilerinizin eksik veya yanlış işlenmiş olması halinde bunların
    düzeltilmesini isteme, • KVKK mevzuatında öngörülen şartlar çerçevesinde
    kişisel verilerinizin silinmesini veya yok edilmesini isteme, • Eksik veya
    yanlış verilerinizin düzeltilmesi ile kişisel verilerinizin silinmesi veya
    yok edilmesini talep ettiğinizde , bu durumun kişisel verilerinizin
    aktarıldığı üçüncü kişilere bildirilmesini isteme, • İşlenen verilerinizin
    münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle aleyhe
    bir sonucun ortaya çıkması durumunda sonuca itiraz etme ve • Kişisel
    verilerinizin kanuna aykırı olarak işlenmesi sebebiyle zarara uğraması
    halinde bu zararın giderilmesini talep etme, haklarına sahipsiniz. Yukarıda
    yer alan tüm kişisel verilerinizin belirtilen işleme amaçları ile sınırlı
    olmak üzere işlenmesine hiçbir baskı altında kalmadan ve açık biçimde
    muvafakat ettiğinizi, üye ol sayfasındaki “hesabımı oluştur” butonuna
    tıklayarak kabul etmiş olacaksınız.
    <p />
  </div>
</article>

            </section>
            </main>
            <Footer />
            </section>
 
    )
  };
  
  export default TermsOfService;

