import React from "react";
import CategoryList from "./category/CategoryList";
import "./FeedV2.css";
import PopularTopicsBlock from "./popularTopics/PopularTopicsBlock";
import FeedNewsList from "./newsList/FeedNewsList";

const FeedV2: React.FC = () => {
  return (
    <div className="feed-container">
      <div className="feed-content">
        <CategoryList />
        <FeedNewsList />
      </div>
      <PopularTopicsBlock />
    </div>
  );
};

export default FeedV2;

