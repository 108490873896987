import React from "react";
import "./BookmarkedNews.css";
import ComingSoon from "../commingSoon/ComingSoon";

const BookmarkedNews: React.FC = () => {
  return (
    <ComingSoon />
  );
};

export default BookmarkedNews;