import React, { FormEvent, useState } from "react";


import { Link } from "react-router-dom";
import './notifications.css';

import SinglePost from "../singlePost/SinglePost"


const Notifications: React.FC = () => {


  const back: string = require("../../product/assets/icons/back.svg").default;
  const user: string = require("../../product/assets/icons/user.svg").default;
  const listLine: string = require("../../product/assets/icons/list-line.svg").default;
  const dot3: string = require("../../product/assets/icons/dot3.svg").default;
  const noProfileUser: string = require("../../product/assets/noprofileuser.png");

  return (
    <>
      <section className="feeds-container">

        <div className="not-header">

          <div className="not-head-left">
            <img src={back} alt="" />
            <span> Bildirimler</span>
          </div>

          <div className="not-head-right">
            <img src={listLine} alt="" />
            <img src={dot3} alt="" className="dots3" />
          </div>

        </div>

          <div className="not-rows">
              <div className="not-row">
                 <div className="not-row-left">
                 <img src={noProfileUser} alt=""  />
                 <div>
                   <span className="not-title"> <strong>  Ceyhun Samyeli </strong>Seni takip etmeye başladı.</span>
                   <span className="date-not">13 Haziran 2024</span>
                 </div>
                 </div>
                 <div className="not-row-right">
                 <img src={dot3} alt=""  />
                 <span className="followbtn"> Sen de takip et </span>
                 </div>
              </div>

              <div className="not-row bg-dark">
                 <div className="not-row-left">
                 <img src={noProfileUser} alt=""  />
                 <div>
                   <span className="not-title"> <strong>  Ceyhun Samyeli </strong>Seni takip etmeye başladı.</span>
                   <span className="date-not">13 Haziran 2024</span>
                 </div>
                 </div>
                 <div className="not-row-right">
                 <img src={dot3} alt=""  />
                 <span className="followbtn"> Sen de takip et </span>
                 </div>
              </div>


              <div className="not-row">
                 <div className="not-row-left">
                 <img src={noProfileUser} alt=""  />
                 <div>
                   <span className="not-title"> <strong>  Ceyhun Samyeli </strong>Seni takip etmeye başladı.</span>
                   <span className="date-not">13 Haziran 2024</span>
                 </div>
                 </div>
                 <div className="not-row-right">
                 <img src={dot3} alt=""  />
                 <span className="followbtn"> Sen de takip et </span>
                 </div>
              </div>


              <div className="not-row">
                 <div className="not-row-left">
                 <img src={noProfileUser} alt=""  />
                 <div>
                   <span className="not-title"> <strong>  Ceyhun Samyeli </strong>Seni takip etmeye başladı.</span>
                   <span className="date-not">13 Haziran 2024</span>
                 </div>
                 </div>
                 <div className="not-row-right">
                 <img src={dot3} alt=""  />
                 <span className="followbtn"> Sen de takip et </span>
                 </div>
              </div>


              <div className="not-row bg-dark">
                 <div className="not-row-left">
                 <img src={noProfileUser} alt=""  />
                 <div>
                   <span className="not-title"> <strong>  Ceyhun Samyeli </strong>Seni takip etmeye başladı.</span>
                   <span className="date-not">13 Haziran 2024</span>
                 </div>
                 </div>
                 <div className="not-row-right">
                 <img src={dot3} alt=""  />
                 <span className="followbtn"> Sen de takip et </span>
                 </div>
              </div>

              <div className="not-row bg-dark">
                 <div className="not-row-left">
                 <img src={noProfileUser} alt=""  />
                 <div>
                   <span className="not-title"> <strong>  Ceyhun Samyeli </strong>Seni takip etmeye başladı.</span>
                   <span className="date-not">13 Haziran 2024</span>
                 </div>
                 </div>
                 <div className="not-row-right">
                 <img src={dot3} alt=""  />
                 <span className="followbtn"> Sen de takip et </span>
                 </div>
              </div>

              <div className="not-row">
                 <div className="not-row-left">
                 <img src={noProfileUser} alt=""  />
                 <div>
                   <span className="not-title"> <strong>  Ceyhun Samyeli </strong>Seni takip etmeye başladı.</span>
                   <span className="date-not">13 Haziran 2024</span>
                 </div>
                 </div>
                 <div className="not-row-right">
                 <img src={dot3} alt=""  />
                 <span className="followbtn"> Sen de takip et </span>
                 </div>
              </div>


              <div className="not-row bg-dark">
                 <div className="not-row-left">
                 <img src={noProfileUser} alt=""  />
                 <div>
                   <span className="not-title"> <strong>  Ceyhun Samyeli </strong>Seni takip etmeye başladı.</span>
                   <span className="date-not">13 Haziran 2024</span>
                 </div>
                 </div>
                 <div className="not-row-right">
                 <img src={dot3} alt=""  />
                 <span className="followbtn"> Sen de takip et </span>
                 </div>
              </div>


          </div>



      </section>
    </>
  );
};

export default Notifications;