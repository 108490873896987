export class CategoryModel {
  constructor(
      public id?: number,
      public name?: string
  ) {}

  copyWith(params: Partial<CategoryModel>): CategoryModel {
    return new CategoryModel(
        params.id ?? this.id,
        params.name ?? this.name
    );
  }
}