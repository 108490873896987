import React, {FormEvent, useState} from "react";

import { Link } from "react-router-dom";
import Footer from "./Footer"
import Header from "./Header"
import './jurnalleWebSite.css';

const HomeJurnalleWebSite: React.FC = () => {

  const location_page: string = require("../../product/assets/images/jurnalleWebSite/location_page.png");
  const Other_profile_follow: string = require("../../product/assets/images/jurnalleWebSite/Other_profile_follow.png");
  const Create_final: string = require("../../product/assets/images/jurnalleWebSite/Create_final.png");
  const comments_page: string = require("../../product/assets/images/jurnalleWebSite/comments_page.png");
  const Jurnal_details: string = require("../../product/assets/images/jurnalleWebSite/Jurnal_details.png");


  const AppStoreLogo: string = require("../../product/assets/images/jurnalleWebSite/appstore.svg").default;
  const PlayStoreLogo: string = require("../../product/assets/images/jurnalleWebSite/googleplay.svg").default;


  return (
      <>

      <section className="web-section">
        <Header />
        <main className="main">
            <section className="home container" id="home">
                <div className="swiper home-swiper">
                    <div className="swiper-wrapper">
                        <section className="swiper-slide">
                            <div className="home__content grid">
                                <div className="home__group">
                                    <img src={location_page} alt="" className="home__img"  />
                                    <div className="home__indicator"></div>
    
                                    <div className="home__details-img">
                                        <h4 className="home__details-title">Lokal.</h4>
                                        <span className="home__details-subtitle">konumundaki haberler</span>
                                    </div>
                                </div>
    
                                <div className="home__data">
                                    <p className="home__subtitle">#herkesicinjurnalle✊</p>
                                    <h1 className="home__title">Her haberin bir yeri var</h1>
                                    <p className="home__description">jurnalle’ de her an güncel, doğru ve 
                                        gerçek haberler, nitelikli yazarlar var.
                                    </p>
                     

                                    <div className="home__buttons">
                                        <Link to="https://play.google.com/store/apps/details?id=com.jurnalle.jurnalle&pli=1" className="store-button">
                                            <img src={PlayStoreLogo} alt="Google Play Store" />
                                          </Link>
                                          <Link to="https://apps.apple.com/tr/app/jurnalle/id6466410768" className="store-button">
                                            <img src={AppStoreLogo} alt="App Store" />
                                          </Link>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                    <div className="swiper-pagination"></div>
                </div>
            </section>
            
          
            <section className="section category">
                <h2 className="section__title"> ... </h2>

                <div className="category__container container grid">
                    <div className="category__data">
                        <img src={Jurnal_details} alt="" className="category__img" />
                        <h3 className="category__title">Özgürsün</h3>
                        <p className="category__description">Haberin kaynağı ya da 
                            Haberlerin alıcısı ol</p>
                    </div>

                    <div className="category__data">
                        <img src={comments_page} alt="" className="category__img" />
                        <h3 className="category__title">Referans</h3>
                        <p className="category__description">Kullanıcı teyitli</p>
                    </div>

                    <div className="category__data">
                        <img src={Other_profile_follow} alt="" className="category__img"  />
                        <h3 className="category__title">Gerçekler</h3>
                        <p className="category__description">Dilediğin habercilerle 
                            gündemini kişiselleştir</p>
                    </div>
                </div>
            </section>

        
            <section className="section about" id="about">
                <div className="about__container container grid">
                    <div className="about__data">
                        <h2 className="section__title about__title">Hakkında </h2>
                        <p className="about__description">
                            Birçok özellikle:
                            Özgürce jurnalle!<br/>
                            Jurnalle, kendisini bir sosyal haber ağı olarak tanımlamaktadır.
                            Her kullanıcının özgürce haber paylaşabilmesine olanak tanır.
                            Bu haberleri de her kullanıcının etkileşimi sonucunda "doğrulama ve yalanlama özellikleri" ile
                            sunarak haber kaosunun önüne geçmeyi ve doğru habere ulaşabilme kolaylığı sunmayı hedeflemektedir.                           İçerisinde oluşturulan ve her kullanıcıya sunulan birçok kategori ve filtreleme özelliği ile
                            İçerisinde oluşturulan ve her kullanıcıya sunulan birçok kategori ve filtreleme özelliği ile 
                            doğru ya da yalan haberin görülebilmesini kolaylaştırır.
                        </p>
                        <Link to="#"  className="button">Hemen keşfet!</Link>
                    </div>

                    <img src={Jurnal_details} alt="" className="about__img"  />
                </div>
            </section>
       
        </main>
        <Footer />
        </section>

    
      </>
    );
  };
  
  export default HomeJurnalleWebSite;

  