import React from "react";
import { Helmet } from "react-helmet-async";

interface NewsDetailsSEOProps {
  title: string;
  description?: string;
  keywords?: string;
  url: string;
  image?: string;
  author?: string;
}

const NewsDetailsSEO: React.FC<NewsDetailsSEOProps> = ({ title, description, keywords, url, image, author }) => (
  <Helmet>
    <title>{title}</title>
    <meta name='description' content={description} />
    <meta name='keywords' content={keywords} />
    <link rel='canonical' href={url} />

    {/* Open Graph / Facebook */}
    <meta property='og:type' content='article' />
    <meta property='og:title' content={title} />
    <meta property='og:description' content={description} />
    <meta property='og:url' content={url} />
    <meta property='og:image' content={image} />
    <meta property='org:author' content={author} />

    {/* Twitter */}
    <meta name='twitter:card' content='summary_large_image' />
    <meta name='twitter:title' content={title} />
    <meta name='twitter:description' content={description} />
    <meta name='twitter:image' content={image} />
  </Helmet>
);

export default NewsDetailsSEO;
