import React, { useEffect, useState } from "react";
import "../../../components/header/header.css";
import { useNavigate } from "react-router-dom";
import "react-tabs/style/react-tabs.css";

import "./HeaderV2.css";
import NotificationPopup from "../notificationPopup/NotificationPopup";

interface HeaderProps {
  noSearchBox?: boolean;
}

const Header: React.FC<HeaderProps> = (props) => {
  const navigate = useNavigate();
  const Notifications_ico: string = require("../../assets/icons/Notifications.svg").default;
  const avatar_profile_ico: string = require("../../assets/icons/avatar_profile.svg").default;
  const plus_ico: string = require("../../assets/icons/plus.svg").default;

  const handleCreateNewsClick = () => {
    navigate("/create-news");
  };

  const [isClassToggled, setIsClassToggled] = useState(false);

  useEffect(() => {
    if (isClassToggled) {
      document.body.classList.add("notification-pop");
    } else {
      document.body.classList.remove("notification-pop");
    }

    return () => {
      document.body.classList.remove("notification-pop");
    };
  }, [isClassToggled]);

  const ToogleNotificationDisplay = () => {
    setIsClassToggled((prevState) => !prevState);
  };
  return (
    <header className='main-layout-header'>
      <div className='header-bar-v2'>
        <div className='header-mobile-center-title'>
          <h2 className='header-title'>Jurnalle</h2>
        </div>
        <div className='nav-right-boxes-v2'>
          <div className='header-main-layout-nav-right-box' onClick={handleCreateNewsClick}>
            <img src={plus_ico} alt='search' />
            <span className='header-create-news-button'>Oluştur</span>
          </div>
          <div className='main-layout-nav-right-box'>
            <img
              src={Notifications_ico}
              alt='notıfıcatıon'
              className='hover-cursor'
              onClick={ToogleNotificationDisplay}
            />
          </div>
          <div className='main-layout-nav-right-box'>
            <img src={avatar_profile_ico} alt='profıle' />
          </div>
          <NotificationPopup />
        </div>
      </div>
    </header>
  );
};

export default Header;
