import { INotificationRepository } from "../../domain/repositories/INotificationRepository";
import { inject, injectable } from "inversify";
import { NotificationContainerTypes } from "../../notificationContainerTypes";
import type { INotificationRemoteDataSource } from "../dataSources/INotificationRemoteDataSource";
import { Left, Right } from "../../../../core/types/either";
import { ResultFuture } from "../../../../core/types/types";
import { ApiException } from "next-netkit";
import { ApiFailure } from "../../../../core/error/failure";
import { NotificationModel } from "../../domain/models/NotificationModel";
import { DisabledNotificationsModel } from "../../domain/models/DisabledNotificationsModel";
import { PaginationParams } from "../../../../product/params/paginationParams";
import { ManageNotificationParams } from "../../domain/useCases/ManageNotification";

@injectable()
export class NotificationRepository implements INotificationRepository {
  @inject(NotificationContainerTypes.INotificationRemoteDataSource)
  private remoteDataSource!: INotificationRemoteDataSource;

  async manageNotification(params: ManageNotificationParams): ResultFuture<boolean> {
    try {
      const result = params.enable
        ? await this.remoteDataSource.enableThisNotificationType(params.notificationTypeId)
        : await this.remoteDataSource.disableThisNotificationType(params.notificationTypeId);
      return new Right(result);
    } catch (error) {
      if (error instanceof ApiException) {
        return new Left(ApiFailure.fromException(error));
      }
      return new Left(new ApiFailure({}));
    }
  }

  async getNotifications(params: PaginationParams): ResultFuture<NotificationModel[]> {
    try {
      const result = await this.remoteDataSource.getNotifications(params);
      return new Right(result);
    } catch (error) {
      if (error instanceof ApiException) {
        return new Left(ApiFailure.fromException(error));
      }
      return new Left(new ApiFailure({}));
    }
  }

  async removeNotification(id: number): ResultFuture<boolean> {
    try {
      await this.remoteDataSource.removeNotification(id);
      return new Right(true);
    } catch (error) {
      if (error instanceof ApiException) {
        return new Left(ApiFailure.fromException(error));
      }
      return new Left(new ApiFailure({}));
    }
  }

  async markNotificationAsRead(id: number): ResultFuture<boolean> {
    try {
      await this.remoteDataSource.markNotificationAsRead(id);
      return new Right(true);
    } catch (error) {
      if (error instanceof ApiException) {
        return new Left(ApiFailure.fromException(error));
      }
      return new Left(new ApiFailure({}));
    }
  }

  async getUnreadNotificationsCount(): ResultFuture<number> {
    try {
      const result = await this.remoteDataSource.getUnreadNotificationsCount();
      return new Right(result.unreadCount ?? 0);
    } catch (error) {
      if (error instanceof ApiException) {
        return new Left(ApiFailure.fromException(error));
      }
      return new Left(new ApiFailure({}));
    }
  }

  async markAllNotificationsAsRead(): ResultFuture<boolean> {
    try {
      await this.remoteDataSource.markAllNotificationsAsRead();
      return new Right(true);
    } catch (error) {
      if (error instanceof ApiException) {
        return new Left(ApiFailure.fromException(error));
      }
      return new Left(new ApiFailure({}));
    }
  }

  async removeAllReadNotifications(): ResultFuture<boolean> {
    try {
      await this.remoteDataSource.removeAllReadNotifications();
      return new Right(true);
    } catch (error) {
      if (error instanceof ApiException) {
        return new Left(ApiFailure.fromException(error));
      }
      return new Left(new ApiFailure({}));
    }
  }

  async removeAllNotifications(): ResultFuture<boolean> {
    try {
      await this.remoteDataSource.removeAllNotifications();
      return new Right(true);
    } catch (error) {
      if (error instanceof ApiException) {
        return new Left(ApiFailure.fromException(error));
      }
      return new Left(new ApiFailure({}));
    }
  }

  async getDisabledNotifications(): ResultFuture<DisabledNotificationsModel> {
    try {
      const result = await this.remoteDataSource.getDisabledNotifications();
      return new Right(result);
    } catch (error) {
      if (error instanceof ApiException) {
        return new Left(ApiFailure.fromException(error));
      }
      return new Left(new ApiFailure({}));
    }
  }
}
