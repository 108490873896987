import { inject, injectable } from "inversify";
import type { INewsRepository } from "../repositories/iNewsRepository";
import { ResultFuture } from "../../../../core/types/types";
import { NewsContainerTypes } from "../../newsContainerTypes";

@injectable()
export class ShareNews {
  @inject(NewsContainerTypes.INewsRepository) private newsRepo!: INewsRepository;

  async execute(slug: string): ResultFuture<boolean> {
    return this.newsRepo.shareNews(slug);
  }
}

