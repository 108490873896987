import { createAction, createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { container } from "../../../product/di_containers/inversify.config";
import { NewsModel } from "../../../product/models/news/newsModel";
import { NewsContainerTypes } from "../../../features/news/newsContainerTypes";
import { NewsDetailsState } from "./newsDetailsState";
import { GetNewsDetails } from "../../../features/news/domain/useCases/GetNewsDetails";
import { Failure } from "../../../core/error/failure";
import { VerifyNews } from "../../../features/news/domain/useCases/VerifyNews";
import { DenyNews } from "../../../features/news/domain/useCases/DenyNews";
import { BookmarkNews } from "../../../features/news/domain/useCases/BookmarkNews";
import { UnBookmarkNews } from "../../../features/news/domain/useCases/UnBookmarkNews";
import { ShareNews } from "../../../features/news/domain/useCases/ShareNews";

const initialState: NewsDetailsState = {
  news: null,
  loading: true,
  error: null,
  isVerified: false,
  isDenied: false,
  isBookmarked: false,
};

export const resetNewsDetailsState = createAction("news/resetNewsDetailsState");

export const fetchNewsDetails = createAsyncThunk<
  NewsModel,
  string,
  {
    rejectValue: string;
  }
>("news/fetchNewsDetails", async (params: string, thunkAPI) => {
  if (params.length === 0) {
    return thunkAPI.rejectWithValue("Haber bulunamadı");
  }
  const getNewsDetailsUseCase = container.get<GetNewsDetails>(NewsContainerTypes.GetNewsDetails);
  const newsDetails = await getNewsDetailsUseCase.execute(params);
  return newsDetails.fold(
    (error: Failure) => thunkAPI.rejectWithValue(error.message),
    (news: NewsModel) => thunkAPI.fulfillWithValue(news)
  );
});

export const verifyNews = createAsyncThunk<
  boolean,
  number,
  {
    rejectValue: string;
  }
>("news/verifyNews", async (params: number, thunkAPI) => {
  const verifyNewsUseCase = container.get<VerifyNews>(NewsContainerTypes.VerifyNews);
  const newsDetails = await verifyNewsUseCase.execute(params);
  return newsDetails.fold(
    (error: Failure) => thunkAPI.rejectWithValue(error.message),
    (isVerified: boolean) => {
      return thunkAPI.fulfillWithValue(isVerified);
    }
  );
});

const denyNews = createAsyncThunk<
  boolean,
  number,
  {
    rejectValue: string;
  }
>("news/denyNews", async (params: number, thunkAPI) => {
  const denyNewsUseCase = container.get<DenyNews>(NewsContainerTypes.DenyNews);
  const newsDetails = await denyNewsUseCase.execute(params);
  return newsDetails.fold(
    (error: Failure) => thunkAPI.rejectWithValue(error.message),
    (isDenied: boolean) => {
      return thunkAPI.fulfillWithValue(isDenied);
    }
  );
});

const bookmarkNews = createAsyncThunk<
  boolean,
  number,
  {
    rejectValue: string;
  }
>("news/bookmarkNews", async (params: number, thunkAPI) => {
  const bookmarkNewsUseCase = container.get<BookmarkNews>(NewsContainerTypes.BookmarkNews);
  const bookmarked = await bookmarkNewsUseCase.execute(params);
  return bookmarked.fold(
    (error: Failure) => thunkAPI.rejectWithValue(error.message),
    (isBookmarked: boolean) => {
      return thunkAPI.fulfillWithValue(isBookmarked);
    }
  );
});

const unBookmarkNews = createAsyncThunk<
  boolean,
  number,
  {
    rejectValue: string;
  }
>("news/unBookmarkNews", async (params: number, thunkAPI) => {
  const unBookmarkNewsUseCase = container.get<UnBookmarkNews>(NewsContainerTypes.UnBookmarkNews);
  const unBookmarked = await unBookmarkNewsUseCase.execute(params);
  return unBookmarked.fold(
    (error: Failure) => thunkAPI.rejectWithValue(error.message),
    (isUnBookmarked: boolean) => {
      return thunkAPI.fulfillWithValue(isUnBookmarked);
    }
  );
});

const shareNews = createAsyncThunk<
  boolean,
  string,
  {
    rejectValue: string;
  }
>("news/shareNews", async (params: string, thunkAPI) => {
  const shareNewsUseCase = container.get<ShareNews>(NewsContainerTypes.ShareNews);
  const shared = await shareNewsUseCase.execute(params);
  return shared.fold(
    (error: Failure) => thunkAPI.rejectWithValue(error.message),
    (isShared: boolean) => {
      return thunkAPI.fulfillWithValue(isShared);
    }
  );
});

const newsDetailsSlice = createSlice({
  name: "newsDetails",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    getNewsDetails();
    verifyNewsReducer();
    denyNewsReducer();
    bookmarkNewsReducer();
    unBookmarkNewsReducer();
    shareNewsReducer();

    builder.addCase(resetNewsDetailsState, (state) => {
      Object.assign(state, initialState);
    });

    function getNewsDetails() {
      builder.addCase(fetchNewsDetails.fulfilled, (state, action: PayloadAction<NewsModel>) => {
        state.loading = false;
        if (state.news === null) {
          state.news = action.payload;
        }
        state.isBookmarked = action.payload.isBookmarked;
        state.isVerified = action.payload.isVerified;
        state.isDenied = action.payload.isDenied;
      });
      builder.addCase(fetchNewsDetails.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.loading = false;
        state.error = action.payload ?? "An unknown error occurred";
      });
    }

    function verifyNewsReducer() {
      builder.addCase(verifyNews.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.loading = false;
        state.error = action.payload ?? "An unknown error occurred";
      });
      builder.addCase(verifyNews.fulfilled, (state, action: PayloadAction<boolean>) => {
        state.isVerified = action.payload;
      });
    }

    function denyNewsReducer() {
      // Deny news
      builder.addCase(denyNews.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.loading = false;
        state.error = action.payload ?? "An unknown error occurred";
      });
      builder.addCase(denyNews.fulfilled, (state, action: PayloadAction<boolean>) => {
        state.isDenied = action.payload;
      });
    }

    function bookmarkNewsReducer() {
      // Bookmark news
      builder.addCase(bookmarkNews.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.error = action.payload ?? "An unknown error occurred";
      });
      builder.addCase(bookmarkNews.fulfilled, (state, action: PayloadAction<boolean>) => {
        state.isBookmarked = action.payload;
      });
    }

    function unBookmarkNewsReducer() {
      // UnBookmark news
      builder.addCase(unBookmarkNews.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.error = action.payload ?? "An unknown error occurred";
      });
      builder.addCase(unBookmarkNews.fulfilled, (state, action: PayloadAction<boolean>) => {
        state.isBookmarked = action.payload;
      });
    }

    function shareNewsReducer() {
      // Share news
      builder.addCase(shareNews.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.error = action.payload ?? "An unknown error occurred";
      });
    }
  },
});

export default newsDetailsSlice.reducer;
