import "reflect-metadata";
import { Container } from "inversify";
import { authContainer } from "../../features/auth/auth.container";
import { networkContainer } from "./network.container";
import { newsContainer } from "../../features/news/newsContainer";
import { categoryContainer } from "../../features/category/categoryContainer";
import { notificationContainer } from "../../features/notification/notificationContainer";

// Create the main container
const container = new Container();

// Merge module containers
container.load(authContainer);
container.load(networkContainer);
container.load(newsContainer);
container.load(categoryContainer);
container.load(notificationContainer);

export { container };
