import React from "react";
import "./ProfileV2.css";
import ComingSoon from "../commingSoon/ComingSoon";

const MyProfileV2: React.FC = () => {
  return (
    <ComingSoon />
  );
};

export default MyProfileV2;