import React from "react";
import ReactMarkdown from "react-markdown";
import { NewsContentBlockModel } from "../../../../product/models/news/newsContentBlockModel";
import "./ContentBlockList.css";

const ContentBlockList: React.FC<{
  contentBlocks: NewsContentBlockModel[];
}> = ({ contentBlocks }) => (
  <div className='content-block-list'>
    {contentBlocks.map((block) => (
      <div key={block.orderNum} className='content-block'>
        {block.description && block.description.description && (
          <p className='p-markdown-text-content'>
            <ReactMarkdown children={block.description.description} />
          </p>
        )}
        {block.media && <img src={block.media.url} alt='content media' className='content-media' />}
      </div>
    ))}
  </div>
);

export default ContentBlockList;
