import React from "react";
import ComingSoon from "../commingSoon/ComingSoon";


const SettingsV2: React.FC = () => {

  return (
    <ComingSoon />
  );
};

export default SettingsV2;