import React from "react";
import "./Discover.css";
import ComingSoon from "../commingSoon/ComingSoon";

const Discover: React.FC = () => {
  return (
    <ComingSoon />
  );
};

export default Discover;