import React, {FormEvent, useState} from "react";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import Notifications from "../../components/notifications/Notifications";

import SideBar from "../../components/sideBar/sideBar";
import RightBlockMyProfile from  "../../components/rightBlockMyProfile/RightBlockMyProfile";


import './notifications.css';

const Myprofile: React.FC = () => {

  return (
      <>
        <Header />

          <section className="home-content">
               
              <SideBar />

                  <Notifications />
            
               <RightBlockMyProfile /> 
          </section>
       
        <Footer/>
      </>
  );
};

export default Myprofile;
