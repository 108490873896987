import React from "react";
import "./Footer.css";
import BaseTextButton from "../buttons/textButton/BaseTextButton";

const Footer: React.FC = () => {
  const x: string = require("../../product/assets/x.svg").default;
  const instagram: string = require("../../product/assets/instagram.svg").default;
  const linkedin: string = require("../../product/assets/linkedin.svg").default;

  const handleXClick = () => {
    window.open("https://x.com/jurnalleapp", "_blank");
  };

  // Function to redirect to Google Play
  const handleInstagramClick = () => {
    window.open("https://www.instagram.com/jurnalleapp", "_blank");
  };
  const handleLinkedinClick = () => {
    window.open("https://www.linkedin.com/company/jurnalle", "_blank");
  };

  return (
      <footer className="footer">
        <div className="footer-content">
          <div className="footer-left">
            <div className="footer-links"> {/* Wrap links in a div for styling */}
              <h3 className="all-rights-reserved">jurnalle | 2024 © Tüm hakları saklıdır </h3>
              <div className="footer-links">
                <BaseTextButton to="https://jurnalle.com/#about" isExternal={true}>
                  Hakkında</BaseTextButton>
                <BaseTextButton to="https://jurnalle.com/community_rules" isExternal={true}>
                  Topluluk Kuralları</BaseTextButton>
                <BaseTextButton to="https://jurnalle.com/privacy_policy" isExternal={true}>
                  Gizlilik Politikası</BaseTextButton>
                <BaseTextButton to="https://jurnalle.com/terms_of_service" isExternal={true}>
                  Hizmet Şartları</BaseTextButton>
              </div>
            </div>
          </div>
          <div className="footer-right">
            <img src={x} alt="X" onClick={handleXClick}/>
            <img src={instagram} alt="Instagram" onClick={handleInstagramClick}/>
            <img src={linkedin} alt="Linkedin" onClick={handleLinkedinClick}/>

          </div>
        </div>
      </footer>
  );
};

export default Footer;
