import React from "react";

const CreateNewsDetailsV2: React.FC = () => {
  return (
    <div className="create-news-container">
      Create news details
    </div>
  );
};

export default CreateNewsDetailsV2;