import React from "react";
import "./PopularTopicsBlock.css";


const PopularTopicsBlock: React.FC = () => {
  return (
    <div className="popular-topics-container">
      <div className="right-block-content-my">
        <h3>Gündem</h3>

        <div className="cat-block">
          <span>Gündem</span>
          <p>İstanbul</p>
          <span>2400</span>
        </div>

        <div className="cat-block">
          <span>Gündem</span>
          <p>İstanbul</p>
          <span>2400</span>
        </div>

        <div className="cat-block">
          <span>Gündem</span>
          <p>İstanbul</p>
          <span>2400</span>
        </div>

        <div className="cat-block">
          <span>Gündem</span>
          <p>İstanbul</p>
          <span>2400</span>
        </div>

        <div className="cat-block">
          <span>Gündem</span>
          <p>İstanbul</p>
          <span>2400</span>
        </div>

        <div className="cat-block">
          <span>Gündem</span>
          <p>İstanbul</p>
          <span>2400</span>
        </div>

      </div>
    </div>

  );
};

export default PopularTopicsBlock;
