import React from "react";
import "./headerRegister.css";
import BaseTextButton from "../buttons/textButton/BaseTextButton";
import { Link } from "react-router-dom";


const HeaderRegister: React.FC = () => {

  const jurnalleLogo: string = require("../../product/assets/jurnalle.svg").default;
  const searchIcon: string = require("../../product/assets/search-ico.svg").default;

  return (
    <header className="header-register">
      <div className="header-bar-register">
        <div className="jurnalle-logo-register">
          <Link to="/"> <img src={jurnalleLogo} alt="logo" /></Link>
        </div>

        <div className="nav-right-register">
          

          <Link className="login-btn-register" to="/login">Giriş yap</Link>
          <Link className="register-btn-register" to="/register">Kaydol</Link>

        </div>

      </div>
    </header>
  );
};

export default HeaderRegister;
