import React, {FormEvent, useState} from "react";

import { Link } from "react-router-dom";
import Header from "./Header"
import Footer from "./Footer"

import './jurnalleWebSite.css';

const Cookies: React.FC = () => {

  
  return (
    <section className="web-section">
        <Header />
<main className="main">

    <section className="container terms">
    <article className="page">
        <header className="page__header">
            <h1 className="page__title">Çerezler</h1>
        </header>
        <div className="page__content">
                Kişisel Verilerin İşlenmesine İlişkin Aydınlatma Metni
                Veri Sorumlusu
                <br/><br/>jurnalle <br/><br/>
            <p>jurnalle olarak 6698 sayılı Kişisel Verilerin Korunması Kanunu (“KVKK”) ile ilgili mevzuat ve yasal
                düzenlemelerden kaynaklanan faaliyetlerimiz çerçevesinde kişisel verilerinizin işlenmesi, saklanması
                ve aktarılmasına ilişkin veri sahibi ilgili kişileri aydınlatmak amacıyla işbu Kişisel Verilerin
                İşlenmesi Aydınlatma Metni’ni (“Aydınlatma Metni”) hazırladık. </p>
            <p>Bunlara ek olarak internet sitemizi, mobil sitemizi ve/veya mobil uygulamamızı ziyaret etmeniz
                durumunda kullanılan çerez ve SDK’lar hakkında ayrıntılı bilgiler Çerez Politikası’nda yer
                almaktadır. Bunlar aracılığıyla işlenen kişisel veriler ise bu Aydınlatma Metni’nde açıklanmaktadır.
                Aydınlatma Metni, jurnalle tarafından yayımlandığı tarih itibariyle geçerli olacaktır. jurnalle,
                Aydınlatma Metni’nde gerekli olduğu takdirde her zaman değişiklik yapabilir. Yapılacak
                değişiklikler, Aydınlatma Metni’nin <Link 
                  to='#'
                 
              >
                 <p>hello@jurnalle.app</p> </Link> 
                adresinde yayımlanmasıyla birlikte derhal geçerlilik kazanır.
                Kişisel Verilerin İşlenmesine İlişkin Rıza Metni
                a. İşlenen Kişisel Veriler ve Toplama Yöntemleri:
            
                </p>  <br/>KVKK’nın 11. maddesi kapsamında;
                • Kişisel verilerinizin işlenip işlenmediğini öğrenme,
                • Kişisel verileriniz işlenmişse buna ilişkin bilgi talep etme,
                • Kişisel verilerinizin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını
                öğrenme,
                • Yurt içinde veya yurt dışında kişisel verilerinizin aktarıldığı üçüncü kişileri bilme,
                • Kişisel verilerinizin eksik veya yanlış işlenmiş olması halinde bunların düzeltilmesini isteme,
                • KVKK mevzuatında öngörülen şartlar çerçevesinde kişisel verilerinizin silinmesini veya yok
                edilmesini isteme,
                • Eksik veya yanlış verilerinizin düzeltilmesi ile kişisel verilerinizin silinmesi veya yok
                edilmesini talep ettiğinizde , bu durumun kişisel verilerinizin aktarıldığı üçüncü kişilere
                bildirilmesini isteme,
                • İşlenen verilerinizin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle aleyhe
                bir sonucun ortaya çıkması durumunda sonuca itiraz etme ve
                • Kişisel verilerinizin kanuna aykırı olarak işlenmesi sebebiyle zarara uğraması halinde bu zararın
                giderilmesini talep etme,
                haklarına sahipsiniz.
                Yukarıda yer alan tüm kişisel verilerinizin belirtilen işleme amaçları ile sınırlı olmak üzere
                işlenmesine hiçbir baskı altında kalmadan ve açık biçimde muvafakat ettiğinizi, üye ol sayfasındaki
                “hesabımı oluştur” butonuna tıklayarak kabul etmiş olacaksınız
                </div>
                </article>
                </section>

</main>
<Footer />
</section>
    );
  };
  
  export default Cookies;

  