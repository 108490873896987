import { INotificationRemoteDataSource } from "./INotificationRemoteDataSource";
import { inject, injectable } from "inversify";
import type { INetworkManager } from "next-netkit";
import { RequestMethod } from "next-netkit";
import { NotificationModel } from "../../domain/models/NotificationModel";
import { UnreadNotificationsCountModel } from "../../domain/models/UnreadNotificationsCountModel";
import { DisabledNotificationsModel } from "../../domain/models/DisabledNotificationsModel";
import { PaginationParams } from "../../../../product/params/paginationParams";
import { APIConst } from "../../../../product/constants/apiConst";

@injectable()
export class NotificationRemoteDataSource implements INotificationRemoteDataSource {
  @inject("INetworkManager") private networkManager!: INetworkManager;

  async enableThisNotificationType(notificationTypeId: number): Promise<boolean> {
    await this.networkManager.requestVoid({
      url: APIConst.updateNotificationPreference(notificationTypeId),
      method: RequestMethod.POST,
    });
    return true;
  }

  async disableThisNotificationType(notificationTypeId: number): Promise<boolean> {
    await this.networkManager.requestVoid({
      url: APIConst.updateNotificationPreference(notificationTypeId),
      method: RequestMethod.DELETE,
    });
    return true;
  }

  async getNotifications(paginationParams: PaginationParams): Promise<NotificationModel[]> {
    return await this.networkManager.requestList<NotificationModel>({
      url: APIConst.notifications(paginationParams.page),
      method: RequestMethod.GET,
      data: paginationParams,
    });
  }

  async removeNotification(id: number): Promise<void> {
    await this.networkManager.requestVoid({
      url: APIConst.removeNotification(id),
      method: RequestMethod.DELETE,
    });
  }

  async markNotificationAsRead(id: number): Promise<void> {
    await this.networkManager.requestVoid({
      url: APIConst.markNotificationAsRead(id),
      method: RequestMethod.PUT,
    });
  }

  async getUnreadNotificationsCount(): Promise<UnreadNotificationsCountModel> {
    return await this.networkManager.request<UnreadNotificationsCountModel>({
      url: APIConst.UNREAD_NOTIFICATIONS_COUNT,
      method: RequestMethod.GET,
    });
  }

  async markAllNotificationsAsRead(): Promise<void> {
    await this.networkManager.requestVoid({
      url: APIConst.ALL_READ_NOTIFICATIONS,
      method: RequestMethod.PUT,
    });
  }

  async removeAllReadNotifications(): Promise<void> {
    await this.networkManager.requestVoid({
      url: APIConst.ALL_READ_NOTIFICATIONS,
      method: RequestMethod.DELETE,
    });
  }

  async removeAllNotifications(): Promise<void> {
    await this.networkManager.requestVoid({
      url: APIConst.ALL_NOTIFICATIONS,
      method: RequestMethod.DELETE,
    });
  }

  async getDisabledNotifications(): Promise<DisabledNotificationsModel> {
    return await this.networkManager.request<DisabledNotificationsModel>({
      url: APIConst.DISABLED_NOTIFICATIONS,
      method: RequestMethod.GET,
    });
  }
}
