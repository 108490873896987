import React from "react";
import "./FeedNewsCardShimmer.css";

const FeedNewsCardShimmer: React.FC = () => {
  return (
    <div className="shimmer-card">
      <div className="shimmer-row">
        <div className="shimmer-column">
          <div className="shimmer-line short"></div>
          <div className="shimmer-line medium"></div>
          <div className="shimmer-line long"></div>
        </div>
        <div className="shimmer-image"></div>
      </div>
    </div>
  );
};

export default FeedNewsCardShimmer;