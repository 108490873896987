import { ContainerModule, interfaces } from "inversify";
import { INewsRemoteDataSource } from "./data/dataSources/iNewsRemoteDataSource";
import { NewsRemoteDataSource } from "./data/dataSources/newsRemoteDataSource";
import { INewsRepository } from "./domain/repositories/iNewsRepository";
import { NewsRepository } from "./data/repositories/newsRepository";
import { GetVerifiedNews } from "./domain/useCases/getVerifiedNews";
import { GetDeniedNews } from "./domain/useCases/getDeniedNews";
import { NewsContainerTypes } from "./newsContainerTypes";
import { GetFeedNews } from "./domain/useCases/getFeedNews";
import { GetNewsDetails } from "./domain/useCases/GetNewsDetails";
import { VerifyNews } from "./domain/useCases/VerifyNews";
import { DenyNews } from "./domain/useCases/DenyNews";
import { BookmarkNews } from "./domain/useCases/BookmarkNews";
import { UnBookmarkNews } from "./domain/useCases/UnBookmarkNews";
import { ShareNews } from "./domain/useCases/ShareNews";

const newsContainer = new ContainerModule((bind: interfaces.Bind) => {
  bind<INewsRemoteDataSource>(NewsContainerTypes.INewsRemoteDataSource).to(NewsRemoteDataSource);
  bind<INewsRepository>(NewsContainerTypes.INewsRepository).to(NewsRepository);
  bind<GetVerifiedNews>(NewsContainerTypes.GetVerifiedNews).to(GetVerifiedNews);
  bind<GetDeniedNews>(NewsContainerTypes.GetDeniedNews).to(GetDeniedNews);
  bind<GetFeedNews>(NewsContainerTypes.GetFeedNews).to(GetFeedNews);
  bind<GetNewsDetails>(NewsContainerTypes.GetNewsDetails).to(GetNewsDetails);
  bind<VerifyNews>(NewsContainerTypes.VerifyNews).to(VerifyNews);
  bind<DenyNews>(NewsContainerTypes.DenyNews).to(DenyNews);
  bind<BookmarkNews>(NewsContainerTypes.BookmarkNews).to(BookmarkNews);
  bind<UnBookmarkNews>(NewsContainerTypes.UnBookmarkNews).to(UnBookmarkNews);
  bind<ShareNews>(NewsContainerTypes.ShareNews).to(ShareNews);
});

export { newsContainer };
